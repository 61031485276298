// authReducer.js
import {
  SAVE_EVENT,
  DELETE_EVENT,
  FETCH_EVENTS,
  ADD_SHIFT,
  REMOVE_SHIFT,
  ADD_NOTES_SCHEDULE,
  SAVE_PLANNED_HOURS,
  ADD_NOTE_TO_EVENT,
  REMOVE_NOT_PLACED,
  REMOVE_EVENT_MATRIX,
  SAVE_NOT_PLACED,
  PUBLISH_SCHEDULE,
  CHANGE_ROLE_SEQUENCE,
  EDIT_LAST_SUBMISSION,
  INIT_SCHEDULE,
  SET_SCHEDULE_TIP,
  REMOVE_SCHEDULE_TIP,
  ADD_PERMANENT_TO_ROLE,
  DUPLICATE_CELL,
  SAVE_SHIFT,
  UPDATE_SCHEDULE,
  SET_HOLIDAYS,
  SET_EXISTING_ROLES,
  SET_NEW_EVENTS,
  // SET_IS_PINNED_SCHEDULE,
} from "../actions/scheduleAction/types";
import { mapKeys, omit } from "lodash";
import { LOGOUT } from "../actions/loginAction/types";
import { LOGOUT_ADMIN } from "../actions/AdminLoginAction/types";
import { getSafe } from "../../helpers";

const INITIAL_STATE = {
  schedule: {},
  events: {},
  notPlaced: {},
  matrix: {},
  shifts: [],
  existing_shifts: [],
  existing_roles: [],
  notPlaced_matrix: {},
  notPlaced_employees_events: [],
  tips: {},
  isAutoAssign: false,
};

const check = (array, toCheck) => {
  if (array) {
    let obj = mapKeys(array, "id");
    if (obj[toCheck.id]) {
      obj = { ...obj, [toCheck.id]: toCheck };
      return Object.values(obj);
    } else return [...array, toCheck];
  } else {
    return [toCheck];
  }
};

const scheduleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_EVENTS:
      return {
        ...state,
        events: { ...mapKeys(action.payload.events, "id") },
        notPlaced: { ...mapKeys(action.payload.notPlaced, "id") },
        notPlaced_employees_events: {
          ...mapKeys(action.payload.notPlaced_employees_events, "id"),
        },
        matrix: { ...action.payload.matrix },
        schedule: action.payload.data,
        shifts: action.payload.shifts.sort((a, b) => a.sequence - b.sequence),
        notPlaced_matrix: { ...action.payload.notPlaced_matrix },
        tips: { ...mapKeys(action.payload.tips, "id") },
        existing_shifts: action.payload.existing_shifts,
        existing_roles: action.payload.existing_roles,
        isAutoAssign: action.payload.isAutoAssign
          ? action.payload.isAutoAssign
          : false,
      };
    case DUPLICATE_CELL:
      return {
        ...state,
        events: { ...mapKeys(action.payload.events, "id") },
        matrix: { ...action.payload.matrix },
        shifts: action.payload.shifts.sort((a, b) => a.sequence - b.sequence),
      };
    case SAVE_SHIFT: {
      const { id, name, sequence } = action.payload;
      return {
        ...state,
        shifts: Object.values({
          ...mapKeys(state.shifts, "id"),
          [id]: { id, name },
        }),
        existing_shifts: Object.values({
          ...mapKeys(state.existing_shifts, "id"),
          [id]: { id, name, sequence },
        }),
      };
    }

    case CHANGE_ROLE_SEQUENCE:
      return {
        ...state,
        events: { ...state.events, ...mapKeys(action.payload, "id") },
      };
    // case PUBLISH_SCHEDULE: {
    //   return {
    //     ...state,
    //     schedule: action.payload,
    //   };
    // }
    case SET_HOLIDAYS: {
      return {
        ...state,
        schedule: {
          ...state.schedule,
          holidays: action.payload,
        },
      };
    }
    case REMOVE_EVENT_MATRIX: {
      const { week_day, employee, id } = action.payload;
      return {
        ...state,
        matrix: {
          ...state.matrix,
          [week_day]: (() => {
            const newMatrix = getSafe(() =>
              state.matrix[week_day][employee.id].filter((x) => x.id !== id)
            );
            if (newMatrix) {
              if (newMatrix.length === 0) {
                delete state.matrix[week_day][employee.id];
              } else {
                state.matrix[week_day][employee.id] = newMatrix;
              }
            }
            return { ...state.matrix[week_day] };
          })(),
        },
      };
    }
    case SAVE_EVENT: {
      const { week_day, employee, shift, id } = action.payload;
      return {
        ...state,
        events: { ...state.events, [id]: action.payload },
        matrix: {
          ...state.matrix,
          [week_day]: {
            ...state.matrix[week_day],
            [employee.id]: check(
              state.matrix[week_day][employee.id],
              action.payload
            ),
          },
        },
      };
    }

    case DELETE_EVENT: {
      const { id } = action.payload.event;
      const { week_day, employee, shift } = action.payload.old_event;

      return {
        ...state,
        events: { ...state.events, [id]: action.payload.event },
        matrix: {
          ...state.matrix,
          [week_day]: (() => {
            const newMatrix = getSafe(() =>
              state.matrix[week_day][employee.id].filter((x) => x.id !== id)
            );
            if (newMatrix) {
              if (newMatrix.length === 0) {
                delete state.matrix[week_day][employee.id];
              } else {
                state.matrix[week_day][employee.id] = newMatrix;
              }
            }
            return state.matrix[week_day];
          })(),
        },
      };
    }

    case SAVE_NOT_PLACED: {
      const { week_day, id, employee } = action.payload;

      return {
        ...state,
        notPlaced: { ...state.notPlaced, [id]: action.payload },
        matrix: {
          ...state.matrix,
          [week_day]: {
            ...state.matrix[week_day],
            [employee.id]: check(
              state.matrix[week_day][employee.id],
              action.payload
            ),
          },
        },
        notPlaced_matrix: {
          ...state.notPlaced_matrix,
          [week_day]: state.notPlaced_matrix[week_day].map((x) => {
            if (x.id === id) return action.payload;

            return x;
          }),
        },
      };
    }

    case SET_SCHEDULE_TIP: {
      return {
        ...state,
        tips: { ...state.tips, [action.payload.id]: action.payload },
      };
    }

    case REMOVE_SCHEDULE_TIP: {
      return {
        ...state,
        tips: omit(state.tips, action.tipId),
      };
    }

    case REMOVE_NOT_PLACED: {
      const { week_day, id, employee } = action.payload.event;

      return {
        ...state,
        matrix: {
          ...state.matrix,
          [week_day]: (() => {
            const newMatrix = getSafe(() =>
              state.matrix[week_day][employee.id].filter((x) => x.id !== id)
            );
            if (newMatrix) {
              if (newMatrix.length === 0) {
                delete state.matrix[week_day][employee.id];
              } else {
                state.matrix[week_day][employee.id] = newMatrix;
              }
            }
            return state.matrix[week_day];
          })(),
        },
        notPlaced: omit(state.notPlaced, id),
        notPlaced_matrix: {
          ...state.notPlaced_matrix,
          [week_day]: state.notPlaced_matrix[week_day].filter(
            (x) => x.id !== id
          ),
        },
      };
    }

    case ADD_NOTE_TO_EVENT:
    case SAVE_PLANNED_HOURS:
      const { id } = action.payload;
      return {
        ...state,
        events: { ...state.events, [id]: action.payload },
      };

    case ADD_NOTES_SCHEDULE:
      return {
        ...state,
        schedule: { ...state.schedule, notes: action.notes },
      };

    // case SET_IS_PINNED_SCHEDULE:
    //   return {
    //     ...state,
    //     schedule: { ...state.schedule, is_pinned: action.is_pinned },
    //   };

    case UPDATE_SCHEDULE: {
      return {
        ...state,
        schedule: {
          ...state.schedule,
          locked_dates: action.payload.locked_dates,
          submission_required_dates: action.payload.submission_required_dates,
          daily_notes: action.payload.daily_notes,
          double_shift: action.payload.double_shift,
          foloow_shift: action.payload.foloow_shift,
        },
      };
    }
    case INIT_SCHEDULE:
    case LOGOUT_ADMIN:
    case LOGOUT:
      return INITIAL_STATE;

    case ADD_SHIFT:
      return {
        ...state,
        events: { ...state.events, ...mapKeys(action.payload.events, "id") },
        matrix: action.payload.matrix,
        shifts: action.payload.shifts ? action.payload.shifts : state.shifts,
        existing_shifts: action.payload.existing_shifts
          ? action.payload.existing_shifts
          : state.existing_shifts,
      };
    case ADD_PERMANENT_TO_ROLE:
      return {
        ...state,
        events: { ...state.events, ...mapKeys(action.payload.events, "id") },
        matrix: action.payload.matrix,
      };
    case REMOVE_SHIFT:
      return {
        ...state,
        events: action.payload.events,
        matrix: action.payload.matrix,
      };
    case EDIT_LAST_SUBMISSION:
      return {
        ...state,
        schedule: action.payload,
      };
    case SET_EXISTING_ROLES:
      return {
        ...state,
        existing_roles: action.payload,
      };
    case SET_NEW_EVENTS:
      return {
        ...state,
        events: { ...mapKeys(action.payload.updatedEvents, "id") },
      };
    default:
      return state;
  }
};
export default scheduleReducer;
