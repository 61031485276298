import React, { useEffect, useMemo, useRef, useState } from "react";
import { COLOR_SUBMIT } from "../../const/colors";
import { getSafe } from "../../helpers";

const LEFT = 1,
  RIGHT = 2;

export const ButtonGroup = ({ children, padding, jumps = 100 }) => {
  const groupRef = useRef();
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    const setter = async () => {
      setScrollLeft(groupRef.current.scrollLeft);
    };
    if (groupRef.current) {
      setter();
    }
  }, [groupRef]);

  const handleScroll = useMemo(
    () => async (direction) => {
      let initialScroll = groupRef.current.scrollLeft;
      if (direction === LEFT) {
        if (groupRef) {
          groupRef.current.scrollLeft -= jumps;
          setScrollLeft(initialScroll + jumps);
        }
      } else {
        if (groupRef) {
          groupRef.current.scrollLeft += jumps;
          setScrollLeft(initialScroll - jumps);
        }
      }
    },
    [groupRef, jumps]
  );
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <i
        style={{
          fontSize: "1.8rem",
          paddingRight: "5px",
          marginLeft: "20px",

          color: scrollLeft < padding ? "transparent" : COLOR_SUBMIT,
          // color: COLOR_SUBMIT,
        }}
        onClick={() => handleScroll(RIGHT)}
        className="fas fa-chevron-right"
      ></i>

      <div
        onTouchEnd={() => setScrollLeft(groupRef.current.scrollLeft)}
        ref={groupRef}
        className="buttonGroup"
      >
        {children}
      </div>
      <i
        style={{
          fontSize: "1.8rem",
          paddingLeft: "5px",
          marginRight: "20px",
          color: getSafe(() =>
            Math.abs(
              padding >=
                groupRef.current.scrollWidth -
                  scrollLeft -
                  groupRef.current.clientWidth
            )
          )
            ? "transparent"
            : COLOR_SUBMIT,
          // color: COLOR_SUBMIT,
        }}
        onClick={() => handleScroll(LEFT)}
        className="fas fa-chevron-left"
      ></i>
    </div>
  );
};
