export const VAT = 1.17;

export const ORDER_ACTION_RECEIVE = 1;
export const ORDER_ACTION_RECEIVE_WATCH = 2;
export const ORDER_ACTION_RECEIVE_NOTE = 3;
export const ORDER_ACTION_DUPLICATE_ORDER = 4;
export const ORDER_ACTION_SPLIT = 5;
export const ORDER_ACTION_REFUND_ORDER = 6;
export const ORDER_ACTION_DELETE_RECEIVED = 7;
export const ORDER_ACTION_DUPLICATE_RECEIVE_ORDER = 8;
export const ORDER_ACTION_RECEIVE_ORDER_TO_INVENTORY = 9;
export const ORDER_ACTION_UNITE = 10;
export const ORDER_ACTION_LOCK = 11;
export const ORDER_ACCEPT = 12;
export const ORDER_DECLINE = 13;
export const ORDER_TYPE_WITHOUT = 0;
export const ORDER_TYPE_ORDER = 1;
export const ORDER_TYPE_RECEIVED_ORDER = 2;
export const ORDER_TYPE_CONCENTRATOR = 3;
export const ORDER_TYPE_CONCENTRATOR_WITHOUT = 4;
export const ORDER_TYPE_RECEIVE_ORDER_DUPLICATE = 5;
export const ORDER_TYPE_REFUND_ITEMS = 6;

export const DOCUMENT_TYPE_OPEN_ORDER = 0;
export const DOCUMENT_TYPE_INVOICE = 1;
export const DOCUMENT_TYPE_SHIPPING_CERTIFICATE = 2;
export const DOCUMENT_TYPE_SMALL_CASHIER_CASH = 3;
export const DOCUMENT_TYPE_SMALL_CASHIER_CREDIT = 6;
export const DOCUMENT_TYPE_CONSECRATOR = 4;
export const DOCUMENT_TYPE_REFUND_INVOICE = 7;
export const DOCUMENT_TYPE_REFUND_CERTIFICATE = 8;
export const DOCUMENT_TYPE_TRANSACTION_INVOICE = 9;
export const DOCUMENT_TYPE_QUOTATION = 10;
export const DOCUMENT_TYPE_RECEIPT = 11;
export const DOCUMENT_TYPE_TAX_INVOICE = 12;

export const DOCUMENT_TYPE_HEBREW = {
  [DOCUMENT_TYPE_OPEN_ORDER]: "הזמנה פתוחה",
  [DOCUMENT_TYPE_INVOICE]: "חשבונית",
  [DOCUMENT_TYPE_SHIPPING_CERTIFICATE]: "ת.משלוח",
  [DOCUMENT_TYPE_SMALL_CASHIER_CASH]: "קופה קטנה - מזומן",
  [DOCUMENT_TYPE_SMALL_CASHIER_CREDIT]: "קופה קטנה - אשראי",
  [DOCUMENT_TYPE_CONSECRATOR]: "חשבונית מרכזת",
  [DOCUMENT_TYPE_REFUND_INVOICE]: "חשבונית זיכוי",
  [DOCUMENT_TYPE_REFUND_CERTIFICATE]: "ת.החזרה",
  [DOCUMENT_TYPE_TRANSACTION_INVOICE]: "חשבונית עסקה",
  [DOCUMENT_TYPE_QUOTATION]: "הצעת מחיר",
  [DOCUMENT_TYPE_RECEIPT]: "קבלה",
  [DOCUMENT_TYPE_TAX_INVOICE]: "חשבונית מס / קבלה",
};

export const DOCUMENT_TYPES_INVOICE = [1, 3, 6, 4, 7, 12];
export const DOCUMENT_TYPES_CERTIFICATE = [2, 8];

export const PAYMENT_TYPE_WITH_VAT = 1;
export const PAYMENT_TYPE_WITHOUT_VAT = 2;
export const PAYMENT_TYPE_MANUAL = 3;
export const PAYMENT_TYPE_NONE = 4;

export const EXPENSE_TYPE_FOOD_COST = 1;
export const EXPENSE_TYPE_OPERATIONAL = 2;
export const EXPENSE_TYPE_FINANCIAL = 3;
export const EXPENSE_TYPE_BY_ITEM = 99;

export const STATUS_TYPE_OPEN = 1;
export const STATUS_TYPE_HAS_CONCENTRATOR = 2;
export const STATUS_TYPE_EXPORTED = 3;

export const EXPENSE_TYPES_STRINGS = {
  [EXPENSE_TYPE_FOOD_COST]: "Food Cost",
  [EXPENSE_TYPE_OPERATIONAL]: "הוצאות תפעול",
  [EXPENSE_TYPE_FINANCIAL]: "הוצאות הנהלה וכלליות",
  [EXPENSE_TYPE_BY_ITEM]: "לפי מוצר",
};

export const DISCOUNT_TYPE_PERCENT = 1;
export const DISCOUNT_TYPE_AMOUNT = 2;

export const VALIDATE_ITEM_CODE_EXIST = 1;
export const VALIDATE_ITEM_CODE_ENABLE = 2;
export const VALIDATE_ITEM_CODE_BRANCH_RELATED = 3;

export const REFOUND_TYPE_AMOUNT = 1;
export const REFOUND_TYPE_PRICE = 2;

export const REFUND_TYPE_HEBREW = {
  [REFOUND_TYPE_AMOUNT]: "זיכוי כמות",
  [REFOUND_TYPE_PRICE]: "זיכוי מחיר",
};

export const REFUND_STATUS_OPEN = 1;
export const REFUND_STATUS_SENT = 2;
export const REFUND_STATUS_CLOSE = 3;
export const REFUND_STATUS_CANCELED = 4;

export const REFUND_STATUS_HEBREW = {
  [REFUND_STATUS_OPEN]: "פתוח",
  [REFUND_STATUS_SENT]: "נשלח לספק",
  [REFUND_STATUS_CLOSE]: "טופל",
  [REFUND_STATUS_CANCELED]: "בוטל",
};

export const REFUND_STATUS_COLORS = {
  [REFUND_STATUS_OPEN]: "#f6bb42",
  [REFUND_STATUS_SENT]: "#47cc00",
  [REFUND_STATUS_CLOSE]: "#f6bb42",
  [REFUND_STATUS_CANCELED]: "#db1e1e",
};

export const EXPORT_MODE_STATUSES = [
  { value: 1, label: "חשבוניות פתוחות" },
  { value: 2, label: "חשבוניות שיוצאו" },
  { value: 3, label: "חשבוניות לטיפול" },
];

export const EXPORT_TYPE_HASHAVSHEVET = 1;
export const EXPORT_TYPE_PRIORITY = 2;
export const EXPORT_TYPE_RAM_PLUS = 3;
export const EXPORT_TYPE_HASHAVSHEVET_BY_ITEM = 6;
export const EXPORT_TYPE_HASHAVSHEVET_WITH_PRM = 7;
export const EXPORT_TYPE_HASHAVSHEVET_BY_ITEM_WITH_PRM = 8;

export const BY_ITEMS_TYPES = [
  EXPORT_TYPE_PRIORITY,
  EXPORT_TYPE_HASHAVSHEVET_BY_ITEM,
  EXPORT_TYPE_HASHAVSHEVET_BY_ITEM_WITH_PRM,
];

//TODO need to move to manage suppliers in the future
//TODO usages: SupplierAccountancyForm
export const AGOROT_ROUNDING_OPTIONS = [
  { value: 0, label: "ללא" },
  { value: 1, label: "ספרה עשרונית אחת" },
  { value: 2, label: "מספר שלם" },
];
export const PAYMENT_CONDITIONS_OPTIONS = [
  { value: 0, label: "מזומן" },
  { value: 1, label: "שוטף" },
  { value: 999, label: "אשראי" },
  { value: 15, label: "שוטף + 15" },
  { value: 20, label: "שוטף + 20" },
  { value: 30, label: "שוטף + 30" },
  { value: 32, label: "שוטף + 32" },
  { value: 35, label: "שוטף + 35" },
  { value: 36, label: "שוטף + 36" },
  { value: 40, label: "שוטף + 40" },
  { value: 42, label: "שוטף + 42" },
  { value: 45, label: "שוטף + 45" },
  { value: 48, label: "שוטף + 48" },
  { value: 50, label: "שוטף + 50" },
  { value: 51, label: "שוטף + 51" },
  { value: 52, label: "שוטף + 52" },
  { value: 58, label: "שוטף + 58" },
  { value: 60, label: "שוטף + 60" },
  { value: 62, label: "שוטף + 62" },
  { value: 65, label: "שוטף + 65" },
  { value: 66, label: "שוטף + 66" },
  { value: 70, label: "שוטף + 70" },
  { value: 75, label: "שוטף + 75" },
  { value: 80, label: "שוטף + 80" },
  { value: 90, label: "שוטף + 90" },
  { value: 100, label: "שוטף + 100" },
  { value: 110, label: "שוטף + 110" },
  { value: 120, label: "שוטף + 120" },
];

//TODO need to move to manage items in the future
//TODO usages: AddItemDialog
export const BOX_TYPES = [
  "מארז",
  "קרטון",
  "שקית",
  "תבנית",
  "שרוול",
  "קופסא",
  "דלי",
  "צנצנת",
  "שק",
  "כד",
  "חריץ",
  "גליל",
  "גביע",
  "פח",
  "ואקום",
  "חפיסה",
  "חבילה",
  "ארגז",
  "בקבוק",
  "מגש",
];

export const BOX_SCALES = [
  "יחידות",
  "בקבוקים",
  "בלוקים",
  "שקיות",
  "קופסאות",
  "צנצנות",
  "שרוולים",
  "פחיות",
  "חבילות",
];

export const SCALES = [
  'ק"ג',
  "גרם",
  "גלון",
  "יחידות",
  "ליטר",
  'מ"ל',
  'מ"מ',
  'מ"ר',
  "מטר",
  'ס"ל',
  'ס"מ',
  'סמ"ר',
  "קרטון",
  "שקית",
  "נספק",
];

//TODO need to move to manage items in the future
//TODO usages: AddItemDialog InventoryItemsTable
export const COUNT_TYPE_NONE = 0;
export const COUNT_TYPE_ITEM = 1;
export const COUNT_TYPE_WEIGHT = 2;
export const COUNT_TYPE_BOX_TYPE = 3;
export const COUNT_TYPE_BOX_SCALE = 4;
export const COUNT_TYPE_BOX_TYPE_AND_BOX_SCALE = 5;
export const COUNT_TYPE_BOX_TYPE_AND_BOX_SCALE_AND_WEIGHT = 7;

export const COUNT_TYPES_HEBREW = {
  [COUNT_TYPE_NONE]: "לא נספר במלאי",
  [COUNT_TYPE_ITEM]: "מוצר",
  [COUNT_TYPE_WEIGHT]: "שקיל - יחידת מידה",
  [COUNT_TYPE_BOX_TYPE]: "צורת מארז",
  [COUNT_TYPE_BOX_SCALE]: "יחידת מידה במארז",
  [COUNT_TYPE_BOX_TYPE_AND_BOX_SCALE]: "מארז + יחידת מידה במארז",
  [COUNT_TYPE_BOX_TYPE_AND_BOX_SCALE_AND_WEIGHT]:
    "מארז + יחידת מידה במארז + שקיל",
};

export const COUNT_TYPES_SCALES_ACCESS = {
  [COUNT_TYPE_ITEM]: "scale",
  [COUNT_TYPE_WEIGHT]: "scale",
  [COUNT_TYPE_BOX_TYPE]: "box_type",
  [COUNT_TYPE_BOX_SCALE]: "box_scale",
  [COUNT_TYPE_BOX_TYPE_AND_BOX_SCALE]: "box_scale",
};

export const sortMap = (items, type) => {
  const SORT_BY_ADD_ORDER = 0;
  const SORT_BY_ALPHABET = 1;

  switch (type) {
    case SORT_BY_ALPHABET:
      return items.sort((a, b) => a.name.localeCompare(b.name));
    case SORT_BY_ADD_ORDER:
    default:
      return items;
  }
};

export const documentTypeOptions = [
  { id: DOCUMENT_TYPE_INVOICE, name: "חשבונית מס" },
  {
    id: DOCUMENT_TYPE_SHIPPING_CERTIFICATE,
    name: "תעודת משלוח",
  },
  { id: DOCUMENT_TYPE_REFUND_INVOICE, name: "חשבונית זיכוי" },
  {
    id: DOCUMENT_TYPE_REFUND_CERTIFICATE,
    name: "תעודת החזרה",
  },
  {
    id: DOCUMENT_TYPE_TRANSACTION_INVOICE,
    name: "חשבונית עסקה",
  },
  {
    id: DOCUMENT_TYPE_QUOTATION,
    name: "הצעת מחיר",
  },
  {
    id: DOCUMENT_TYPE_SMALL_CASHIER_CASH,
    name: "חשבונית קופה קטנה - מזומן",
  },
  {
    id: DOCUMENT_TYPE_SMALL_CASHIER_CREDIT,
    name: "חשבונית קופה קטנה - אשראי",
  },
  {
    id: DOCUMENT_TYPE_RECEIPT,
    name: "קבלה",
  },
  {
    id: DOCUMENT_TYPE_TAX_INVOICE,
    name: DOCUMENT_TYPE_HEBREW[DOCUMENT_TYPE_TAX_INVOICE],
  },
];

export const ASSIGNED_NUMBER_THRESHOLD = 25000;
export const MONTHLY_OBLIGO_METHOD_PERCENTAGE = 2;
