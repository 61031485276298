import Axios from "axios";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import { setError } from "./redux/actions/errorAction";
import { logout } from "./redux/actions/loginAction";

import createActivityDetector from "activity-detector";
import { observer } from "mobx-react";
import { useStore } from "./stores/setupContext";

const isDev = process.env.NODE_ENV === "development";

export function useIdle(options) {
  const [isIdle, setIsIdle] = React.useState(false);
  React.useEffect(() => {
    const activityDetector = createActivityDetector(options);
    activityDetector.on("idle", () => setIsIdle(true));
    activityDetector.on("active", () => setIsIdle(false));

    // clean the subscription
    return () => {
      activityDetector.stop();
    };
  }, []);
  return isIdle;
}

export const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return size;
};

export const useIsMobileMobx = () => {
  const { main } = useStore();
  const { setIsMobile, setIsTablet } = main;
  useLayoutEffect(() => {
    async function updateSize() {
      const windowWidth = window.innerWidth;
      if (windowWidth < 100) {
        return;
      }
      if (windowWidth <= 500) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
      if (windowWidth > 500 && windowWidth <= 1281) {
        setIsTablet(true);
      } else {
        setIsTablet(false);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
};

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, onOuterClick) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onOuterClick();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, onOuterClick]);
}

/**
 * Component that alerts if you click outside of it
 */
export default function OutsideAlerter(props) {
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, props.onOuterClick);

  return (
    <div style={props.style} className="outside_alerter" ref={wrapperRef}>
      {props.children}
    </div>
  );
}

export const useIsMountedRef = () => {
  const isMountedRef = useRef(null);
  useEffect(() => {
    isMountedRef.current = true;
    return () => (isMountedRef.current = false);
  });
  return isMountedRef;
};

// export class ErrorBoundary extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = { hasError: false };
//   }

//   static getDerivedStateFromError(error) {
//     // Update state so the next render will show the fallback UI.
//     return { hasError: true };
//   }

//   componentDidCatch(error, errorInfo) {
//     // You can also log the error to an error reporting service
//     // logErrorToMyService(error, errorInfo);
//     // localStorage.clear()
//     if (!isDev) {
//       window.location.reload();
//     }
//   }

//   render() {
//     if (this.state.hasError) {
//       // You can render any custom fallback UI

//       return !isDev ? <Redirect to={{ pathname: "/" }} /> : <></>;
//     }

//     return this.props.children;
//   }
// }

const ServerColumnsOptions = ({
  branch_id,
  report_title,
  token,
  logout,
  setError,
}) => {
  const [savedColumns, setSavedColumns] = useState([]);
  const saveViewOptions = async (columns) => {
    try {
      if (branch_id) return;
      const res = await Axios.post(
        `branch/${branch_id}/reportsOptions`,
        {
          report_title,
          columns,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    } catch (e) {
      console.error(e);
      if (e.response.status === 401) {
        logout();
      } else if (e.response.status === 500) {
        setError("ניתן לפנות לתמיכה הטכנית של רסטיגו  ", "ארעה שגיאה בשרת");
      }
    }
  };

  const fetchBranchViewOptions = async (branch_id, report_title) => {
    try {
      if (branch_id) return;
      const res = await Axios.get(`branch/${branch_id}/reportsOptions`, {
        params: {
          report_title,
        },
        headers: { Authorization: `Bearer ${token}` },
      });

      setSavedColumns([res.data.map((x) => x.column_title)]);
    } catch (e) {
      console.error(e);

      if (e.response.status === 401) {
        logout();
      } else if (e.response.status === 500) {
        setError("ניתן לפנות לתמיכה הטכנית של רסטיגו  ", "ארעה שגיאה בשרת");
      }
    }
  };

  return { savedColumns, fetchBranchViewOptions, saveViewOptions };
};
const columnsMapStateToProps = (state) => {
  const { token } = state.auth;
  const { id } = state.branches.selected_branch;

  return { token, branch_id: id };
};

export const useServerColumnsOptions = connect(columnsMapStateToProps, {
  logout,
  setError,
})(ServerColumnsOptions);

export const useFirstRender = () => {
  const firstRender = useRef();
  useEffect(() => {
    firstRender.current = true;
  }, []);
  return !firstRender.current;
};

/* 
    Responsive web design

      xs, extra-small: 0px
      sm, small: 600px
      md, medium: 900px
      lg, large: 1200px
      xl, extra-large: 1536px
    
      mobile: 0px - 600px
      tablet: 601px - 900px
      laptop: 901px - 1200px
      desktop: 1281px - ...
  */

export const useScreenSize = () => {
  const [isMobile, setIsMobile] = useState(true);
  const [isTablet, setIsTablet] = useState(true);
  const [isLaptop, setIsLaptop] = useState(true);
  const [isDesktop, setIsDesktop] = useState(true);

  const resize = useCallback(() => {
    const { innerWidth } = window;
    setIsMobile(innerWidth < 600);
    setIsTablet(innerWidth >= 601 && innerWidth < 900);
    setIsLaptop(innerWidth >= 901 && innerWidth < 1200);
    setIsDesktop(innerWidth >= 1201);
  }, []);

  useLayoutEffect(() => {
    resize();
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [resize]);

  return { isMobile, isTablet, isLaptop, isDesktop };
};
