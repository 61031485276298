import StoreBase from "./StoreBase";
import { action, computed, observable, makeObservable, toJS } from "mobx";
import { isEmpty, omit } from "lodash-es";
import Axios from "axios";
import { dateReqFormat } from "../helpers";

export const INVENTORY_TYPE_ALL = 0;
export const INVENTORY_TYPE_COUNT = 1;
export const INVENTORY_TYPE_THROW = 2;
export const INVENTORY_TYPE_TRANSFER = 3;
export const INVENTORY_TYPE_PULL = 4;
export const INVENTORY_TYPE_SALE = 5;
export const INVENTORY_TYPE_EXPENDITURE = 7;
export const INVENTORY_TYPE_DAILY_COUNT = 8;
const currentDate = new Date();

class InventoryDashboardStore extends StoreBase {
  formFields = {
    inventoryType: 0,
    branchesIds: [],
    dateRange: {
      startDate: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
      endDate: new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - 1
      ),
      key: "selection",
      label: "החודש",
    },
    token: "",
    itemsType: 0,
    showDailyCount: false,
    endDateChecked: true,
  };
  data = {};

  constructor() {
    super();
    makeObservable(this, {
      // Observables
      formFields: observable,
      data: observable,
      // Computed
      dateRangeFormatted: computed,
      isData: computed,
      // Actions
      handleChange: action,
      initDefaultValues: action,
      onSubmit: action,
      fetchInventoriesData: action,
      resetData: action,
    });
  }

  get isData() {
    return isEmpty(this.data);
  }

  get dateRangeFormatted() {
    if (isEmpty(this.formFields.dateRange)) {
      return null;
    }
    return [
      dateReqFormat(this.formFields.dateRange.startDate),
      dateReqFormat(this.formFields.dateRange.endDate),
    ];
  }

  handleChange = (key, value) => {
    this.formFields[key] = value;
  };

  initDefaultValues = (defaultValues) => {
    this.formFields = { ...this.formFields, ...defaultValues };
  };

  fetchInventoriesData = async () => {
    try {
      if (!this.formFields.token) {
        return;
      }

      if (
        this.dateRangeFormatted[0] === "Invalid date" ||
        this.dateRangeFormatted[1] === "Invalid date"
      )
        return;

      this.rootStore.main.setIsLoading(true);
      const response = await Axios.get("newInventory/analyse", {
        params: {
          branchesIds: this.formFields.branchesIds.length
            ? this.formFields.branchesIds
            : -99,
          dateRange: this.dateRangeFormatted,
          type: this.formFields.inventoryType,
          itemsType: this.formFields.itemsType,
          showDailyCount: this.formFields.showDailyCount,
          endDateChecked: this.formFields.endDateChecked,
        },
        headers: { Authorization: `Bearer ${this.formFields.token}` },
      });
      const { data } = response;

      const customBoxTypeExcel = [];
      const customScaleTypeExcel = [];
      const customWeightScaleTypeExcel = [];

      // Process typeBoxTypeScaleTable
      data.typeBoxTypeScaleTable = Object.values(data.typeBoxTypeScaleTable);

      data.typeBoxTypeScaleTable.forEach((item) => {
        const columns = [
          "actual_use_column",
          "closing_column",
          "expenditure_column",
          "opening_column",
          "shopping_column",
          "throw_column",
          "transfers_in",
          "transfers_out",
        ];

        columns.forEach((column) => {
          if (item[column] && Array.isArray(item[column].extra_data)) {
            const mergedData = item[column].extra_data.map((extraItem) => ({
              ...extraItem,
              worth: extraItem.worth.toFixed(1),
              item_name: item.item_name ?? "",
              branch_name: item.branch_name ?? "",
              part_number: item.part_number ?? "",
              supplier_name: item.supplier_name ?? "",
              category: item.category ?? "",
              amount: extraItem.amount.toFixed(1),
              scale: item[column].scale,
            }));

            customBoxTypeExcel.push(...mergedData);
          }
        });
      });

      // Process scaleTypeTable
      data.scaleTypeTable = Object.values(data.scaleTypeTable);

      data.scaleTypeTable.forEach((item) => {
        const columns = [
          "actual_use_column",
          "closing_column",
          "expenditure_column",
          "opening_column",
          "shopping_column",
          "throw_column",
          "transfers_in",
          "transfers_out",
        ];

        columns.forEach((column) => {
          if (item[column] && Array.isArray(item[column].extra_data)) {
            const mergedData = item[column].extra_data.map((extraItem) => ({
              ...extraItem,
              worth: extraItem.worth.toFixed(1),
              item_name: item.item_name ?? "",
              branch_name: item.branch_name ?? "",
              part_number: item.part_number ?? "",
              supplier_name: item.supplier_name ?? "",
              category: item.category ?? "",
              amount: item[column].amount.toFixed(1),
              scale: item[column].scale,
            }));

            customScaleTypeExcel.push(...mergedData);
          }
        });
      });

      // Process weightTypeTable
      data.weightTypeTable = Object.values(data.weightTypeTable);

      data.weightTypeTable.forEach((item) => {
        const columns = [
          "actual_use_column",
          "closing_column",
          "expenditure_column",
          "opening_column",
          "shopping_column",
          "throw_column",
          "transfers_in",
          "transfers_out",
        ];

        columns.forEach((column) => {
          if (item[column] && Array.isArray(item[column].extra_data)) {
            const mergedData = item[column].extra_data.map((extraItem) => ({
              ...extraItem,
              worth: extraItem.worth.toFixed(1),
              item_name: item.item_name ?? "",
              branch_name: item.branch_name ?? "",
              part_number: item.part_number ?? "",
              supplier_name: item.supplier_name ?? "",
              category: item.category ?? "",
              amount: item[column].amount.toFixed(1),
              scale: item[column].scale,
            }));

            customWeightScaleTypeExcel.push(...mergedData);
          }
        });
      });

      data.customBoxTypeExcel = customBoxTypeExcel;
      data.customScaleTypeExcel = customScaleTypeExcel;
      data.customWeightScaleTypeExcel = customWeightScaleTypeExcel;

      this.data = data;
    } catch (err) {
      console.error(err);
    }
    this.rootStore.main.setIsLoading(false);
  };

  onSubmit = () => {
    this.fetchInventoriesData();
  };

  resetData = () => {
    this.data = {};
  };
}

export default InventoryDashboardStore;
