import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  changeKeyObjects,
  dateReqFormat,
  fetchErrorMessages,
} from "../../../helpers";
import { deleteEvent } from "../../../redux/actions/scheduleAction";
import { mapKeys, sortBy } from "lodash";
import PageNav from "../../../components/PageNav";
// import Button from "../../../components/buttons/Button";
import Dropdown from "../../../components/dropdown/Dropdown";
import DateRangePicker from "../../../components/datepicker/DateRangePicker";
import Axios from "axios";
import { addDays, addMonths, endOfMonth, startOfMonth } from "date-fns";
import DefinedRange from "../../../components/datepicker/date-range-picker/DefinedRange";
import { hebStaticRanges } from "../../../components/datepicker/const";
import he from "date-fns/locale/he";
import {
  setActive,
  setIsFrame,
  setTitle,
} from "../../../redux/actions/publicAction";
import { logout } from "../../../redux/actions/loginAction";
import { setError } from "../../../redux/actions/errorAction";
import SkeletonReports from "../../../components/skeletons/SkeletonReports";
import { setConfirm } from "../../../redux/actions/confirmAction";
import TableConstructor from "./TableConstructor";
import MultiSelectCheckbox from "../../../components/dropdown/MultiSelectCheckBox";
import WorkForceFilter from "./filters/WorkForceFilter";
import {
  EDIT_SCHEDULE_PRIVILEGE,
  EDIT_INSIDE_REPORTS_PRIVILEGE,
  LIMIT_CHANGE_SCHEDULE_PRIVILEGE,
  SHOW_WAITER_REPORT_PRIVILEGE,
  NETWORK_SALARY_REPORT,
} from "../../../const/privilegesButtons";
import SegmentedControl from "../../../components/SegmentedControl";
import BtnTooltip from "../../../components/tooltips/BtnTooltip";
import { SHOW_ONLY_MY_EMPLOYEE_REPORTS } from "../../../privilegesIndexes";
import { useScreenSize } from "../../../Hooks";
import { Button } from "@mui/material";
import SalaryByIdentifierFilter from "./filters/SalaryByIdentifierFilter";

const NAV_OPTIONS_MANAGER = [
  "events",
  "salary",
  "salary_position",
  "salary_department",
  // "salary_by_identifier",
  "tips",
  "daily_tips",
  "summary_tips",
  "anomalies",
  "work_force",
  "futureAssignsReport",
];

const ANOMALIES_OPTIONS = [
  { value: 1, label: "חוקי עבודה" },
  { value: 2, label: "משמרות פתוחות" },
];

const NAV_OPTIONS_EMPLOYEE = ["events", "tips"];

const ReportsEmployees = ({
  token,
  user,
  employees,
  selected_branch,
  isManager,
  name,
  setActive,
  deleteEvent,
  setIsFrame,
  logout,
  setError,
  setConfirm,
  isAllowedToEdit,
  employees_transfer,
  title,
  setTitle,
  blocked_from_date,
  roles,
  fill,
}) => {
  const { isMobile } = useScreenSize();
  const [options, setOptions] = useState({
    reports: isManager
      ? [...NAV_OPTIONS_MANAGER, ...fill]
      : NAV_OPTIONS_EMPLOYEE,
    employees: [],
    departments: [],
    anomaliesOptions: [],
  });

  const [selectedReport, setSelectedReport] = useState({
    index: 0,
    name: "events",
  });
  const [
    selectedDepartmentFilteringMethod,
    setSelectedDepartmentFilteringMethod,
  ] = useState(0);
  const [checkedEmployees, setCheckedEmployees] = useState([]);
  const [checkedDepartments, setCheckedDepartments] = useState([]);
  const [checkedAnomalies, setCheckedAnomalies] = useState([]);
  const [selectedDates, setSelectedDates] = useState({
    startDate: startOfMonth(addMonths(new Date(), -1)),
    endDate: endOfMonth(addMonths(new Date(), -1)),
    key: "selection",
    label: "חודש שעבר",
  });
  const [isFilterSubmissionDates, setIsFilterSubmissionDates] = useState(false);
  const [isFilterCreationDates, setIsFilterCreationDates] = useState(false);
  const [isFilterAttendanceDate, setIsFilterAttendanceDate] = useState(false);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedWorkForceFormsFilter, setSelectedWorkForceFormsFilter] =
    useState([]);
  const [selectedWorkForceEnableFilter, setSelectedWorkForceEnableFilter] =
    useState({ name: "פעילים", id: 1 });
  const [showAllBranches, setShowAllBranches] = useState(false);
  const [trigerAnomaliesFetchData, setTrigerAnomaliesFetchData] =
    useState(false);
  const [checkedBranches, setCheckedBranches] = useState([]);

  const createOptions = useMemo(
    () => async (employeesObject, rolesObject) => {
      const employees = Object.values(employeesObject);
      const roles = Object.values(rolesObject);
      let departmentsOptions = mapKeys(roles, "department_id");

      const employeesOptions =
        selectedReport.name === "salary_by_identifier"
          ? employees.map((employee) => ({
              label: employee.full_name,
              value: employee.employee_ID_Number ?? employee.id,
              additionalEmployeeId: employee.id,
            }))
          : await changeKeyObjects(employees, {
              full_name: "label",
              id: "value",
            });
      departmentsOptions = Object.values(departmentsOptions);
      departmentsOptions = await changeKeyObjects(departmentsOptions, {
        department_name: "label",
        department_id: "value",
      });

      setOptions({
        ...options,
        employees: employeesOptions,
        departments: departmentsOptions,
        anomaliesOptions: ANOMALIES_OPTIONS,
      });
    },
    [selectedReport.name]
  );

  useEffect(() => {
    setActive(name);
    setTitle(title);
    setIsFrame(false);
  }, []);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      createOptions(employees, roles);
      setData([]);
    }
    return () => {
      mounted = false;
    };
  }, [employees, roles, createOptions, selected_branch]);

  const handleSimpleEmployeeConfirm = useMemo(
    () => async (selection) => {
      setLoader(true);
      const startDate = await dateReqFormat(selection.startDate);
      const endDate = await dateReqFormat(selection.endDate);
      try {
        const response = await Axios.get(
          `reports/employees/${selectedReport.name}`,
          {
            params: {
              branch: user.branch_id,
              startDate, //required
              endDate, //required
              department_ids: null,
              employee_ids: [user.id],
            },
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setData(response.data);
      } catch (e) {
        switch (e?.response?.status) {
          case 401:
            logout();
            break;
          default:
            setError();
            break;
        }
        setData([]);
      } finally {
        setLoader(false);
      }
    },
    [selectedReport]
  );

  const fetchData = useMemo(
    () => async (employee_ids) => {
      const startDate = await dateReqFormat(selectedDates.startDate);
      const endDate = await dateReqFormat(selectedDates.endDate);

      try {
        const response = await Axios.get(
          `reports/employees/${
            selectedReport.name === "events_summary"
              ? "events"
              : selectedReport.name
          }`,
          {
            params: {
              selectedDepartmentFilteringMethod: isManager
                ? selectedDepartmentFilteringMethod
                : 1,
              branch:
                selectedReport.name === "work_force" ||
                selectedReport.name === "salary_by_identifier"
                  ? checkedBranches
                  : isManager
                  ? selected_branch.id
                  : user.branch_id,
              startDate, //required
              endDate, //required
              department_ids: isManager
                ? checkedDepartments.length === options.departments.length
                  ? []
                  : checkedDepartments
                : null,
              employee_ids: isManager
                ? employee_ids.length === options.employees.length
                  ? []
                  : employee_ids
                : [user.id],
              by_pos: selectedReport.name === "salary_position" ? true : null,
              by_department:
                selectedReport.name === "salary_department" ? true : null,
              show_all_branches: ["transfers", "anomalies"].includes(
                selectedReport.name
              )
                ? showAllBranches
                : undefined,
              workForceFormsFilters:
                selectedReport.name === "work_force"
                  ? selectedWorkForceFormsFilter.map((x) => x.value)
                  : null,
              isFilterSubmissionDates,
              isFilterCreationDates,
              isFilterAttendanceDate,
              enableFilter:
                selectedReport.name === "work_force"
                  ? selectedWorkForceEnableFilter.id
                  : null,
              anomaliesFilter: checkedAnomalies,
            },
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        if (selectedReport.name === "events") {
          let modifiedData = response.data.map((employeeData) => {
            let temp = { ...employeeData };
            temp.body = sortBy(temp.body, "working_date");
            return temp;
          });
          modifiedData.forEach((employee) => {
            const shiftsByDate = {};
            employee.body.forEach((shift) => {
              if ("is_accepted" in shift && !shift?.is_accepted) {
                shift.className = "error";
                shift.notes =
                  "לעובד זה חסר אישור מנהל , לאישור השעות ניתן ללחוץ על עפרון - שמירת שעות";
              }
              if (shift.hours_total > 15) {
                shift.className = "error";
                shift.notes = "עבד מעל 15 שעות";
              }
              const { working_date } = shift;
              if (!shiftsByDate[working_date]) {
                shiftsByDate[working_date] = [];
              }
              shiftsByDate[working_date].push(shift);
              if (
                shift.actual_end == shift.actual_start &&
                shift.hours_100 == 0 &&
                shift.hours_125 == 0 &&
                shift.hours_150 == 0
              ) {
                shift.className = "error";
                shift.notes = "משמרת ללא שעות נדרש לטפל!";
              }
            });

            const getMinutesFromTime = (time) => {
              if (!time) return null; // Return null if the time is null
              const [hours, minutes, seconds] = time.split(":").map(Number);
              return hours * 60 + minutes + seconds / 60;
            };
            Object.keys(shiftsByDate).forEach((date) => {
              const shiftsOnDate = shiftsByDate[date];
              shiftsOnDate.sort((a, b) => {
                return (
                  getMinutesFromTime(a.actual_start) -
                  getMinutesFromTime(b.actual_start)
                );
              });

              for (let i = 0; i < shiftsOnDate.length - 1; i++) {
                const currentShift = shiftsOnDate[i];
                const nextShift = shiftsOnDate[i + 1];

                const currentStartTime = getMinutesFromTime(
                  currentShift.actual_start
                );
                const currentEndTime = getMinutesFromTime(
                  currentShift.actual_end
                );
                const nextStartTime = getMinutesFromTime(
                  nextShift.actual_start
                );
                const nextEndTime = getMinutesFromTime(nextShift.actual_end);
                if (
                  currentStartTime !== null &&
                  currentEndTime !== null &&
                  nextStartTime !== null &&
                  nextEndTime != null
                ) {
                  if (currentStartTime < currentEndTime) {
                    if (currentEndTime > nextStartTime) {
                      currentShift.className = "error";
                      nextShift.className = "error";
                      currentShift.notes = "משמרת חופפת נדרש לטפל";
                      nextShift.notes = "משמרת חופפת נדרש לטפל";
                    }
                  } else {
                    if (currentEndTime < nextStartTime) {
                      currentShift.className = "error";
                      nextShift.className = "error";
                      currentShift.notes = "משמרת חופפת נדרש לטפל";
                      nextShift.notes = "משמרת חופפת נדרש לטפל";
                    }
                  }
                }
              }
            });
          });
          return modifiedData;
        }
        if (
          selectedReport.name === "work_force" &&
          response.data.length === 0
        ) {
          setError(
            "יש לבחור סינון רלוונטי אחר",
            "לא נמצאו עובדים בסינון הרלוונטי",
            false,
            true
          );
        }
        return response.data;
      } catch (e) {
        switch (e?.response?.status) {
          case 401:
            logout();
            break;
          default:
            setError();
            break;
        }

        return [];
      }
    },
    [
      selectedReport,
      selectedDates,
      selectedWorkForceFormsFilter,
      selectedWorkForceEnableFilter,
      isFilterSubmissionDates,
      isFilterCreationDates,
      isFilterAttendanceDate,
      checkedDepartments,
      checkedAnomalies,
      isManager,
      logout,
      setError,
      selected_branch,
      showAllBranches,
      token,
      user,
      selectedDepartmentFilteringMethod,
      options,
      checkedBranches,
    ]
  );

  const handleSubmit = async (event = null) => {
    if (event) event.preventDefault();
    setLoader(true);
    const data = await fetchData(checkedEmployees);
    setTrigerAnomaliesFetchData((prev) => !prev);
    setData(data);
    setLoader(false);
  };

  return (
    <div className="reports">
      <div>
        <h2 style={isMobile ? { margin: "1rem" } : { marginBottom: "2rem" }}>
          דוחות עובדים
        </h2>
        <PageNav
          isMobile={isMobile}
          options={options.reports.map((name, index) => ({ name, index }))}
          onClick={(report) => {
            if (report.name === "futureAssignsReport") {
              setSelectedDates({
                startDate: new Date(),
                endDate: new Date(),
                key: "selection",
                label: "היום",
              });
            }
            setSelectedReport(report);
            setData([]);
          }}
        />

        {isManager ? (
          <div
            style={isMobile ? {} : { padding: "0 4rem 0 1.5rem" }}
            className={`${isMobile ? "" : "flex-between"}`}
          >
            <div
              style={
                isMobile
                  ? { width: "100%" }
                  : { marginTop: "3rem", width: "fit-content" }
              }
            >
              <form
                className={`${isMobile ? "" : "flex-start"}`}
                onSubmit={handleSubmit}
              >
                <div style={isMobile ? {} : { marginLeft: "2rem" }}>
                  {selectedReport.name === "work_force" ? (
                    ""
                  ) : (
                    <label>
                      תקופה
                      <Dropdown
                        style={
                          isMobile
                            ? {
                                minWidth: "100%",
                                fontSize: "1.4rem",
                                display: "block",
                                margin: "0",
                              }
                            : {
                                width: "100%",
                                textAlign: "center",
                                fontSize: " 1.4rem",
                                fontWeight: "500",
                                maxWidth: "24rem",
                                minWidth: "16rem",
                                borderRadius: "5px",
                                color: "#747474",
                                backgroundColor: "#F7F7F7",
                                position: "relative",
                              }
                        }
                        contentStyle={{ width: "fit-content" }}
                        text={selectedDates.label}
                      >
                        {isMobile ? (
                          <DefinedRange
                            ranges={[selectedDates]}
                            staticRanges={hebStaticRanges}
                            local={he}
                            onChange={(item) =>
                              setSelectedDates(item.selection)
                            }
                          />
                        ) : (
                          <DateRangePicker
                            selectedRange={selectedDates}
                            setSelectedRange={setSelectedDates}
                          />
                        )}
                      </Dropdown>
                    </label>
                  )}
                </div>
                <div style={isMobile ? {} : { marginLeft: "2rem" }}>
                  <label>
                    עובדים
                    <MultiSelectCheckbox
                      title="עובדים"
                      style={{ maxWidth: "unset" }}
                      checked={checkedEmployees}
                      setChecked={setCheckedEmployees}
                      options={options.employees}
                    />
                  </label>
                </div>
                {selectedReport.name === "anomalies" ? (
                  <div style={isMobile ? {} : { marginLeft: "2rem" }}>
                    <label>
                      סוג חריגה
                      <MultiSelectCheckbox
                        title="סוג חריגה"
                        style={{ maxWidth: "unset" }}
                        checked={checkedAnomalies}
                        setChecked={setCheckedAnomalies}
                        options={options.anomaliesOptions}
                      />
                    </label>
                  </div>
                ) : (
                  ""
                )}
                {!["transfers", "anomalies"].includes(selectedReport.name) ? (
                  <>
                    <div style={isMobile ? {} : { marginLeft: "2rem" }}>
                      <label>
                        מחלקה
                        <MultiSelectCheckbox
                          title="מחלקות"
                          style={{ maxWidth: "unset" }}
                          checked={checkedDepartments}
                          setChecked={setCheckedDepartments}
                          options={options.departments}
                        />
                      </label>
                    </div>
                    {[
                      "events",
                      "events_summary",
                      "tips",
                      "futureAssignsReport",
                      "summary_tips",
                    ].includes(selectedReport.name) && true ? (
                      <div style={isMobile ? {} : { marginLeft: "2rem" }}>
                        <label>
                          <BtnTooltip
                            text={
                              <p>
                                <strong>סידור -</strong>
                                {` יסנן את העובדים המשובצים במחלקות הנבחרות שבסידור (סינון לא כולל את "לא משובצים")`}
                                <br />
                                <strong>הגדרה -</strong>
                                {` יסנן את העובדים עפ"י המחלקות בהן מוגדרים בכרטיס עובד (סינון כולל את "לא משובצים")`}
                              </p>
                            }
                          >
                            <i
                              style={{
                                fontSize: "1.6rem",
                                marginLeft: "0.5rem",
                              }}
                              className="fas fa-info-circle"
                            />
                          </BtnTooltip>
                          מחלקה עפ"י
                          <SegmentedControl
                            setSelected={setSelectedDepartmentFilteringMethod}
                            selected={selectedDepartmentFilteringMethod}
                            options={[{ text: "הגדרה" }, { text: "סידור" }]}
                          />
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <div
                    style={
                      isMobile ? {} : { marginLeft: "2rem", marginTop: "2rem" }
                    }
                  >
                    <label className="checkbox--container">
                      הצג את כל הסניפים
                      <input
                        type="checkbox"
                        checked={showAllBranches}
                        onChange={() => setShowAllBranches(!showAllBranches)}
                      />
                      <span className="checkbox--checkmark"></span>
                    </label>
                  </div>
                )}
                {selectedReport.name === "work_force" ? (
                  <WorkForceFilter
                    {...{
                      isMobile,
                      selectedWorkForceEnableFilter,
                      setSelectedWorkForceEnableFilter,
                      isFilterSubmissionDates,
                      setIsFilterSubmissionDates,
                      isFilterCreationDates,
                      setIsFilterCreationDates,
                      isFilterAttendanceDate,
                      setIsFilterAttendanceDate,
                      selectedDates,
                      setSelectedDates,
                      selectedWorkForceFormsFilter,
                      setSelectedWorkForceFormsFilter,
                      checkedBranches,
                      setCheckedBranches,
                    }}
                  />
                ) : (
                  ""
                )}
                {selectedReport.name === "salary_by_identifier" ? (
                  <SalaryByIdentifierFilter
                    {...{ checkedBranches, setCheckedBranches }}
                  />
                ) : (
                  ""
                )}

                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    marginTop: "18px",
                    fontSize: "1.4rem",
                    width: "100px",
                    height: "3em",
                    ...(isMobile && { width: "100%" }),
                  }}
                  type="submit"
                >
                  בצע
                </Button>
              </form>
            </div>
          </div>
        ) : (
          <div className="flex-start margin-y">
            <Dropdown
              style={{
                maxWidth: "16rem",
                fontSize: "1.4rem",
                display: "block",
              }}
              contentStyle={{ width: "fit-content" }}
              text={selectedDates.label}
            >
              <DefinedRange
                ranges={[selectedDates]}
                staticRanges={hebStaticRanges}
                local={he}
                onChange={(item) => setSelectedDates(item.selection)}
              />
            </Dropdown>
            <Button
              variant="contained"
              onClick={() => handleSimpleEmployeeConfirm(selectedDates)}
              color="primary"
            >
              בצע
            </Button>
          </div>
        )}
        <SkeletonReports loader={loader} />
        <TableConstructor
          type={selectedReport.name}
          date={{
            start: selectedDates.startDate,
            end: selectedDates.endDate,
          }}
          {...{
            checkedDepartments,
            checkedEmployees,
            options,
            data,
            selected_branch,
            logout,
            token,
            setError,
            showAllBranches,
            isMobile,
            isManager,
            fetchData,
            isAllowedToEdit,
            blocked_from_date,
            deleteEvent,
            setConfirm,
            setData,
            handleSubmit,
            trigerAnomaliesFetchData,
            checkedBranches,
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { token, user } = state.auth;
  const { employees, roles } = state;
  const { isMobile, isTablet } = state.publicValues;
  const { selected_branch } = state.branches;
  const { privileges_buttons, employees_transfer } = state.user_privileges;
  const isManager =
    privileges_buttons.includes(EDIT_SCHEDULE_PRIVILEGE) &&
    !privileges_buttons.includes(SHOW_ONLY_MY_EMPLOYEE_REPORTS);
  const isAllowedToEdit = privileges_buttons.includes(
    EDIT_INSIDE_REPORTS_PRIVILEGE
  );
  const showWaiterReport = privileges_buttons.includes(
    SHOW_WAITER_REPORT_PRIVILEGE
  );

  const showNetworkSalaryReport = privileges_buttons.includes(
    NETWORK_SALARY_REPORT
  );
  const { block_slot_span } = selected_branch;
  let blocked_from_date = null;
  if (privileges_buttons.includes(LIMIT_CHANGE_SCHEDULE_PRIVILEGE)) {
    if (!(block_slot_span === null || block_slot_span === undefined)) {
      blocked_from_date = dateReqFormat(
        addDays(new Date(), -1 - block_slot_span)
      );
    }
  }
  let fill = [];
  if (showWaiterReport) {
    fill = ["waiter", "events_summary"];
  }
  if (showNetworkSalaryReport) {
    fill = [...fill, "salary_by_identifier"];
  }
  if (employees_transfer) {
    fill = [...fill, "transfers"];
  }
  return {
    token,
    roles,
    isManager,
    isAllowedToEdit,
    user,
    employees_transfer,
    employees,
    selected_branch,
    isMobile: isMobile || isTablet,
    blocked_from_date,
    fill,
  };
};
export default connect(mapStateToProps, {
  deleteEvent,
  setActive,
  setIsFrame,
  logout,
  setError,
  setTitle,
  setConfirm,
})(ReportsEmployees);
