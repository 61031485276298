// LoginAction
import {
  LOGIN,
  LOGOUT,
  FADE,
  FETCH_USER_PRIVILEGES,
  FETCH_USER,
} from "./types";
import authService from "../../../config/auth";
import Axios from "axios";
import { SET_ERROR } from "../errorAction/types";
import { isFunction } from "lodash";
// import { BASE_URL } from "../../../config/adminAuth";
import { START_LOADING, STOP_LOADING } from "../loaderAction";
import { setError } from "../errorAction";
import { LOAD_EMPLOYEE_DATA } from "../form101Action/type";
const BASE_URL = process.env.REACT_APP_URL;
//Login
export const login =
  ({ email, password, remember_me = false }, setLoader, setError) =>
  async (dispatch) => {
    try {
      const response = await authService.login(email, password, remember_me);
      const token = response.auth_token;


      const response_user = await Axios.post("jwttokenlogin", null, {
        params: {
          token,
          fcm_token: localStorage.getItem("fcm_token"),
        },
      });

      const user = response_user.data;
      dispatch({
        type: LOGIN,
        payload: { token, user, password, email },
      });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          setError("אימייל משתמש או סיסמא שגויים, יש לוודא כי השדות נכונים");
          // dispatch({
          //   type: SET_ERROR,
          //   errorTitle: `התחברות נכשלה`,
          //   error: "אימייל משתמש או סיסמא שגויים, יש לוודא כי השדות נכונים",
          //   warning: true,
          // });
        } else if (e.response.status === 403) {
          setError("משתמש זה כבר אינו פעיל במערכת");

          // dispatch({
          //   type: SET_ERROR,
          //   errorTitle: `התחברות נכשלה`,
          //   error: "משתמש זה כבר אינו פעיל במערכת",
          //   warning: true,
          // });
        }
      if (isFunction(setLoader)) {
        setLoader(false);
      }
    }
  };

export const jwtLogin = (token) => async (dispatch) => {
  try {
    const response_user = await Axios.post("jwttokenlogin", null, {
      params: {
        token,
      },
    });

    const user = response_user.data;

    dispatch({ type: LOGIN, payload: { token, user } });
  } catch (e) {
    console.error(e);
  }
};

export const logConnectedUser = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, text: "מחליף משתמש.." });

    const response = await Axios.post(
      "logConnectedUser",
      { id },
      {
        headers: { Authorization: `Bearer ${getState().auth.token}` },
      }
    );

    const user = response.data.data;
    const token = response.data.auth_token;

    await window.open(`${BASE_URL}/logout`, "_self");
    document.cookie =
      "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    dispatch({ type: LOGIN, payload: { token, user } });

    window.location.replace(`${window.location.origin}/#/users`);
  } catch (e) {
    console.error(e);
    if (e.response.status === 401) {
      dispatch({ type: LOGOUT });
    }
  }
  dispatch({ type: STOP_LOADING });
};

export const fetchUserPrivileges = () => async (dispatch, getState) => {
  try {
    const response = await Axios.post("userPrivileges", null, {
      headers: { Authorization: `Bearer ${getState().auth.token}` },
    });
    const { data } = response;
    dispatch({ type: FETCH_USER_PRIVILEGES, ...data });
  } catch (e) {
    if (e.response) {
      if (e.response.status === 401) {
        dispatch({ type: LOGOUT });
      }
    }
  }
};

export const checkConnection = () => async (dispatch, getState) => {
  try {
    await Axios.post("checkJWTConnection", null, {
      headers: { Authorization: `Bearer ${getState().auth.token}` },
    });
  } catch (e) {
    if (e.response) {
      if (e.response.status === 401 || e.response.status === 403) {
        dispatch({ type: LOGOUT });
      }
    }
  }
};

export const restorePassword =
  (
    { identifier, phone_number, email },
    setOTPModal = () => {},
    setLoader = () => {},
    setOTPModalError = () => {}
  ) =>
  async (dispatch) => {
    try {
      const response = await Axios.post("restorepass", {
        identifier,
        phone_number,
        email,
      });

      setOTPModal({ ...response.data.data });
      setLoader(false);
    } catch (e) {
      console.error(e, "hi");
      if (e.response) {
        if (e.response.status === 401) {
          dispatch({
            type: SET_ERROR,
            errorTitle: `התחברות נכשלה`,
            error: "אימייל משתמש שגוי, יש לוודא כי הזנת אימייל נכון",
            warning: true,
          });
        } else if (e.response.status === 409) {
          dispatch({
            type: SET_ERROR,
            errorTitle: `שגיאת אבטחה`,
            error:
              "אימייל או מס' טלפון לא תקינים, לא ניתן לשלוח קוד לשחזור סיסמא. \r\n ניתן לפנות לתמיכה הטכנית של רסטיגו  ",
          });
        } else if (e.response.status === 429) {
          setOTPModalError({ ...e.response.data.data });
          dispatch({
            type: SET_ERROR,
            errorTitle: `שגיאת אבטחה`,
            error:
              "חשבונך נחסם עקב ריבוי נסיונות נסה שוב מאוחר יותר. \r\n ניתן לפנות לתמיכה הטכנית של רסטיגו  ",
          });
        }
      }
    }
    setLoader(false);
  };

export const restoreOTP =
  (login_user_web_id, { password, code }) =>
  async (dispatch) => {
    try {
      const response = await Axios.post("OTPrestore", {
        login_user_web_id,
        password,
        code,
      });

      const token = response.data.data.auth_token;
      const { email } = response.data.data;
      const response_user = await Axios.post("jwttokenlogin", null, {
        params: {
          token,
        },
      });

      const user = response_user.data;
      dispatch({
        type: LOGIN,
        payload: { token, user, password, email },
      });
    } catch (e) {
      console.error(e);
      if (e.response) {
        if (e.response.status === 401) {
          dispatch({
            type: SET_ERROR,
            errorTitle: `שגיאת אבטחה`,
            error: "קוד אימות לא תואם",
            timeout: true,
          });
        }
      }
    }
  };

export const loginOTP = (email, password, code) => async (dispatch) => {
  try {
    const response = await Axios.post("jwtOTPlogin", {
      email,
      password,
      code,
    });

    const token = response.data.auth_token;
    const admin = response.data.data;
    dispatch({ type: LOGIN, payload: { token, admin } });
  } catch (e) {
    console.error(e);
    if (e.response) {
      if (e.response.status === 401) {
        dispatch({
          type: SET_ERROR,
          errorTitle: `שגיאת אבטחה`,
          error: "קוד אימות לא תואם",
          timeout: true,
        });
      }
    }
  }
};

//Logout
export const logout = () => async (dispatch) => {
  dispatch({ type: LOGOUT });
};

//Fade
export const fade = () => async (dispatch) => {
  dispatch({ type: FADE });
};

export const fetchUser = (id) => async (dispatch, getState) => {
  try {
    const { data: user } = await Axios.get(`users/${id}`, {
      headers: { Authorization: `Bearer ${getState().auth.token}` },
    });

    dispatch({ type: FETCH_USER, payload: user });
  } catch (error) {
    dispatch(setError());
  }
};
