import Axios from "axios";
import { pick } from "lodash";
import { objectToFormData } from "../../../components/Forms/helpers";
import { dynamicToFixed, getSafeDivision, printView } from "../../../helpers";
import { ITEM_PRICE_LOG_TYPES, NEW_ITEM_ID } from "../../../pages/goods/const";
import {
  COUNT_TYPE_BOX_TYPE,
  COUNT_TYPE_NONE,
} from "../../../pages/products/const";
import { setConfirm, setSnackBar } from "../confirmAction";
import { SET_ERROR } from "../errorAction/types";
import { startLoading, stopLoading } from "../loaderAction";
import {
  CLOSE_FORM,
  CREATE_NEW_ITEM,
  EDIT_CATEGORY,
  EDIT_ITEM,
  FETCH_CATALOG,
  FETCH_CATEGORIES,
  FETCH_ITEMS,
  FETCH_ITEMS_FREE_SEARCH,
  FETCH_POS_CODES,
  FETCH_UNIFIED_PRODUCTS,
  SET_FREESEARCH_ID,
  SET_ITEM_ERROR,
  SET_PRODUCT,
  SET_SUPPLIERS_OPTIONS,
  STORE_CATEGORY,
  STORE_ITEM,
  TOGGLE_CATEGORY,
  TOGGLE_ITEM,
  UPDATE_CATEGORY,
  SET_DID_CATEGORY_CHANGE,
  FETCH_PRICE_GAPS,
  FETCH_ALLERGENS,
} from "./type";
import {
  categoriesURI,
  endLoadingCatalog,
  endLoadingDetailsCategory,
  endLoadingDetailsItem,
  endLoadingFreeSearch,
  endLoadingMasterCategory,
  endLoadingMasterItems,
  endLoadingPosCodes,
  endLoadingPriceGaps,
  endLoadingProducts,
  endLoadingSuppliers,
  endLoadingSwapIngredient,
  endLoadingUnifiedProducts,
  isExistsURI,
  itemsURI,
  logsURI,
  posCodesURI,
  allergensURI,
  priceGapsURI,
  productsURI,
  startLoadingCatalog,
  startLoadingDetailsItem,
  startLoadingFreeSearch,
  startLoadingMasterCategory,
  startLoadingMasterItems,
  startLoadingPosCodes,
  startLoadingPriceGaps,
  startLoadingProducts,
  startLoadingSuppliers,
  startLoadingSwapIngredient,
  startLoadingUnifiedProducts,
  startLodingDetailsCategory,
  supplierCatalog,
  suppliersURI,
  sweepingURI,
  toggleCategoriesURI,
  toggleURI,
  unifiedProductsURI,
  uploadMultiURI,
  startLoadingAllergens,
  endLoadingAllergens,
} from "./const";

// TODO: Add loading state for CRUD actions

const error = (e) => async (dispatch, getState) => {
  const isMobile = getState().publicValues.isMobile;
  const status = e?.response?.status;

  if (e.response)
    if (status === 401) {
      // dispatch({ type: LOGOUT });
    } else if (status === 500 || status === 413) {
      dispatch({
        type: SET_ERROR,
        errorTitle: "ארעה שגיאה בשרת",
        error: isMobile
          ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
          : "\n אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו" +
            "\n Message: " +
            e?.response?.data?.message,
      });
    } else if (status === 422) {
      // 422 laravel validation error

      const data = Object.values(
        e.response.data?.errors ?? e.response.data?.file
      );

      dispatch({
        type: SET_ERROR,
        errorTitle: (
          <div className="flex-center" style={{ direction: "ltr" }}>
            {e.response.data?.message}
          </div>
        ),
        error: (
          <div className="flex-center" style={{ direction: "ltr" }}>
            <ul>
              {data.map((e, index) => (
                <li key={index} style={{ textAlign: "left" }}>
                  {e}
                </li>
              ))}
            </ul>
          </div>
        ),
      });
    } else if (Axios.isCancel(e)) {
      dispatch({
        type: SET_ERROR,
        errorTitle: "Request Canceld",
        error: "Operation canceled by the user.",
      });
    } else if (status === 410) {
      //error 410 when try to remove all branches to items
      const itemsName = e?.response?.data.map((error) => error.item);

      dispatch({
        type: SET_ERROR,
        errorTitle: "ארעה שגיאה בעדכון המוצר",
        error: `המוצרים הבאים : ${itemsName.join(
          ","
        )} חייבים לכלול סניף אחד לפחות!`,
      });
    }
};

//  =========== CATEGORY CRUD SECTION START ===========

// CATEGORY MASTER
export const fetchCategories = () => async (dispatch, getState) => {
  const token = getState().auth.token;

  const { current_page, per_page } = getState().goods.categories.pagination;

  const { status, other } = getState().goods.categories.filter;

  const params = {
    ...(!!status.length
      ? {
          status: status.map((value) => value - 1),
        }
      : {
          status: [1],
        }),
    ...(!!other.length && {
      other,
    }),
  };

  try {
    dispatch(startLoadingMasterCategory);

    const { data } = await Axios.get(categoriesURI, {
      params,
      headers: { Authorization: `Bearer ${token}` },
    });

    dispatch({
      type: FETCH_CATEGORIES,
      payload: Object.values(data),
    });
  } catch (e) {
    dispatch(error(e));
  } finally {
    dispatch(endLoadingMasterCategory);
  }
};

// CATEGORY DETAILS
export const fetchCategory = (id) => async (dispatch, getState) => {
  const token = getState().auth.token;

  try {
    dispatch(startLodingDetailsCategory);

    const { data } = await Axios.get(`${categoriesURI}/showCategory/${id}`, {
      headers: { Authorization: `Bearer ` + token },
    });

    // 'לפי סניף' toggler
    // system categories return without selected_branches
    data.debitByBranch = !!data?.selected_branches?.length;

    dispatch({
      type: EDIT_CATEGORY,
      payload: data,
    });
  } catch (e) {
    dispatch(error(e));
  } finally {
    dispatch(endLoadingDetailsCategory);
  }
};

// DELETE CATEGORY
export const toggleCategory = (id) => async (dispatch, getState) => {
  const token = getState().auth.token;

  try {
    const { data } = await Axios.post(`${toggleCategoriesURI}/${id}`, null, {
      headers: { Authorization: `Bearer ` + token },
    });

    dispatch({
      type: TOGGLE_CATEGORY,
      payload: data,
    });
  } catch (e) {
    dispatch(error(e));
  }
};

// CREATE CATEGORY
export const storeCategory = (category) => async (dispatch, getState) => {
  const token = getState().auth.token;

  try {
    const { data } = await Axios.post(categoriesURI, category, {
      headers: { Authorization: `Bearer ` + token },
    });

    dispatch({ type: STORE_CATEGORY, payload: data });

    dispatch(setSnackBar(`קטגוריה חדשה "${data.name}" נוצרה בהצלחה!`, true));
  } catch (e) {
    dispatch(error(e));
  }
};

// UPDATE CATEGORY
export const updateCategory = (category) => async (dispatch, getState) => {
  const token = getState().auth.token;
  if (!category.debitByBranch) category.selected_branches = [];
  const formData = objectToFormData(category);
  formData.append("_method", "PUT");

  try {
    dispatch(startLoading("מעדכן..."));

    const { data } = await Axios.post(
      `${categoriesURI}/updateCategory/${category.id}`,
      formData,
      {
        headers: { Authorization: `Bearer ` + token },
      }
    );

    dispatch({ type: UPDATE_CATEGORY, payload: data });
    dispatch(fetchCategories());

    dispatch(setConfirm("עודכן בהצלחה!"));
  } catch (e) {
    dispatch(error(e));
  } finally {
    dispatch(stopLoading());
  }
};

//  =========== CATEGORY CRUD SECTION END ===========

//  =========== ITEMS CRUD SECTION START ===========

// ITEMS MASTER (INDEX METHOD)
export const fetchItems = () => async (dispatch, getState) => {
  const token = getState().auth.token;
  const cancelToken = Axios.CancelToken.source();
  const { current_page, per_page } = getState().goods.items.pagination;

  const {
    status,
    branchId: { id: branchId },
    categoryId,
    suppliersId,
    other,
  } = getState().goods.items.filter;

  const params = {
    ...(!!status.length
      ? {
          status: status.map((value) => value - 1),
        }
      : {
          status: [1],
        }),
    branchId,
    ...(!!categoryId.length && {
      categoryId,
    }),
    ...(!!suppliersId.length && {
      suppliersId,
    }),
    ...(!!other.length && {
      other,
    }),
    page: current_page + 1,
    per_page,
  };

  try {
    dispatch(startLoadingMasterItems);

    const { data } = await Axios.get(itemsURI, {
      params,
      cancelToken: cancelToken.token,
      headers: { Authorization: `Bearer ${token}` },
    });

    dispatch({ type: FETCH_ITEMS, payload: data });
  } catch (e) {
    dispatch(error(e));
  } finally {
    dispatch(endLoadingMasterItems);
  }
};

// ITEMS DETAILS (FETCH / DUPLICATE, SHOW METHOD)
export const fetchItem =
  (id, duplicate = false) =>
  async (dispatch, getState) => {
    const token = getState().auth.token;
    const categories = getState().goods.categories.data.master.data;

    const selectedBranchId = getState().goods.items.filter.branchId.id;
    const userBranches = getState().branches.branches;
    const selectedBranches = selectedBranchId
      ? userBranches.filter(({ id }) => id === selectedBranchId)
      : userBranches;

    try {
      dispatch(startLoadingDetailsItem);

      const { data } = await Axios.get(`${itemsURI}/${id}`, {
        headers: { Authorization: `Bearer ` + token },
        params: { extraBranchId: selectedBranchId },
      });

      /**
       * some items have system category id while the category is stored in the network_category table under different id.
       * if item comes with a category id that isnt found in any network category,
       * it need to be found by the system category id (const_category_id) instead
       */
      const isCategoryExists = categories.find(
        ({ id }) => id === data.category_id
      );

      if (!isCategoryExists) {
        const isConstCategoryExists = categories.find(
          ({ const_category_id }) => const_category_id === data.category_id
        );
        if (isConstCategoryExists) {
          data.category_id = isConstCategoryExists.id;
        }
      }

      //this value doesn't stored anywhere, it's just to visualize the prices ratio for the client
      data.network_markup = dynamicToFixed(
        getSafeDivision(data.network_price, data.price) * 100
      );

      //optional date which can be provided by the user when disconnecting sold dish
      data.sold_dish_code_delete_retro_date = null;

      const posCode = data.pos_code;
      data.posCodeLabel = posCode
        ? {
            id: posCode.id,
            label: `${posCode.name} - ${posCode.product_code}`,
            value: posCode.product_code,
          }
        : null;

      const filteredItemProps = [
        // GENERAL
        "id",
        "enable",
        "name",
        "part_number",
        "internal_catalog_number",
        "manufacturer_number",
        "supplier_id",
        "category_id",
        "taklit_id",

        // ORDER
        "by_scale",
        "order_able",
        "by_weight",
        "box_type",
        "box_scale",
        "package_items",
        "scale",
        "inventory",
        "notes",
        "order_limit",
        "expenses_type",

        // PRICE
        "price",
        "discount",
        "network_price",
        "network_markup",

        // PRODUCTS
        "product_tree_lost_percent",
        "sold_dish_code",
        "pos_code",
        "posCodeLabel",
        "unrelated_unified_products",
        "related_unified_products",
        "sell_price_vat",
        "sell_price",
        "allergens",

        // LOG
        "logs",

        // DEPOSIT AND TAXES
        "has_tax",
        "deposit",
        "tax",

        // INVENTORY
        "count_type",
        "expiration_date",
        "fix_inventory",
        "min_inventory",

        // CREDIT AND DEBIT
        "item_credit",
        "item_debit",
        "split_vat",

        // IMAGE
        "img",

        // BRANCHES
        "branchIds",

        // OTHER
        "updater",
      ];

      const copyItemPropsToExclude = [
        "id",
        "part_number",
        "internal_catalog_number",
        "manufacturer_number",
        "logs",
        "updater",
      ];

      const itemProps = duplicate
        ? filteredItemProps.filter(
            (prop) => !copyItemPropsToExclude.includes(prop)
          )
        : filteredItemProps;

      const payload = pick(data, itemProps);

      dispatch({
        type: EDIT_ITEM,
        payload: {
          ...payload,
          ...(duplicate && { id: NEW_ITEM_ID }),
          branchIds: selectedBranches.map(({ id, name }) => ({
            id,
            name,
            value: payload.branchIds.includes(id),
          })),
        },
      });
    } catch (e) {
      dispatch(error(e));
    } finally {
      dispatch(endLoadingDetailsItem);
    }
  };

// CREATE A NEW ITEM (Button)
export const createNewItem = () => (dispatch, getState) => {
  const branchIds = getState().branches.branches.map(({ id, name }) => ({
    id,
    name,
    value: true, // TRUE - all branches selected by default
  }));

  const lastCreatedItem = getState().goods.items.data.details.lastCreatedItem;

  const initialItem = {
    // GENERAL
    id: NEW_ITEM_ID,
    enable: true,
    name: null,
    part_number: null,
    internal_catalog_number: null,
    manufacturer_number: null,
    supplier_id: lastCreatedItem?.supplier_id ?? null,
    category_id: lastCreatedItem?.category ?? null,
    taklit_id: null,

    // ORDER
    by_scale: true,
    order_able: true,
    by_weight: false,
    box_type: "מארז",
    box_scale: "יחידות",
    package_items: 1,
    scale: null,
    inventory: 1,
    order_limit: null,
    expenses_type: 1,

    // PRICE
    price: null,
    discount: 0,
    network_price: 0,
    network_markup: 0,

    // PRODUCTS
    product_tree_lost_percent: 0,
    sold_dish_code: null,
    sold_dish_code_delete_retro_date: null,
    unified_product: null,
    allergens: [],

    // DEPOSIT AND TAXES
    has_tax: true,
    deposit: 0,
    tax: 0,

    // INVENTORY
    count_type: COUNT_TYPE_BOX_TYPE,
    expiration_date: null,

    // CREDIT AND DEBIT
    item_credit: null,
    item_debit: null,
    split_vat: false,

    // IMAGE
    img: null,

    // BRANCHES
    branchIds,
  };

  dispatch({ type: CREATE_NEW_ITEM, payload: initialItem });
};

// DELETE ITEM
export const toggleItem = (id) => async (dispatch, getState) => {
  const token = getState().auth.token;

  try {
    const { data } = await Axios.post(`${toggleURI}/${id}`, null, {
      headers: { Authorization: `Bearer ` + token },
    });

    dispatch({
      type: TOGGLE_ITEM,
      payload: data,
    });
  } catch (e) {
    dispatch(error(e));
  }
};

// CREATE ITEM
export const storeItem = (item) => async (dispatch, getState) => {
  const token = getState().auth.token;
  const formData = objectToFormData(item); // only reason to use formData is file upload

  try {
    dispatch(startLoading("שומר מוצר חדש..."));

    const { data } = await Axios.post(itemsURI, formData, {
      headers: { Authorization: `Bearer ` + token },
    });

    dispatch({ type: STORE_ITEM, payload: data });

    if (item.sold_dish_code !== "") dispatch(fetchPOSCodes());

    dispatch(setConfirm("נשמר בהצלחה!"));
  } catch (e) {
    dispatch(error(e));
  } finally {
    dispatch(stopLoading());
  }
};

// UPDATE ITEM
export const updateItem = (item) => async (dispatch, getState) => {
  const token = getState().auth.token;
  // Only reason to use formData is file upload
  const formData = objectToFormData(item);
  // Known laravel issue, while sending put requests with form data the requests is empty
  // The solution is to send POST request with _method PUT in form data.

  formData.append("_method", "PUT");

  try {
    dispatch(startLoading("מעדכן מוצר קיים..."));

    await Axios.post(`${itemsURI}/${item.id}`, formData, {
      headers: { Authorization: `Bearer ` + token },
    });

    dispatch({ type: CLOSE_FORM });

    dispatch(fetchItems());
    dispatch(fetchPOSCodes());

    const isSystemCategory = !item.category;
    if (isSystemCategory) dispatch(fetchCategories());

    dispatch(setConfirm("עודכן בהצלחה!"));
  } catch (e) {
    dispatch(error(e));
  } finally {
    dispatch(stopLoading());
  }
};

//  =========== ITEMS CRUD SECTION END ===========

// export const setDidCategoryChange = (bool) => (dispatch) => {
//   dispatch({ type: SET_DID_CATEGORY_CHANGE, payload: bool });
// };

export const isExists = (params) => async (dispatch, getState) => {
  const token = getState().auth.token;

  try {
    const { data } = await Axios.get(isExistsURI, {
      params,
      headers: { Authorization: `Bearer ` + token },
    });

    if (data.exists) {
      dispatch({
        type: SET_ITEM_ERROR,
        payload: [
          {
            part_number:
              "לא ניתן להקים את המוצר מאחר וקיים מוצר נוסף עם מק״ט זהה",
          },
        ],
      });
    } else {
      dispatch({
        type: SET_ITEM_ERROR,
        payload: [],
      });
    }
  } catch (e) {
    dispatch(error(e));
  }
};

export const swapIngredient = (productIds) => async (dispatch, getState) => {
  const token = getState().auth.token;
  const newIngredientId = getState().goods.items.data.details.data.id;
  const oldIngredientId = getState().goods.items.data.products.freeSearchId;

  try {
    dispatch(startLoadingSwapIngredient);
    dispatch(startLoading("שומר..."));

    await Axios.post(
      `${productsURI}/swap`,
      {
        newIngredientId,
        oldIngredientId,
        productIds,
      },
      {
        headers: { Authorization: `Bearer ` + token },
      }
    );

    dispatch(setSnackBar("חומר הגלם הוחלף בהצלחה"));
    const freeSearchId = getState().goods.items.data.products.freeSearchId;
    dispatch(fetchProducts(freeSearchId));
  } catch (e) {
    dispatch(error(e));
  } finally {
    dispatch(endLoadingSwapIngredient);
    dispatch(stopLoading());
  }
};

export const fetchPOSCodes = () => async (dispatch, getState) => {
  const token = getState().auth.token;

  try {
    dispatch(startLoadingPosCodes);

    const { data } = await Axios.get(posCodesURI, {
      headers: { Authorization: `Bearer ` + token },
    });

    dispatch({
      type: FETCH_POS_CODES,
      payload: data,
    });
  } catch (e) {
    dispatch(error(e));
  } finally {
    dispatch(endLoadingPosCodes);
  }
};

export const fetchUnifiedProducts = () => async (dispatch, getState) => {
  const token = getState().auth.token;

  try {
    dispatch(startLoadingUnifiedProducts);

    const { data } = await Axios.get(unifiedProductsURI, {
      headers: { Authorization: `Bearer ` + token },
    });

    dispatch({
      type: FETCH_UNIFIED_PRODUCTS,
      payload: data,
    });
  } catch (e) {
    dispatch(error(e));
  } finally {
    dispatch(endLoadingUnifiedProducts);
  }
};

// Get connected supplier catalog by item name
export const fetchCatalog = (name, supplier) => async (dispatch, getState) => {
  const token = getState().auth.token;

  try {
    dispatch(startLoadingCatalog);

    const { data } = await Axios.get(supplierCatalog, {
      params: {
        search_term: name,
        supplier_id: supplier?.value,
      },
      headers: { Authorization: `Bearer ` + token },
    });

    dispatch({ type: FETCH_CATALOG, payload: data });
  } catch (e) {
    dispatch(error(e));
  } finally {
    dispatch(endLoadingCatalog);
  }
};

export const fetchSuppliers = () => async (dispatch, getState) => {
  const token = getState().auth.token;

  try {
    dispatch(startLoadingSuppliers);
    const { data } = await Axios.get(suppliersURI, {
      headers: { Authorization: `Bearer ${token}` },
    });

    dispatch({
      type: SET_SUPPLIERS_OPTIONS,
      payload: data,
    });
  } catch (e) {
    dispatch(error(e));
  } finally {
    dispatch(endLoadingSuppliers);
  }
};

export const fetchSweeping = (payload) => async (dispatch, getState) => {
  const token = getState().auth.token;
  const as_branch_id = getState().goods.items.filter.branchId.id;
  const { setSweepingLoading, filteredData, checkedItems } = payload;

  try {
    setSweepingLoading(true);
    dispatch(startLoading("שומר..."));
    const res = await Axios.post(
      sweepingURI,
      { as_branch_id, filteredData, checkedItems },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    dispatch(fetchItems());

    dispatch(setConfirm("נשמר בהצלחה"));
  } catch (e) {
    dispatch(error(e));
  } finally {
    setSweepingLoading(false);
    dispatch(stopLoading());
  }
};

export const fetchAllergens = () => async (dispatch, getState) => {
  const token = getState().auth.token;

  try {
    dispatch(startLoadingAllergens);

    const { data } = await Axios.get(allergensURI, {
      headers: { Authorization: `Bearer ` + token },
    });

    dispatch({
      type: FETCH_ALLERGENS,
      payload: data,
    });
  } catch (e) {
    dispatch(error(e));
  } finally {
    dispatch(endLoadingAllergens);
  }
};

// Fill the freesearch options base on user input abd filters
export const fetchFreeSearch = (freeSearch) => async (dispatch, getState) => {
  const token = getState().auth.token;

  const {
    status,
    branchId: { id: branchId },
    categoryId,
    suppliersId,
    other,
  } = getState().goods.items.filter;

  const params = {
    freeSearch,
    ...(!!status.length
      ? {
          status: status.map((value) => value - 1),
        }
      : {
          status: [1],
        }),
    branchId,
    ...(!!categoryId.length && {
      categoryId,
    }),
    ...(!!suppliersId.length && {
      suppliersId,
    }),
    ...(!!other.length && {
      other,
    }),
  };

  try {
    dispatch(startLoadingFreeSearch);
    const { data } = await Axios.get(itemsURI, {
      params,
      headers: { Authorization: `Bearer ${token}` },
    });

    dispatch({ type: FETCH_ITEMS_FREE_SEARCH, payload: data });
  } catch (e) {
    dispatch(error(e));
  } finally {
    dispatch(endLoadingFreeSearch);
  }
};

export const fetchProducts = (id) => async (dispatch, getState) => {
  const token = getState().auth.token;
  const itemId = getState().goods.items.data.details.data.id;

  try {
    dispatch(startLoadingProducts);

    const { data } = await Axios.get(`${productsURI}/${id}`, {
      params: { id: itemId },
      headers: { Authorization: `Bearer ` + token },
    });

    dispatch({ type: SET_FREESEARCH_ID, payload: id });
    dispatch({ type: SET_PRODUCT, payload: data });
  } catch (e) {
    dispatch(error(e));
  } finally {
    dispatch(endLoadingProducts);
  }
};

// Download excel catalog button in master table
export const downloadCatalog = () => async (dispatch, getState) => {
  const token = getState().auth.token;

  const {
    status,
    branchId: { id: branchId },
    categoryId,
    suppliersId,
    other,
  } = getState().goods.items.filter;

  const params = {
    ...(!!status.length
      ? {
          status: status.map((value) => value - 1),
        }
      : {
          status: [1],
        }),
    branchId,
    ...(!!categoryId.length && {
      categoryId,
    }),
    ...(!!suppliersId.length && {
      suppliersId,
    }),
    ...(!!other.length && {
      other,
    }),
  };

  try {
    dispatch(startLoading("מוריד קטלוג"));
    const { data } = await Axios.get(`${itemsURI}/exportCatalog`, {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const downloadLink = document.createElement("a");
    downloadLink.href = process.env.REACT_APP_URL + "/" + data.file_path;
    downloadLink.target = "_blank";
    downloadLink.download = "קטלוג מוצרים.csv";
    downloadLink.click();
  } catch (e) {
    dispatch(error(e));
  } finally {
    dispatch(stopLoading());
  }
};

// HALF LEAGCY
// Upload excel modal
export const uploadMulti =
  (file, isByPartNumber) => async (dispatch, getState) => {
    const token = getState().auth.token;
    const branches = getState().goods.items.data.multiUploadBranches.data;

    try {
      dispatch(startLoading("מעדכן קטלוג"));

      let formData = new FormData();
      formData.append("file", file);
      formData.append("isByPartNumber", isByPartNumber);
      formData.append("branches", branches);

      const { data } = await Axios.post(uploadMultiURI, formData, {
        headers: { Authorization: `Bearer ` + token },
      });

      return data;
    } catch (e) {
      dispatch(error(e));
    } finally {
      dispatch(stopLoading());
    }
  };

export const printBarcode = (id) => async (dispatch, getState) => {
  const token = getState().auth.token;
  try {
    dispatch(startLoading("מוריד ברקודים"));
    const res = await Axios.get(`${itemsURI}/printBarcode/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    printView(res.data);
  } catch (e) {
    dispatch(error(e));
  } finally {
    dispatch(stopLoading());
  }
};

//PRICE GAPS CRUD OPERATIONS
export const fetchPriceGaps = () => async (dispatch, getState) => {
  const token = getState().auth.token;
  const { current_page, per_page } = getState().goods.price_gaps.pagination;

  const {
    branchId: { id: branchId },
    categoryId,
    suppliersId,
    other,
    dateRange,
  } = getState().goods.price_gaps.filter;

  const params = {
    branchId,
    ...(!!categoryId.length && {
      categoryId,
    }),
    ...(!!suppliersId.length && {
      suppliersId,
    }),
    ...(!!other.length && {
      other,
    }),
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    action_types: Object.values(ITEM_PRICE_LOG_TYPES),
    page: current_page + 1,
    per_page,
  };

  try {
    dispatch(startLoadingPriceGaps);

    const { data } = await Axios.get(logsURI, {
      params,
      headers: { Authorization: `Bearer ${token}` },
    });

    dispatch({ type: FETCH_PRICE_GAPS, payload: data });
  } catch (e) {
    dispatch(error(e));
  } finally {
    dispatch(endLoadingPriceGaps);
  }
};

export const revertPriceGap = (price_log_id) => async (dispatch, getState) => {
  const token = getState().auth.token;
  const priceGaps = getState().goods.price_gaps.data.master.data;

  try {
    dispatch(startLoadingPriceGaps);

    const { data } = await Axios.put(
      `${logsURI}/${price_log_id}/revert`,
      null,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    dispatch(fetchPriceGaps());
  } catch (e) {
    dispatch(error(e));
  } finally {
    dispatch(endLoadingPriceGaps);
  }
};
