// authReducer.js
import { LOGOUT_ADMIN } from "../actions/AdminLoginAction/types";
import { LOGIN, LOGOUT, FADE, FETCH_USER } from "../actions/loginAction/types";

const INITIAL_STATE = {
  token: null,
  user: {},
  email: null,
  password: null,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_USER:
      return { ...state, user: action.payload };
    case LOGIN:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        email: action.payload.email,
        password: action.payload.password,
      };
    case FADE:
      return { ...state, email: null, password: null };
    case LOGOUT_ADMIN:
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
export default authReducer;
