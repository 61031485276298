// ScheduleAction
import React from "react";
import { FaCalendarAlt } from "react-icons/fa";
import {
  SAVE_EVENT,
  FETCH_EVENTS,
  FETCH_EMPLOYEES,
  FETCH_ROLES,
  DELETE_EVENT,
  ADD_SHIFT,
  FETCH_SUBMISSIONS,
  ADD_SUBMISSIONS,
  REMOVE_SHIFT,
  ADD_NOTES_SCHEDULE,
  SAVE_PLANNED_HOURS,
  ADD_NOTE_TO_EVENT,
  REMOVE_NOT_PLACED,
  REMOVE_EVENT_MATRIX,
  SAVE_NOT_PLACED,
  PUBLISH_SCHEDULE,
  CHANGE_ROLE_SEQUENCE,
  EDIT_LAST_SUBMISSION,
  INIT_SCHEDULE,
  SET_SCHEDULE_TIP,
  REMOVE_SCHEDULE_TIP,
  ADD_PERMANENT_TO_ROLE,
  DUPLICATE_CELL,
  SAVE_SHIFT,
  UPDATE_SCHEDULE,
  EDIT_VACATION_STATUS,
  SET_IS_PINNED_SCHEDULE,
  SET_SPECIAL_REQUEST_COUNT,
  FETCH_ROLES_MAP,
  SET_HOLIDAYS,
  SET_EXISTING_ROLES,
  SET_NEW_EVENTS,
} from "./types";
import { isFunction, omit, isNull, mapKeys } from "lodash";
import { SET_ERROR } from "../errorAction/types";

import {
  createFormJson,
  createDayMatrix,
  createSubmissionsMatrix,
  createNotesMatrix,
  notPlacedMatrix,
} from "../helpers";

import axios from "axios";
import { LOGOUT } from "../loginAction/types";
import { SET_CONFIRM, SET_CONFIRM_BAR } from "../confirmAction/types";
import { dateReqFormat, fixDate, fixHour } from "../../../helpers";
import {
  DRAG_CELL_END,
  EDIT_MODE_SCHEDULE_CHANGE,
} from "../publicAction/types";
import { I18n } from "react-redux-i18n";
import { addDays } from "date-fns";
import { HIDE_DIALOG, SET_DIALOG } from "../DialogAction/types";
import { FaExclamationTriangle } from "react-icons/fa";
import { COLOR_WARNINGS } from "../../../const/colors";
import { ResponsiveWrapper } from "@nivo/core";
import { setSnackBar } from "../confirmAction";
import Axios from "axios";
import { BASE_URL } from "../../../config/auth";
import { startLoading, stopLoading } from "../../../redux/actions/loaderAction";

const noShiftsPopup = (dispatch, week_start) => {
  dispatch({
    type: SET_ERROR,
    error: `נראה כי לא שובצת למשמרות בסידור של ${fixDate(
      week_start,
      "DD/MM"
    )} - ${fixDate(addDays(new Date(week_start), 7), "DD/MM")}`,
    warning: true,
  });
};

export const publishSchedule =
  ({
    setIsLoadPublish,
    publishedTo = [],
    image = null,
    sendEmail = false,
    sendSMS = false,
    attachImage = false,
  }) =>
  async (dispatch, getState) => {
    try {
      const formData = await createFormJson(
        { publishedTo, image, sendEmail, attachImage, sendSMS },
        "PUT"
      );

      const { data } = await axios.post(
        `newShift/${getState().schedule.schedule.id}/publish`,
        formData,
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );

      // delete data.schedule.events;

      if (data?.error) {
        if (data.error.code === 3) {
          dispatch({
            type: SET_DIALOG,
            title: "סידור פורסם בהצלחה",
            text: (
              <div className="strong">
                <FaExclamationTriangle color={COLOR_WARNINGS} />
                <span style={{ margin: ".5rem" }}>נדרש לחדש חבילת SMS</span>
                <p>{` יתרה נוכחית: ${data.error.data.remain_count}`}</p>
                <p>{`כמות לשליחה: ${data.error.data.employee_to_send_count}`}</p>
                <p>{`לא נשלחו SMS בפרסום סידור זה. לצורך שליחת SMS יש לרכוש בנק SMS חדש , לפרטים נוספים ניתן לפנות לצוות רסטיגו.`}</p>
              </div>
            ),
            iconJSX: () =>
              FaCalendarAlt({ size: "7.2em", color: COLOR_WARNINGS }),
            showAbortButton: false,
            showConfirmButton: false,
          });
        } else {
          dispatch({
            type: SET_DIALOG,
            title: "סידור פורסם בהצלחה",
            text: (
              <div className="flex-column-center">
                {" "}
                <div className="strong">
                  <FaExclamationTriangle color={COLOR_WARNINGS} />
                  <span style={{ margin: ".5rem" }}>
                    נדרש עדכון למספרי הנייד של העובדים הבאים:
                  </span>
                </div>
                <ol className="fit-content u-right-text">
                  {data.error.data.map((x) => (
                    <li className="fit-content" key={x.id}>{`${x.firstName} ${
                      x.lastName
                    }, מס' נייד עדכני: ${
                      x.phoneNumber ? x.phoneNumber : "לא קיים"
                    }`}</li>
                  ))}
                </ol>
              </div>
            ),
            iconJSX: () =>
              FaCalendarAlt({ size: "7.2em", color: COLOR_WARNINGS }),
            showAbortButton: false,
            showConfirmButton: false,
          });
        }
      }

      dispatch({
        type: SET_CONFIRM,
        confirmTitle: "סידור פורסם בהצלחה!",
      });

      // dispatch({
      //   type: PUBLISH_SCHEDULE,
      //   payload: data.schedule,
      // });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 409) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
    }
    setIsLoadPublish(0);
  };

export const publishScheduleToSingleEmail =
  ({ image, sendEmail, setShowSingleEmailModal, setShowSendingMessage }) =>
  async (dispatch, getState) => {
    try {
      const formData = createFormJson({ image, sendEmail }, "PUT");
      const res = await axios.post(
        `newShift/${getState().schedule.schedule.id}/publishSingleEmail`,
        formData,
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );
      if (res.status === 200) {
        setShowSendingMessage(false);
        setShowSingleEmailModal(false);
        dispatch(setSnackBar("המייל נשלח בהצלחה", true));
      }
    } catch (err) {
      dispatch(setSnackBar("נא לפנות לשירות הלקוחות של רסטיגו", false));
    }
  };

export const fetchSchedule =
  (
    week_start = null,
    not_changeable = 0,
    for_submission = 0,
    setLoader,
    fetchSubmissions = false
  ) =>
  async (dispatch, getState) => {
    try {
      if (!(week_start && getState().branches.selected_branch.id)) return;
      week_start = dateReqFormat(week_start);
      const response = await axios.get("newShift/getShift", {
        params: {
          branch_id: getState().branches.selected_branch.id,
          week_start,
          not_changeable,
          for_submission,
        },
        headers: { Authorization: `Bearer ${getState().auth.token}` },
      });

      const { data } = response;
      let {
        events = [],
        shifts = [],
        tips,
        existing_shifts = [],
        existing_roles = [],
      } = data;
      const notPlaced = await events.filter((item) => !item.shift);
      const notPlaced_matrix = await notPlacedMatrix(notPlaced);
      const notPlaced_employees_events = await events
        .filter((item) => !item.shift && item.employee)
        .map((event) => {
          return {
            ...event,
            department: { id: 0, name: "לא משובצים" },
            shift: { id: 0, name: "לא משובצים" },
          };
        });
      const matrix = await createDayMatrix(events);
      events = await events.filter((item) => item.shift);
      if (fetchSubmissions) {
        await fetchSubmissions(data.id, null, null);
      }
      delete data.events;
      delete data.shifts;
      delete data.existing_shifts;
      delete data.existing_roles;
      dispatch({
        type: FETCH_EVENTS,
        payload: {
          events,
          data,
          matrix,
          shifts,
          notPlaced_matrix,
          tips,
          existing_shifts,
          existing_roles,
          notPlaced,
          notPlaced_employees_events,
        },
      });
      if (events.length > 0 && not_changeable) {
        // "not_changeable means privilege that it is user that can change the schedule"
        // if there is no events so we dont find matches
        const matchEvent = events.find(
          (event) => event.employee_id === getState().auth.user.id
        );
        if (!matchEvent) {
          noShiftsPopup(dispatch, week_start);
        }
      }

      dispatch({ type: EDIT_MODE_SCHEDULE_CHANGE, payload: false });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 400) {
          if (e.response.data.code === 1) {
            dispatch({
              type: SET_ERROR,
              errorTitle: for_submission
                ? "לא ניתן להגיש"
                : "תצוגת סידור נכשלה",
              error: `לא פורסם סידור עבודה לתאריכים ${fixDate(
                week_start
              )} - ${fixDate(
                addDays(new Date(week_start), 7)
              )}, יש לפנות למנהל המסעדה.`,
              warning: true,
            });
          } else noShiftsPopup(dispatch, week_start);
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
      dispatch({ type: INIT_SCHEDULE });
    }
    if (isFunction(setLoader)) setLoader(false);
  };

export const autoScheduleGenerator =
  (setIsLoadAutoAssign) => async (dispatch, getState) => {
    setIsLoadAutoAssign(true);
    try {
      const response = await axios.get(
        `newShift/${getState().schedule.schedule.id}/autoAssign`,
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );

      const { data } = response;

      let {
        events = [],
        shifts = [],
        tips,
        existing_shifts = [],
        existing_roles = [],
      } = data;
      const notPlaced = await events.filter((item) => !item.shift);
      const notPlaced_matrix = await notPlacedMatrix(notPlaced);

      const matrix = await createDayMatrix(events);
      events = await events.filter((item) => item.shift);

      delete data.events;
      delete data.existing_shifts;
      delete data.existing_roles;
      delete data.shifts;

      dispatch({
        type: FETCH_EVENTS,
        payload: {
          events,
          data,
          matrix,
          shifts,
          notPlaced_matrix,
          tips,
          existing_shifts,
          existing_roles,
          isAutoAssign: true,
        },
      });
      dispatch({ type: EDIT_MODE_SCHEDULE_CHANGE, payload: false });
      setIsLoadAutoAssign(false);
    } catch (e) {
      console.error(e);
      setIsLoadAutoAssign(false);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
    }
  };

export const autoScheduleApprove =
  (autoScheduleEvents = []) =>
  async (dispatch, getState) => {
    try {
      const response = await axios.post(
        `newShift/${getState().schedule.schedule.id}/autoAssignApprove`,
        autoScheduleEvents,
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );

      const { data } = response;
      let {
        events = [],
        shifts = [],
        tips,
        existing_shifts = [],
        existing_roles = [],
      } = data;
      const notPlaced = await events.filter((item) => !item.shift);
      const notPlaced_matrix = await notPlacedMatrix(notPlaced);

      const matrix = await createDayMatrix(events);
      events = await events.filter((item) => item.shift);

      delete data.events;
      delete data.existing_shifts;
      delete data.existing_roles;
      delete data.shifts;

      dispatch({
        type: FETCH_EVENTS,
        payload: {
          events,
          data,
          matrix,
          shifts,
          notPlaced_matrix,
          tips,
          existing_shifts,
          existing_roles,
          isAutoAssign: false,
        },
      });
      dispatch({ type: EDIT_MODE_SCHEDULE_CHANGE, payload: false });
    } catch (e) {
      console.error(e);
      dispatch({
        type: SET_ERROR,
        errorTitle: "ארעה שגיאה בשרת",
        error: "ניתן לפנות לתמיכה הטכנית של רסטיגו  ",
      });
    }
  };

export const saveTemplate = () => async (dispatch, getState) => {
  try {
    await axios.post(
      `newShift/${getState().schedule.schedule.id}/saveTemplate`,
      null,
      {
        headers: { Authorization: `Bearer ${getState().auth.token}` },
      }
    );
    dispatch({
      type: SET_CONFIRM,
      confirmTitle: "תבנית נשמרה בהצלחה",
      confirm: ``,
    });
    dispatch({ type: EDIT_MODE_SCHEDULE_CHANGE, payload: false });
  } catch (e) {
    console.error(e);
    if (e.response)
      if (e.response.status === 401) {
        dispatch({ type: LOGOUT });
      } else if (e.response.status === 500) {
        dispatch({
          type: SET_ERROR,
          errorTitle: "ארעה שגיאה בשרת",
          error: getState().publicValues.isMobile
            ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
            : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
        });
      }
  }
};

//Add notes to schedule
export const addNotesToSchedule =
  (schedule_id, notes = null, handleOpen) =>
  async (dispatch, getState) => {
    try {
      // if (!notes ) notes = null;

      // const formData = await createFormJson({ notes }, "PUT");

      const response = await axios.put(
        `newShift/${schedule_id}`,
        { notes },
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );
      dispatch({ type: ADD_NOTES_SCHEDULE, notes: response.data.notes });
      if (isFunction(handleOpen)) handleOpen(null);
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
      console.error(e);
    }
  };

//Add notes to schedule
// export const setIsPinnedSchedule =
//   (schedule_id, is_pinned = true) =>
//   async (dispatch, getState) => {
//     try {
//       // const formData = await createFormJson({ is_pinned }, "PUT");

//       const response = await axios.put(
//         `newShift/${schedule_id}`,
//         { is_pinned },
//         {
//           headers: { Authorization: `Bearer ${getState().auth.token}` },
//         }
//       );
//       dispatch({
//         type: SET_IS_PINNED_SCHEDULE,
//         is_pinned: response.data.is_pinned,
//       });
//     } catch (e) {
//       console.error(e);
//       if (e.response)
//         if (e.response.status === 401) {
//           dispatch({ type: LOGOUT });
//         } else if (e.response.status === 500) {
//           dispatch({
//             type: SET_ERROR,
//             errorTitle: "ארעה שגיאה בשרת",
//             error: getState().publicValues.isMobile
//               ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
//               : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
//           });
//         }
//       console.error(e);
//     }
//   };
//Add lock date to schedule
export const updateSchedule = (formValues) => async (dispatch, getState) => {
  try {
    // const formData = await createFormJson({ ...formValues }, "PUT");
    const response = await axios.put(
      `newShift/${getState().schedule.schedule.id}`,
      { ...formValues },
      {
        headers: { Authorization: `Bearer ${getState().auth.token}` },
      }
    );
    dispatch({ type: UPDATE_SCHEDULE, payload: response.data });
  } catch (e) {
    switch (e?.response?.status) {
      case 401:
        dispatch({ type: LOGOUT });
        break;

      default:
        dispatch({
          type: SET_ERROR,
          errorTitle: "ארעה שגיאה בשרת",
          error: getState().publicValues.isMobile
            ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
            : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
        });
        break;
    }
  }
};

//Add notes to event
export const addNotesToEvent =
  (event_id = null, notes = null, handleOpen) =>
  async (dispatch, getState) => {
    try {
      if (!event_id) throw new Error(" event id missing");

      if (!notes || notes === "") notes = null;

      const formData = await createFormJson({ notes }, "PUT");

      const response = await axios.post(
        `newSubShiftEvent/${event_id}`,
        formData,
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );
      dispatch({ type: ADD_NOTE_TO_EVENT, payload: response.data });
      if (isFunction(handleOpen)) handleOpen(null);
    } catch (e) {
      console.error(e);
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
    }
  };

export const editLastSubmissions =
  (last_submission) => async (dispatch, getState) => {
    try {
      // const formData = await createFormJson({ last_submission }, "PUT");

      const response = await axios.put(
        `newShift/${getState().schedule.schedule.id}`,
        { last_submission },
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );
      delete response.data.events;
      dispatch({
        type: SET_CONFIRM,
        confirmTitle: "תאריך אחרון להגשה נשמר בהצלחה",
        confirm: ``,
      });
      dispatch({ type: EDIT_MODE_SCHEDULE_CHANGE, payload: true });
      dispatch({
        type: EDIT_LAST_SUBMISSION,
        payload: response.data,
      });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
    }
  };

export const setSavedEvent = (event) => (dispatch) => {
  dispatch({ type: SAVE_EVENT, payload: event });
};

//Add manual hours to event
export const addHoursToEvent =
  (
    { event_id = null, start = null, end = null, standby = null },
    handleOpen = null,
    saveToReport = false,
    reload = null,
    updateTable = null
  ) =>
  async (dispatch, getState) => {
    try {
      if (!event_id) throw new Error(" event id missing");

      const formData = await createFormJson({ start, end, standby }, "PUT");

      const response = await axios.post(
        `newSubShiftEvent/${event_id}/manual`,
        formData,
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );

      if (isFunction(handleOpen)) handleOpen(null);
      if (!saveToReport) {
        if (response.data.shift) {
          dispatch({ type: SAVE_EVENT, payload: response.data });
          return true;
        } else {
          dispatch({ type: SAVE_NOT_PLACED, payload: response.data });
          return false;
        }
      } else {
        // dispatch({
        //   type: SET_CONFIRM,
        //   confirmTitle: "שעות בפועל עודכנו",
        //   confirm: ``,
        // });
        if (isFunction(reload)) reload();
        if (isFunction(updateTable)) updateTable(response.data, event_id);
        return true;
      }
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 400) {
          const { event, data, code } = e.response.data;

          if (code === 7) {
            dispatch({
              type: SET_ERROR,
              errorTitle: "לתשומת לבך",
              warning: true,
              error:
                event.shift && event.role
                  ? `ל${
                      event.employee.name
                    } יש שעות בפועל ליום זה בין השעות ${fixHour(
                      event.actual_end
                    )} - ${fixHour(event.actual_start)} במשמרת ${
                      event.shift.name
                    } כ${event.role.name}. לא ניתן לעדכן שעות.`
                  : `ל${
                      event.employee.name
                    } יש שעות בפועל ליום זה בין השעות ${fixHour(
                      event.actual_end
                    )} - ${fixHour(event.actual_start)}. לא ניתן לעדכן שעות.`,
            });
          } else if (code === 1) {
            dispatch({
              type: SET_ERROR,
              errorTitle: "לתשומת לבך",
              warning: true,
              error: `לא ניתן לעדכן שעות, חסרים הטפסים הבאים: ${data.map(
                (x) => ` ${I18n.t(x)}`
              )}.`,
            });
          } else if (code === 9) {
            const { max_hours } = e.response.data;
            dispatch({
              type: SET_ERROR,
              errorTitle: "לתשומת לבך",
              error: `ל${event.employee.name} יש שעות בפועל ליום זה, עדכון השעות יוביל לחריגה מ-${max_hours} ביום. לא ניתן לעדכן שעות.`,
            });
          } else if (code === 3) {
            dispatch({
              type: SET_ERROR,
              warning: true,
              error: `לא ניתן לעדכן שעות מאחר ונכנסו לזמן שבת ועובד זה משובץ ב-3 שבתות נוספות ברציפות.`,
              errorTitle: "לתשומת לבך",
            });
          } else if (code === 4) {
            dispatch({
              type: SET_ERROR,
              warning: true,
              errorTitle: "לתשומת לבך",
              error: ` לא ניתן לעדכן שעות ל${event.employee.name} מאחר ועברו רק ${data} שעות מאז המשמרת יום קודם לכן`,
            });
          } else if (code === 14) {
            dispatch({
              type: SET_ERROR,
              warning: true,
              errorTitle: "לתשומת לבך",
              error: ` לא ניתן לעדכן שעות ל${event.employee.name} מאחר וקיים שיבוץ במשמרת הבאה בעוד ${data} שעות`,
            });
          } else if (code === 15) {
            dispatch({
              type: SET_ERROR,
              warning: true,
              errorTitle: "לתשומת לבך",
              error: ` לא ניתן לעדכן שעות ל${event.employee.name} מאחר וקיים שיבוץ במשמרת יום קודם לכן עם שעות חופפות`,
            });
          } else if (code === 16) {
            dispatch({
              type: SET_ERROR,
              warning: true,
              errorTitle: "לתשומת לבך",
              error: ` לא ניתן לעדכן שעות ל${event.employee.name} מאחר וקיים שיבוץ במשמרת הבאה עם שעות חופפות`,
            });
          }
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
    }
  };

export const saveDailyNotes =
  (tempObj, handleOpen, updateTable) => async (dispatch, getState) => {
    if (!tempObj.id) throw new Error("event id missing");

    try {
      const response = await Axios.post(
        `newSubShiftEvent/${tempObj.id}/updateDailyNote`,
        { daily_notes: tempObj.daily_notes },
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );
      if (isFunction(handleOpen)) handleOpen(null);
      if (isFunction(updateTable)) updateTable(response.data, tempObj.id);
    } catch (err) {
      console.log(err);
    }
  };
export const lockDay = (date) => async (dispatch, getState) => {
  try {
    const response = await axios.post(
      `newShift/${getState().schedule.schedule.id}/lockDay`,
      { date },
      {
        headers: { Authorization: `Bearer ${getState().auth.token}` },
      }
    );
    dispatch({ type: UPDATE_SCHEDULE, payload: response.data });
  } catch (e) {
    if (e.response)
      if (e.response.status === 401) {
        dispatch({ type: LOGOUT });
      } else if (e.response.status === 500) {
        dispatch({
          type: SET_ERROR,
          errorTitle: "ארעה שגיאה בשרת",
          error: getState().publicValues.isMobile
            ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
            : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
        });
      } else if (e.response.status === 409) {
        return e.response.data;
      }
  }
};

export const obligateEmployeesToWork =
  (date, shift_ids) => async (dispatch, getState) => {
    try {
      const response = await axios.post(
        `newShift/${getState().schedule.schedule.id}/requireWorkDay`,
        { date, shift_ids },
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );
      dispatch({ type: UPDATE_SCHEDULE, payload: response.data });
    } catch (e) {
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        } else if (e.response.status === 409) {
          return e.response.data;
        } else {
          return e.response;
        }
    }
  };

//add New Assign From Report
export const addNewAssignFromReport =
  ({
    date = null,
    start = null,
    end = null,
    employee_id,
    handleOpen = null,
    saveToReport = false,
    updateTable = null,
  }) =>
  async (dispatch, getState) => {
    try {
      const response = await axios.post(
        `newSubShiftEvent/newAssign`,
        {
          date,
          start,
          end,
          employee_id,
          branch_id: getState().branches.selected_branch.id,
        },
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );

      // console.log(response.data)
      if (!saveToReport) {
        if (response.data.shift) {
          dispatch({ type: SAVE_EVENT, payload: response.data });
        } else {
          dispatch({ type: SAVE_NOT_PLACED, payload: response.data });
        }
      } else {
        dispatch({
          type: SET_CONFIRM,
          confirmTitle: "שעות בפועל עודכנו",
          confirm: ``,
        });
        if (isFunction(updateTable)) updateTable(response.data);
      }

      if (isFunction(handleOpen)) handleOpen(null);
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 400) {
          const { event, data, code } = e.response.data;
          if (code === 3) {
            dispatch({
              type: SET_ERROR,
              warning: true,
              error: `לא ניתן לעדכן שעות מאחר ונכנסו לזמן שבת ועובד זה משובץ ב-3 שבתות נוספות ברציפות.`,
              errorTitle: "לתשומת לבך",
            });
          } else if (code === 1) {
            dispatch({
              type: SET_ERROR,
              errorTitle: "לתשומת לבך",
              warning: true,
              error: `לא ניתן לעדכן שעות, חסרים הטפסים הבאים: ${data.map(
                (x) => ` ${I18n.t(x)}`
              )}.`,
            });
          } else if (code === 7) {
            dispatch({
              type: SET_ERROR,
              errorTitle: "לתשומת לבך",
              warning: true,
              error:
                event.shift && event.role
                  ? `ל${
                      event.employee.name
                    } יש שעות בפועל ליום זה בין השעות ${fixHour(
                      event.actual_end
                    )} - ${fixHour(event.actual_start)} במשמרת ${
                      event.shift.name
                    } כ${event.role.name}. לא ניתן לעדכן שעות.`
                  : `ל${
                      event.employee.name
                    } יש שעות בפועל ליום זה בין השעות ${fixHour(
                      event.actual_end
                    )} - ${fixHour(event.actual_start)}. לא ניתן לעדכן שעות.`,
            });
          } else if (code === 9) {
            const { max_hours } = e.response.data;
            dispatch({
              type: SET_ERROR,
              errorTitle: "לתשומת לבך",
              error: `ל${event.employee.name} יש שעות בפועל ליום זה, עדכון השעות יוביל לחריגה מ-${max_hours} ביום. לא ניתן לעדכן שעות.`,
            });
          } else if (code === 4) {
            dispatch({
              type: SET_ERROR,
              warning: true,
              errorTitle: "לתשומת לבך",
              error: ` לא ניתן לעדכן שעות ל${event.employee.name} מאחר ועברו רק ${data} שעות מאז המשמרת יום קודם לכן`,
            });
          } else if (code === 14) {
            dispatch({
              type: SET_ERROR,
              warning: true,
              errorTitle: "לתשומת לבך",
              error: ` לא ניתן לעדכן שעות ל${event.employee.name} מאחר וקיים שיבוץ במשמרת הבאה בעוד ${data} שעות`,
            });
          } else if (code === 15) {
            dispatch({
              type: SET_ERROR,
              warning: true,
              errorTitle: "לתשומת לבך",
              error: ` לא ניתן לעדכן שעות ל${event.employee.name} מאחר וקיים שיבוץ במשמרת יום קודם לכן עם שעות חופפות`,
            });
          } else if (code === 16) {
            dispatch({
              type: SET_ERROR,
              warning: true,
              errorTitle: "לתשומת לבך",
              error: ` לא ניתן לעדכן שעות ל${event.employee.name} מאחר וקיים שיבוץ במשמרת הבאה עם שעות חופפות`,
            });
          }
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
    }
  };

export const getHolidays = () => async (dispatch, getState) => {
  try {
    const token = getState().auth.token;
    const currentYear = new Date().getFullYear(); // get the current year
    const startDate = new Date(`${currentYear}-01-01 00:00:00`); // construct start date using current year
    const endDate = new Date(`${currentYear}-12-31 23:59:59`); // construct end date using current year

    const dateRange = [startDate, endDate]; // initialize empty array

    const { data } = await Axios.get(`${BASE_URL}/api/holidays`, {
      params: {
        dateRange: dateRange,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    dispatch({ type: SET_HOLIDAYS, payload: data });
  } catch (err) {
    console.log(err);
  }
};

export const moveEvent =
  (origin, destination) => async (dispatch, getState) => {
    try {
      if (!(origin.id && destination.id)) {
        throw new Error("origin or destination are missing");
      }
      const formData = await createFormJson(
        { destination_event: destination.id },
        "PUT"
      );

      const response = await axios.post(
        `newSubShiftEvent/${origin.id}/move`,
        formData,
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );

      if (response.data.origin) {
        dispatch({
          type: DELETE_EVENT,
          payload: {
            event: response.data.origin,
            old_event: origin,
          },
        });
      } else {
        dispatch({
          type: REMOVE_NOT_PLACED,
          payload: {
            event: origin,
          },
        });
      }

      dispatch({ type: SAVE_EVENT, payload: response.data.destination });
      dispatch({ type: DRAG_CELL_END });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 400) {
          const { event, data, code } = e.response.data;
          if (code === 3) {
            dispatch({
              type: SET_ERROR,
              warning: true,
              error: `לא ניתן להעביר עובד מאחר והעברה זו גוררת כניסה לשבת ועובד זה משובץ ב-3 שבתות נוספות ברציפות.`,
              errorTitle: "לתשומת לבך",
            });
          } else if (code === 7) {
            dispatch({
              type: SET_ERROR,
              errorTitle: "לתשומת לבך",
              warning: true,
              error:
                event.shift && event.role
                  ? `ל${
                      event.employee.name
                    } יש שעות בפועל ליום זה בין השעות ${fixHour(
                      event.actual_end
                    )} - ${fixHour(event.actual_start)} במשמרת ${
                      event.shift.name
                    } כ${event.role.name}. לא ניתן להעברה.`
                  : `ל${
                      event.employee.name
                    } יש שעות בפועל ליום זה בין השעות ${fixHour(
                      event.actual_end
                    )} - ${fixHour(event.actual_start)}. לא ניתן להעברה.`,
            });
          } else if (code === 10) {
            dispatch({
              type: SET_ERROR,
              errorTitle: "לתשומת לבך",
              warning: true,
              error:
                event.shift && event.role
                  ? `ל${
                      event.employee.name
                    } יש שעות תכנון ליום זה בין השעות ${fixHour(
                      event.hours.planned.end
                    )} - ${fixHour(event.hours.planned.start)} במשמרת ${
                      event.shift.name
                    } כ${event.role.name}. לא ניתן להעברה.`
                  : `ל${
                      event.employee.name
                    } יש שעות תכנון ליום זה בין השעות ${fixHour(
                      event.hours.planned.end
                    )} - ${fixHour(
                      event.hours.planned.start
                    )}. לא ניתן להעברה.`,
            });
          } else if (code === 1) {
            dispatch({
              type: SET_ERROR,
              errorTitle: "לתשומת לבך",
              warning: true,
              error: `לא ניתן לעדכן שעות, חסרים הטפסים הבאים: ${data.map(
                (x) => ` ${I18n.t(x)}`
              )}.`,
            });
          } else if (code === 9) {
            const { max_hours } = e.response.data;
            dispatch({
              type: SET_ERROR,
              errorTitle: "לתשומת לבך",
              error: `ל${event.employee.name} יש שעות בפועל ליום זה, העברה זו תוביל לחריגה מ-${max_hours} ביום. לא ניתן להעביר.`,
            });
          } else if (code === 4) {
            dispatch({
              type: SET_ERROR,
              warning: true,
              errorTitle: "לתשומת לבך",
              error: ` לא ניתן להעביר שעות ל${event.employee.name} מאחר ועברו רק ${data} שעות מאז המשמרת יום קודם לכן`,
            });
          } else if (code === 14) {
            dispatch({
              type: SET_ERROR,
              warning: true,
              errorTitle: "לתשומת לבך",
              error: ` לא ניתן להעביר שעות ל${event.employee.name} מאחר וקיים שיבוץ במשמרת הבאה בעוד ${data} שעות`,
            });
          } else if (code === 15) {
            dispatch({
              type: SET_ERROR,
              warning: true,
              errorTitle: "לתשומת לבך",
              error: ` לא ניתן להעביר שעות ל${event.employee.name} מאחר וקיים שיבוץ במשמרת יום קודם לכן עם שעות חופפות`,
            });
          } else if (code === 16) {
            dispatch({
              type: SET_ERROR,
              warning: true,
              errorTitle: "לתשומת לבך",
              error: ` לא ניתן להעביר שעות ל${event.employee.name} מאחר וקיים שיבוץ במשמרת הבאה עם שעות חופפות`,
            });
          }
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
      dispatch({ type: DRAG_CELL_END });
    }
  };

export const switchEvents =
  (origin, destination) => async (dispatch, getState) => {
    try {
      if (!(origin.id && destination.id)) {
        throw new Error("origin or destination are missing");
      }
      const formData = await createFormJson(
        { destination_event: destination.id },
        "PUT"
      );

      const response = await axios.post(
        `newSubShiftEvent/${origin.id}/switch`,
        formData,
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );

      if (isNull(destination.employee)) {
        dispatch({
          type: DELETE_EVENT,
          payload: {
            event: response.data.origin,
            old_event: origin,
          },
        });
      } else dispatch({ type: SAVE_EVENT, payload: response.data.origin });

      dispatch({ type: SAVE_EVENT, payload: response.data.destination });
      dispatch({ type: DRAG_CELL_END });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 400) {
          const { event, data, code } = e.response.data;
          if (code === 3) {
            dispatch({
              type: SET_ERROR,
              warning: true,
              error: `לא ניתן להחליף מאחר והחלפה זו גוררת כניסה לזמן שבת ואחד מהעובדים משובץ ב-3 שבתות נוספות ברציפות.`,
              errorTitle: "לתשומת לבך",
            });
          } else if (code === 7) {
            dispatch({
              type: SET_ERROR,
              errorTitle: "לתשומת לבך",
              warning: true,
              error:
                event.shift && event.role
                  ? `ל${
                      event.employee.name
                    } יש שעות בפועל ליום זה בין השעות ${fixHour(
                      event.actual_end
                    )} - ${fixHour(event.actual_start)} במשמרת ${
                      event.shift.name
                    } כ${event.role.name}. לא ניתן להחלפה.`
                  : `ל${
                      event.employee.name
                    } יש שעות בפועל ליום זה בין השעות ${fixHour(
                      event.actual_end
                    )} - ${fixHour(event.actual_start)}. לא ניתן להחלפה.`,
            });
          } else if (code === 10) {
            dispatch({
              type: SET_ERROR,
              errorTitle: "לתשומת לבך",
              warning: true,
              error:
                event.shift && event.role
                  ? `ל${
                      event.employee.name
                    } יש שעות תכנון ליום זה בין השעות ${fixHour(
                      event.hours.planned.end
                    )} - ${fixHour(event.hours.planned.start)} במשמרת ${
                      event.shift.name
                    } כ${event.role.name}. לא ניתן להחלפה.`
                  : `ל${
                      event.employee.name
                    } יש שעות תכנון ליום זה בין השעות ${fixHour(
                      event.hours.planned.end
                    )} - ${fixHour(
                      event.hours.planned.start
                    )}. לא ניתן להחלפה.`,
            });
          } else if (code === 1) {
            dispatch({
              type: SET_ERROR,
              errorTitle: "לתשומת לבך",
              warning: true,
              error: `לא ניתן לעדכן שעות, חסרים הטפסים הבאים: ${data.map(
                (x) => ` ${I18n.t(x)}`
              )}.`,
            });
          } else if (code === 9) {
            const { max_hours } = e.response.data;
            dispatch({
              type: SET_ERROR,
              errorTitle: "לתשומת לבך",
              error: `ל${event.employee.name} יש שעות בפועל ליום זה, החלפה זו תוביל לחריגה מ-${max_hours} ביום. לא ניתן להחליף תאים.`,
            });
          } else if (code === 4) {
            dispatch({
              type: SET_ERROR,
              warning: true,
              errorTitle: "לתשומת לבך",
              error: ` לא ניתן להחליף תאים עם ${event.employee.name} מאחר ועברו רק ${data} שעות מאז המשמרת יום קודם לכן`,
            });
          } else if (code === 14) {
            dispatch({
              type: SET_ERROR,
              warning: true,
              errorTitle: "לתשומת לבך",
              error: ` לא ניתן להחליף תאים עם ${event.employee.name} מאחר וקיים שיבוץ במשמרת הבאה בעוד ${data} שעות`,
            });
          } else if (code === 15) {
            dispatch({
              type: SET_ERROR,
              warning: true,
              errorTitle: "לתשומת לבך",
              error: ` לא ניתן להחליף תאים עם ${event.employee.name} מאחר וקיים שיבוץ במשמרת יום קודם לכן עם שעות חופפות`,
            });
          } else if (code === 16) {
            dispatch({
              type: SET_ERROR,
              warning: true,
              errorTitle: "לתשומת לבך",
              error: ` לא ניתן להחליף תאים עם ${event.employee.name} מאחר וקיים שיבוץ במשמרת הבאה עם שעות חופפות`,
            });
          }
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
    }
    dispatch({ type: DRAG_CELL_END });
  };

//Add employee to event
export const addEmployeeToEvent =
  (employee = null, event = null, handleOpen, switchDialog, forced = false) =>
  async (dispatch, getState) => {
    try {
      if (!(employee && event))
        throw new Error("employee id or event id are missing");

      const formData = await createFormJson(
        { employee_id: employee.id, forced, permanent: false },
        "PUT"
      );

      const response = await axios.post(
        `newSubShiftEvent/${event.id}`,
        formData,
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );
      if (event.employee) {
        dispatch({ type: REMOVE_EVENT_MATRIX, payload: event });
      }
      dispatch({ type: SAVE_EVENT, payload: response.data });
      if (isFunction(handleOpen)) handleOpen(null);
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        } else if (e.response.status === 400) {
          if (e.response.data.code) {
            switchDialog({
              code: e.response.data.code,
              forms: e.response.data.data ? e.response.data.data : null,
              same_hours_event: e.response.data.event
                ? e.response.data.event
                : null,
              event,
              employee,
              max_hours: e.response.data.max_hours
                ? e.response.data.max_hours
                : null,
            });
          }
        }
      console.error(e);
    }
  };

//Delete event
export const deleteEvent =
  ({ event = null, handleOpen = null, isReport = false, onSuccess }) =>
  async (dispatch, getState) => {
    try {
      if (!event.id) throw new Error("Event id missing");

      const response = await axios.post(
        `newSubShiftEvent/${event.id}/remove`,
        null,
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );

      if (isReport) {
        if (isFunction(onSuccess)) {
          onSuccess();
        }
        dispatch({
          type: SET_CONFIRM,
          confirmTitle: "שיבוץ הוסר מהסידור בהצלחה",
          confirm: ``,
        });
        return;
      }
      const { origin } = response.data;
      if (origin && event.shift) {
        dispatch({
          type: DELETE_EVENT,
          payload: { old_event: event, event: origin },
        });
      } else {
        dispatch({
          type: REMOVE_NOT_PLACED,
          payload: {
            event,
          },
        });
      }
      if (isFunction(handleOpen)) handleOpen(null);
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
    }
  };

//Save planned Hours edit mode
export const savePlannedHours =
  (
    { event_id = null, start = null, end = null, forced = false },
    handleOpen = null,
    saveToReport = false,
    reload = null
  ) =>
  async (dispatch, getState) => {
    try {
      if (!event_id) throw new Error("Event id  missing");
      const formData = await createFormJson({ start, end, forced }, "PUT");

      const response = await axios.post(
        `newSubShiftEvent/${event_id}/planned`,
        formData,
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );
      if (!saveToReport) {
        dispatch({ type: SAVE_PLANNED_HOURS, payload: response.data });
        dispatch({ type: EDIT_MODE_SCHEDULE_CHANGE, payload: true });
      } else {
        dispatch({
          type: SET_CONFIRM,
          confirmTitle: "שעות תכנון עודכנו",
          confirm: ``,
        });
        if (isFunction(reload)) reload();
      }
      if (isFunction(handleOpen)) handleOpen(null);
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 400) {
          const { event, code, data } = e.response.data;
          if (code === 7) {
            dispatch({
              type: SET_ERROR,
              errorTitle: "לתשומת לבך",
              error: `ל${event.employee.name} יש שעות בפועל מ ${fixHour(
                event.actual_end
              )} - ${fixHour(event.actual_start)} במשמרת ${event.shift.name} כ${
                event.role.name
              }. לא ניתן לעדכן שעות.`,
            });
          } else if (code === 10) {
            dispatch({
              type: SET_DIALOG,
              title: "לתשומת לבך",
              text: `${event.employee.name} כבר שובץ היום בין השעות ${fixHour(
                event.hours.planned.end
              )} - ${fixHour(event.hours.planned.start)} במשמרת ${
                event.shift.name
              } כ${event.role.name}. האם לעדכן שעות תכנון בכל מקרה? `,
              onConfirm: () =>
                dispatch(
                  savePlannedHours(
                    { event_id, start, end, forced: true },
                    handleOpen,
                    saveToReport,
                    reload
                  )
                ),
            });
          } else if (code === 1) {
            dispatch({
              type: SET_ERROR,
              errorTitle: "לתשומת לבך",
              warning: true,
              error: `לא ניתן לעדכן שעות, חסרים הטפסים הבאים: ${data.map(
                (x) => ` ${I18n.t(x)}`
              )}.`,
            });
          } else if (code === 3) {
            dispatch({
              type: SET_ERROR,
              warning: true,
              error: `לא ניתן לעדכן שעות מאחר ונכנסו לזמן שבת ועובד זה משובץ ב-3 שבתות נוספות ברציפות.`,
              errorTitle: "לתשומת לבך",
            });
          } else if (code === 4) {
            dispatch({
              type: SET_ERROR,
              warning: true,
              errorTitle: "לתשומת לבך",
              error: ` לא ניתן לעדכן שעות ל${event.employee.name} מאחר ועברו רק ${data} שעות מאז סיום המשמרת יום קודם לכן`,
            });
          } else if (code === 14) {
            dispatch({
              type: SET_ERROR,
              warning: true,
              errorTitle: "לתשומת לבך",
              error: ` לא ניתן לעדכן שעות ל${event.employee.name} מאחר וקיים שיבוץ במשמרת הבאה בעוד ${data} שעות`,
            });
          } else if (code === 15) {
            dispatch({
              type: SET_ERROR,
              warning: true,
              errorTitle: "לתשומת לבך",
              error: ` לא ניתן לעדכן שעות ל${event.employee.name} מאחר וקיים שיבוץ במשמרת יום קודם לכן עם שעות חופפות`,
            });
          } else if (code === 16) {
            dispatch({
              type: SET_ERROR,
              warning: true,
              errorTitle: "לתשומת לבך",
              error: ` לא ניתן לעדכן שעות ל${event.employee.name} מאחר וקיים שיבוץ במשמרת הבאה עם שעות חופפות`,
            });
          } else if (code === 9) {
            const { max_hours } = e.response.data;
            dispatch({
              type: SET_ERROR,
              errorTitle: "לתשומת לבך",
              error: `${event.employee.name} שובץ להיום, עדכון השעות יוביל לחריגה מ-${max_hours} ביום. לא ניתן לעדכן שעות.`,
            });
          }
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
      console.error(e);
    }
  };

export const editCell =
  (
    { event_id = null, start = null, end = null },
    employee_id,
    permanent,
    handleOpen
  ) =>
  async (dispatch, getState) => {
    try {
      if (!event_id) throw new Error("Event id  missing");

      const formData = await createFormJson(
        { start, end, permanent, employee_id },
        "PUT"
      );

      const response = await axios.post(
        `newSubShiftEvent/${event_id}`,
        formData,
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );
      dispatch({ type: SAVE_PLANNED_HOURS, payload: response.data });
      dispatch({ type: EDIT_MODE_SCHEDULE_CHANGE, payload: true });

      if (isFunction(handleOpen)) handleOpen(null);
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
    }
  };

export const duplicateCell =
  ({ event_id = null, numberValue = null }, handleOpen, stopLoading) =>
  async (dispatch, getState) => {
    try {
      if (isFunction(handleOpen)) handleOpen(null);
      if (!event_id) throw new Error("Event id  missing");

      const response = await axios.post(
        `newShift/${getState().schedule.schedule.id}/duplicateCell`,
        { event_id, numberValue },
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );
      const { data } = response;
      let { events, shifts } = data;
      const matrix = await createDayMatrix(events);
      events = await events.filter((item) => item.shift);
      dispatch({ type: DUPLICATE_CELL, payload: { events, matrix, shifts } });

      dispatch({ type: EDIT_MODE_SCHEDULE_CHANGE, payload: true });
      if (isFunction(stopLoading)) {
        stopLoading();
      }
    } catch (e) {
      console.error(e);
      if (isFunction(stopLoading)) {
        stopLoading();
      }
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
    }
  };

export const addPermanentToRole =
  ({ events_ids = null, employee_id }) =>
  async (dispatch, getState) => {
    try {
      const formData = await createFormJson({ events_ids, employee_id }, "PUT");

      const response = await axios.post(
        `newSubShiftEvent/assignPermanent`,
        formData,
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );

      const { data } = response;
      let { events } = data;
      const matrix = await createDayMatrix(events);
      events = await events.filter((item) => item.shift);

      dispatch({ type: ADD_PERMANENT_TO_ROLE, payload: { events, matrix } });
      dispatch({ type: EDIT_MODE_SCHEDULE_CHANGE, payload: true });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
      console.error(e);
    }
  };

//fetch branch employees
export const fetchEmployees = (branch_ids) => async (dispatch, getState) => {
  try {
    const response = await axios.get("employees", {
      params: {
        branch_id: branch_ids ?? getState().branches.selected_branch.id,
        filterEnabled: true,
      },
      headers: { Authorization: `Bearer ${getState().auth.token}` },
    });
    let data = Object.values(response.data);
    data = data.filter((x) => x.department_id);

    dispatch({ type: FETCH_EMPLOYEES, payload: data });
  } catch (e) {
    console.error(e);
    if (e.response)
      if (e.response.status === 401) {
        dispatch({ type: LOGOUT });
      } else if (e.response.status === 500) {
        dispatch({
          type: SET_ERROR,
          errorTitle: "ארעה שגיאה בשרת",
          error: getState().publicValues.isMobile
            ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
            : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
        });
      }
    console.error(e);
  }
};
export const fetchEmployeesBySendingId =
  (branchId) => async (dispatch, getState) => {
    try {
      const response = await axios.get("employees", {
        params: {
          branch_id: branchId,
          filterEnabled: true,
        },
        headers: { Authorization: `Bearer ${getState().auth.token}` },
      });
      let data = Object.values(response.data);
      data = data.filter((x) => x.department_id);

      dispatch({ type: FETCH_EMPLOYEES, payload: data });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
      console.error(e);
    }
  };

//fetch schedule submissions
export const fetchSubmissions =
  (schedule_id, employee_id = null, setLoader = null) =>
  async (dispatch, getState) => {
    if (!schedule_id) return;
    try {
      const response = await axios.get("newSubmission/getSubmissions", {
        params: {
          schedule_id,
          employee_id,
          filterEnabled: true,
        },
        headers: { Authorization: `Bearer ${getState().auth.token}` },
      });

      const {
        submissions,
        notes,
        shifts_and_days,
        roles_and_days,
        special_requests,
      } = response.data;
      const matrix = await createSubmissionsMatrix(
        submissions,
        getState().branches.selected_branch.shift_request_type
      );
      const notes_matrix = await createNotesMatrix(notes);

      dispatch({
        type: FETCH_SUBMISSIONS,
        payload: {
          submissions,
          notes,
          notes_matrix,
          matrix,
          shifts_and_days,
          roles_and_days,
          special_requests,
        },
      });
      if (isFunction(setLoader)) setLoader(false);
    } catch (e) {
      console.error(e);
      if (isFunction(setLoader)) setLoader(false);

      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 400) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "לתשומת לבך",
            error: `לפני הגשת משמרות עלייך למלא את הטפסים הבאים :${e.response.data.data.map(
              (x) => ` ${I18n.t(x)}`
            )}. קישור למילוי הטפסים מופיע בעמוד הראשי`,
          });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
      console.error(e);
    }
  };

//add new submissions
export const addSubmissions =
  ({ submission_events, notes_events, schedule_id, employee_ids, onSuccess }) =>
  async (dispatch, getState) => {
    try {
      const response = await axios.post(
        "newSubmission/manager",
        {
          submission_events,
          schedule_id,
          notes: notes_events,
          employee_ids,
        },
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );

      const { week_start, week_end } = getState().publicValues.scheduleWeek;
      dispatch({
        type: SET_CONFIRM,
        confirmTitle: "בקשות הוגשו בהצלחה",
        confirm: `לסידור בתאריכים: ${fixDate(week_start)} - ${fixDate(
          week_end
        )}`,
      });

      const { submissions, notes, shifts_and_days, roles_and_days } =
        response.data;
      const matrix = await createSubmissionsMatrix(
        submissions,
        getState().branches.selected_branch.shift_request_type
      );
      const notes_matrix = await createNotesMatrix(notes);
      dispatch({ type: SET_CONFIRM, confirmTitle: "הוגש בהצלחה", confirm: "" });

      dispatch({
        type: FETCH_SUBMISSIONS,
        payload: {
          submissions,
          matrix,
          notes,
          notes_matrix,
          shifts_and_days,
          roles_and_days,
        },
      });
      if (isFunction(onSuccess)) {
        onSuccess();
      }
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 409) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאת תאימות",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
            warning: true,
          });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
      console.error(e);
    }
  };

export const editAllVacationStatus =
  (type, notes_ids = []) =>
  async (dispatch, getState) => {
    try {
      if (!notes_ids.length) {
        return;
      }
      const formData = createFormJson({ type, notes_ids }, "PUT");
      const { data } = await axios.post(
        `newSubmission/editAllStatus`,
        formData,
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );

      let { notes } = getState().submissions;
      notes = Object.values({ ...notes, ...mapKeys(data, "id") });
      const notes_matrix = await createNotesMatrix(data);

      dispatch({
        type: EDIT_VACATION_STATUS,
        payload: { notes: data, notes_matrix },
      });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
      console.error(e);
    }
  };

export const editVacationStatus =
  ({ id, status }) =>
  async (dispatch, getState) => {
    try {
      const formData = createFormJson({ status }, "PUT");
      const { data } = await axios.post(
        `newSubmission/${id}/vacationStatus`,
        formData,
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );

      let { notes } = getState().submissions;
      notes = Object.values({ ...notes, [data.id]: data });
      const notes_matrix = await createNotesMatrix(notes);

      dispatch({
        type: EDIT_VACATION_STATUS,
        payload: { notes, notes_matrix },
      });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
      console.error(e);
    }
  };

export const getPendingCount = () => async (dispatch, getState) => {
  try {
    const branch_id = getState().branches.selected_branch.id;
    if (!branch_id) return;
    const { data } = await axios.get(`NewSubmissionRequest/pendingCount`, {
      params: { branch_id },
      headers: { Authorization: `Bearer ${getState().auth.token}` },
    });
    dispatch({
      type: SET_SPECIAL_REQUEST_COUNT,
      payload: data,
    });
  } catch (e) {
    console.error(e);
  }
};

export const setPendingCount = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: SET_SPECIAL_REQUEST_COUNT,
      payload: getState().submissions.special_requests_count - 1,
    });
  } catch (e) {
    console.error(e);
  }
};

//add new submissions
export const addSubmissionsEmployee =
  (formValues, notesValues = null, employee_id, schedule_id) =>
  async (dispatch, getState) => {
    try {
      // console.log(formValues);
      const response = await axios.post(
        "newSubmission",
        {
          submission_events: formValues,
          notes: notesValues,
          schedule_id,
          employee_id,
        },
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );

      // console.log(response.data);
      // const data = response.data.map((sub) => {
      //   const week_day = new Date(sub.date).getDay();
      //   return { ...sub, week_day };
      // });
      const { submissions, notes } = response.data;
      // const oldSubmissions = Object.values(getState().submissions.submissions);
      const matrix = await createSubmissionsMatrix(
        submissions,
        getState().branches.selected_branch.shift_request_type
      );
      const notes_matrix = await createNotesMatrix(notes);
      dispatch({ type: SET_CONFIRM, confirmTitle: "הוגש בהצלחה", confirm: "" });

      dispatch({
        type: ADD_SUBMISSIONS,
        payload: { submissions, matrix, notes_matrix },
      });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
    }
  };

//add new submissions
export const addSubmissionsEmployeeNew =
  (submission_events, notesValues = null, employee_id, schedule_id) =>
  async (dispatch, getState) => {
    try {
      const response = await axios.post(
        "newSubmission/employees",
        {
          submission_events,
          notes: notesValues,
          schedule_id,
          employee_id,
        },
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );

      const { submissions, notes, shifts_and_days, roles_and_days } =
        response.data;
      const matrix = await createSubmissionsMatrix(
        submissions,
        getState().branches.selected_branch.shift_request_type
      );
      const notes_matrix = await createNotesMatrix(notes);
      dispatch({ type: SET_CONFIRM, confirmTitle: "הוגש בהצלחה", confirm: "" });

      dispatch({
        type: FETCH_SUBMISSIONS,
        payload: {
          submissions,
          matrix,
          notes,
          notes_matrix,
          shifts_and_days,
          roles_and_days,
        },
      });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 409) {
          if (e?.response?.data?.last_submission_validation_fail) {
            dispatch({
              type: SET_ERROR,
              errorTitle: "תאריך הגשת משמרות עבר",
              warning: true,
            });
          } else {
            dispatch({
              type: SET_ERROR,
              errorTitle: "ארעה שגיאת תאימות",
              error: getState().publicValues.isMobile
                ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
                : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
              warning: true,
            });
          }
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
    }
  };

//fetch branch roles
export const fetchRoles = () => async (dispatch, getState) => {
  try {
    const rolesMap = {};
    const response = await axios.get("roles", {
      params: {
        branch_id: getState().branches.selected_branch.id,
        filterEnabled: true,
      },
      headers: { Authorization: `Bearer ${getState().auth.token}` },
    });

    for (const role of response.data) {
      rolesMap[role.department_id] = role;
    }
    dispatch({ type: FETCH_ROLES_MAP, payload: rolesMap });
    dispatch({ type: FETCH_ROLES, payload: response.data });
  } catch (e) {
    console.error(e);
    if (e.response)
      if (e.response.status === 401) {
        dispatch({ type: LOGOUT });
      } else if (e.response.status === 500) {
        dispatch({
          type: SET_ERROR,
          errorTitle: "ארעה שגיאה בשרת",
          error: getState().publicValues.isMobile
            ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
            : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
        });
      }
  }
};

//change department sequence
export const changeDepartmentSequence =
  (shift_id, employee_types_sequence) => async (dispatch, getState) => {
    try {
      const formData = await createFormJson({ employee_types_sequence }, "PUT");

      const response = await axios.post(`newSubShift/${shift_id}`, formData, {
        headers: { Authorization: `Bearer ${getState().auth.token}` },
      });
      // console.log(response.data);
      // dispatch({ type: EDIT_MODE_SCHEDULE_CHANGE, payload: true });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
    }
  };

//change role sequence
export const changeRoleSequence =
  (events_to_switch, sequence_to_switch, setRoleLoader = () => {}) =>
  async (dispatch, getState) => {
    try {
      const response = await axios.post(
        `newShift/rolesequence`,
        { events_to_switch, sequence_to_switch },
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );

      dispatch({ type: EDIT_MODE_SCHEDULE_CHANGE, payload: true });
      dispatch({ type: CHANGE_ROLE_SEQUENCE, payload: response.data });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
    }
    setRoleLoader();
  };

//change department sequence
export const changeShiftSequence =
  (shift_id, employee_types_sequence) => async (dispatch, getState) => {
    try {
      // const formData = await createFormJson({ employee_types_sequence }, "PUT");
      // const response = await axios.post(
      //   `newSubShift/${shift_id}`,
      //   formData,
      //   {
      //     params: {
      //       token: getState().auth.token,
      //     },
      //   }
      // );
      // console.log(response.data)
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
    }
  };

//add shift edit mode
export const addShift =
  (formValues, handleOpen) => async (dispatch, getState) => {
    const { shift } = formValues;
    if (!formValues.employee_id) {
      delete formValues.employee_id;
    }
    try {
      const response = await axios.post(
        "newSubShiftEvent/storeWeek",
        {
          ...formValues,
          branch_id: String(getState().branches.selected_branch.id),
        },
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );

      if (response.data.error_no === 409) {
        dispatch({
          type: SET_ERROR,
          errorTitle: "שגיאת מערכת",
          error: `משמרת בשם "${shift.name}" כבר קיימת בסידור זה`,
        });
      } else {
        let events = getState().schedule.events;

        response.data.original.forEach((event) => {
          events[event.id] = event;
        });

        const matrix = await createDayMatrix([
          ...Object.values(events),
          ...Object.values(getState().schedule.notPlaced),
        ]);

        dispatch({ type: ADD_SHIFT, payload: { events, matrix } });
        dispatch({ type: EDIT_MODE_SCHEDULE_CHANGE, payload: true });
      }

      if (isFunction(handleOpen)) handleOpen(false);
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
      console.error(e);
    }
  };

//edit eole hours
export const editRoleHours =
  (eventIds, hours, stopLoading) => async (dispatch, getState) => {
    try {
      const response = await axios.post(
        "newSubShiftEvent/editRoleHours",
        {
          eventIds,
          hours,
          branch_id: getState().branches.selected_branch.id,
          new_shift_id: getState().schedule.schedule.id,
        },
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );

      if (response.data.error_no === 409) {
        dispatch({
          type: SET_ERROR,
          errorTitle: "ארעה שגיאה בשרת",
          error: "ניתן לפנות לתמיכה הטכנית של רסטיגו  ",
        });
      } else {
        const { data } = response;
        const currentEvents = getState().schedule.events;
        // Convert the events object into an array if it's not already
        const newEventsArray = Array.isArray(data) ? data : Object.values(data);

        // Create a new object to store the updated state
        const updatedEvents = { ...currentEvents };

        // Iterate over the new events and update the corresponding event in the state
        newEventsArray.forEach((newEvent) => {
          if (updatedEvents[newEvent.id]) {
            updatedEvents[newEvent.id] = {
              ...updatedEvents[newEvent.id],
              planned_end: newEvent.planned_end,
              planned_start: newEvent.planned_start,
              hours: {
                ...updatedEvents[newEvent.id].hours,
                planned: {
                  start: newEvent.planned_start,
                  end: newEvent.planned_end,
                },
              },
            };
          }
        });
        dispatch({
          type: SET_NEW_EVENTS,
          payload: { updatedEvents },
        });
        dispatch({ type: EDIT_MODE_SCHEDULE_CHANGE, payload: true });
      }
      if (isFunction(stopLoading)) stopLoading();
      // if (isFunction(handleOpen)) handleOpen(false);
    } catch (e) {
      console.error(e);
      stopLoading();
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
      console.error(e);
    }
  };

//duplicate row
export const duplicateShiftRole =
  (eventIds, setRoleLoader = null, numberValue) =>
  async (dispatch, getState) => {
    try {
      dispatch(startLoading("טוען..."));

      const response = await axios.post(
        `newSubShiftEvent/duplicateWeek`,
        {
          eventIds,
          branch_id: getState().branches.selected_branch.id,
          new_shift_id: getState().schedule.schedule.id,
          amount: numberValue,
        },
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );

      let events = getState().schedule.events;

      response.data.original.forEach((event) => {
        events[event.id] = event;
      });

      const matrix = await createDayMatrix([
        ...Object.values(events),
        ...Object.values(getState().schedule.notPlaced),
      ]);
      // events = await events.filter((item) => item.shift);
      dispatch({ type: ADD_SHIFT, payload: { events, matrix } });
      dispatch({ type: EDIT_MODE_SCHEDULE_CHANGE, payload: true });
      if (isFunction(setRoleLoader)) {
        setRoleLoader(null);
      }
      dispatch(stopLoading());
    } catch (e) {
      console.error(e);
      if (isFunction(setRoleLoader)) {
        setRoleLoader(null);
      }
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
      console.error(e);
    }
  };

export const eraseDateEvents =
  ({ date, setLoader = null }) =>
  async (dispatch, getState) => {
    try {
      const response = await axios.post(
        `newShift/${getState().schedule.schedule.id}/eraseDateEvents`,
        {
          date,
        },
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );

      const { data } = response;
      let events = data;
      events = Object.values({
        ...getState().schedule.events,
        ...mapKeys(data, "id"),
      });
      const matrix = await createDayMatrix(events);
      dispatch({
        type: REMOVE_SHIFT,
        payload: { events: mapKeys(events, "id"), matrix },
      });
      dispatch({ type: EDIT_MODE_SCHEDULE_CHANGE, payload: true });
    } catch (e) {
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
      console.error(e);
    }
    if (isFunction(setLoader)) {
      setLoader();
    }
  };

//remove shift edit mode
export const removeShift =
  ({ department, shift, role, week_start }) =>
  async (dispatch, getState) => {
    try {
      const formData = await createFormJson(
        {
          department,
          shift,
          role,
          week_start,
          branch_id: getState().branches.selected_branch.id,
        },
        "DELETE"
      );

      const response = await axios.post(
        `newSubShiftEvent/deleteWeek`,
        formData,
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );

      let events = getState().schedule.events;

      await response.data.forEach((event) => {
        events = omit(events, event.id);
      });

      const matrix = await createDayMatrix([
        ...Object.values(events),
        ...Object.values(getState().schedule.notPlaced),
      ]);

      dispatch({ type: REMOVE_SHIFT, payload: { events, matrix } });
      dispatch({ type: EDIT_MODE_SCHEDULE_CHANGE, payload: true });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        }
      if (e.response.status === 400) {
        if (e.response.data.code === 1)
          dispatch({
            type: SET_ERROR,
            errorTitle: `שגיאת מערכת`,
            error: `התפקיד ${role.name} במשמרת ${shift.name} משבוע זה מכיל שעות בפועל ולכן לא ניתן למחיקה.`,
          });
        else {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
      } else if (e.response.status === 500) {
        dispatch({
          type: SET_ERROR,
          errorTitle: "ארעה שגיאה בשרת",
          error: "ניתן לפנות לתמיכה הטכנית של רסטיגו  ",
        });
      }
      console.error(e);
    }
  };

export const editShiftName = (shift_id, name) => async (dispatch, getState) => {
  try {
    const formData = await createFormJson(
      {
        name,
      },
      "PUT"
    );
    const response = await axios.post(`newSubShift/${shift_id}`, formData, {
      headers: { Authorization: `Bearer ${getState().auth.token}` },
    });
    dispatch({ type: SAVE_SHIFT, payload: response.data });
  } catch (e) {
    console.error(e);
    if (e.response)
      if (e.response.status === 401) {
        dispatch({ type: LOGOUT });
      } else if (e.response.status === 400) {
        dispatch({
          type: SET_ERROR,
          errorTitle: "שגיאת כפילות",
          warning: true,
          error: "כבר קיימת משמרת בשם זה",
        });
      } else if (e.response.status === 500) {
        dispatch({
          type: SET_ERROR,
          errorTitle: "ארעה שגיאה בשרת",
          error: getState().publicValues.isMobile
            ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
            : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
        });
      }
    console.error(e);
  }
};

//fetch schedule Tips
export const fetchTips = () => async (dispatch, getState) => {
  try {
    const response = await axios.get(
      `scheduleTips/${getState().schedule.schedule.id}`,
      {
        headers: { Authorization: `Bearer ${getState().auth.token}` },
      }
    );

    dispatch({ type: SAVE_SHIFT, payload: response.data });
  } catch (e) {
    console.error(e);
    if (e.response)
      if (e.response.status === 401) {
        dispatch({ type: LOGOUT });
      } else if (e.response.status === 500) {
        dispatch({
          type: SET_ERROR,
          errorTitle: "ארעה שגיאה בשרת",
          error: getState().publicValues.isMobile
            ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
            : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
        });
      }
    console.error(e);
  }
};

//add schedule tips
export const addTips =
  (
    {
      schedule_shift_id,
      num_of_shifts = 1,
      date,
      cash,
      credit,
      profit_from_employee = null,
      extra = null,
      employee_id = 0,
      schedule_id,
    },
    setAll,
    setLoading,
    onClose = () => {},
    setErrorEmployees
  ) =>
  async (dispatch, getState) => {
    try {
      console.log(extra);
      if (!schedule_id) return;
      setLoading(true);
      const response = await axios.post(
        "scheduleTips",
        {
          schedule_id,
          schedule_shift_id,
          num_of_shifts,
          date,
          cash,
          credit,
          profit_from_employee,
          extra,
          employee_id,
        },
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );
      let events = response.data.events;
      events.forEach((event) => {
        event.hourlyTip = Number(event.hourlyTip).toFixed(2);
      });
      if (response?.data?.employee_profit) {
        response.data.profitFromEmployee = response?.data?.employee_profit ?? 0;
      }
      setAll({ events, ...response.data });
      setLoading(false);
      dispatch({ type: SET_SCHEDULE_TIP, payload: response.data });
    } catch (e) {
      console.error(e);
      setLoading(false);
      setAll({});
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 404) {
          if (e.response.data.code === 1) {
            dispatch({
              type: SET_ERROR,
              errorTitle: `שגיאת מערכת`,
              error: `לא ניתן לחשב טיפים למשמרת זו מאחר ולא משובצים עובדים עם שעות בפועל `,
            });
          } else if (e.response.data.code === 20) {
            dispatch({
              type: SET_ERROR,
              errorTitle: `חריגה ממינימום טיפ שעתי`,
              error: `לא ניתן לעדכן את חישוב הטיפים למשמרת זו מאחר והטיפ לשעה נמוך מהסכום המוגבל במינימום טיפ לשעה, יש לחשב את הטיפים מחדש `,
              warning: true,
            });
          }
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "הטיפים חושבו",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "לצורך צפיה בטיפים נדרש לפתוח את מסך חישוב טיפים מחדש",
          });
          onClose();
        } else if (e.response.status === 409) {
          setAll({ errorEmployees: e.response.data });
        } else if (e.response.status === 403) {
          dispatch({
            type: SET_ERROR,
            errorTitle: `חישוב טיפ כבר התבצע`,
            error: `${e.response.data.error}`,
            warning: true,
          });
        }
    }
  };

//edit schedule tips
export const editTips =
  (
    {
      schedule_shift_id,
      date,
      cash,
      credit,
      profit_from_employee,
      tipId,
      extra,
      employee_id = 0,
      schedule_id,
    },
    setAll,
    setLoading = () => {}
  ) =>
  async (dispatch, getState) => {
    try {
      if (!schedule_id) return;
      setLoading(true);
      const formData = await createFormJson(
        {
          schedule_id,
          schedule_shift_id,
          date,
          cash,
          credit,
          profit_from_employee,
          extra,
          employee_id,
        },
        "PUT"
      );

      const response = await axios.post(`scheduleTips/${tipId}`, formData, {
        headers: { Authorization: `Bearer ${getState().auth.token}` },
      });
      let events = response.data.events;

      events.forEach((event) => {
        event.hourlyTip = Number(event.hourlyTip).toFixed(2);
      });
      if (response?.data?.employee_profit) {
        response.data.profitFromEmployee = response?.data?.employee_profit ?? 0;
      }
      setAll({ events, ...response.data });
      setLoading(false);

      dispatch({ type: SET_SCHEDULE_TIP, payload: response.data });
    } catch (e) {
      console.error(e);
      setLoading(false);
      setAll({});
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 404) {
          if (e.response.data.code === 20) {
            dispatch({
              type: SET_ERROR,
              errorTitle: `חריגה ממינימום טיפ שעתי`,
              error: `לא ניתן לעדכן את חישוב הטיפים למשמרת זו מאחר והטיפ לשעה נמוך מהסכום המוגבל במינימום טיפ לשעה, יש לחשב את הטיפים מחדש `,
              warning: true,
            });
          }
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        } else if (e.response.status === 409) {
          setAll({ errorEmployees: e.response.data });
        }
    }
  };

//delete schedule tips
export const deleteTips =
  ({ tipId }, setAll) =>
  async (dispatch, getState) => {
    try {
      const formData = await createFormJson(null, "DELETE");

      await axios.post(`scheduleTips/${tipId}`, formData, {
        headers: { Authorization: `Bearer ${getState().auth.token}` },
      });
      setAll({});
      dispatch({ type: REMOVE_SCHEDULE_TIP, tipId });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
      console.error(e);
    }
  };

export const transferEmployee =
  ({ id, working_date, employee }, source_branch, destination_branch) =>
  async (dispatch, getState) => {
    if (!employee) throw Error("request must contain employee");
    try {
      const response = await axios.post(
        `newSubShiftEvent/${id}/transfer`,
        {
          source_branch_id: source_branch.id,
          destination_branch_id: destination_branch.id,
          destination_branch_name: destination_branch.name,
          working_date,
          employee_id: employee.id,
          user_info_id: getState().auth.user.id,
        },
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );

      dispatch({ type: SAVE_EVENT, payload: response.data });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 404) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "העברה לא קיימת במערכת",
            error: "ניתן לפנות לתמיכה הטכנית של רסטיגו  ",
          });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
      console.error(e);
    }
  };

export const deleteTransfer =
  ({ id }) =>
  async (dispatch, getState) => {
    try {
      const deleteMethod = new FormData();
      deleteMethod.append("_method", "DELETE");

      const response = await axios.post(
        `newSubShiftEvent/${id}/transfer`,
        deleteMethod,
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );
      dispatch({ type: SAVE_EVENT, payload: response.data });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 404) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "העברה לא קיימת במערכת",
            error: "ניתן לפנות לתמיכה הטכנית של רסטיגו  ",
          });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
      console.error(e);
    }
  };

export const addBonusToEmployee =
  ({ event_id, employee_id, amount, note, bonusType }) =>
  async (dispatch, getState) => {
    try {
      const response = await axios.post(
        `newSubShiftEvent/${event_id}/bonus`,
        { employee_id, amount, note, bonus_type: bonusType },
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );
      dispatch({ type: SAVE_EVENT, payload: response.data });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
      console.error(e);
    }
  };

export const editBonusToEmployee =
  ({ bonus_id, amount, note, bonusType }) =>
  async (dispatch, getState) => {
    try {
      const formData = await createFormJson(
        {
          amount,
          note,
          bonus_type: bonusType,
        },
        "PUT"
      );
      const response = await axios.post(`bonusEvent/${bonus_id}`, formData, {
        headers: { Authorization: `Bearer ${getState().auth.token}` },
      });
      dispatch({ type: SAVE_EVENT, payload: response.data });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
      console.error(e);
    }
  };

export const deleteBonusToEmployee =
  (bonus_id) => async (dispatch, getState) => {
    try {
      const formData = await createFormJson(null, "DELETE");
      const response = await axios.post(`bonusEvent/${bonus_id}`, formData, {
        headers: { Authorization: `Bearer ${getState().auth.token}` },
      });
      dispatch({ type: SAVE_EVENT, payload: response.data });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
      console.error(e);
    }
  };

export const addTipsToExtraDepartment =
  (
    {
      schedule_shift_id,
      num_of_shifts = 1,
      date,
      cash,
      credit,
      profit_from_employee = null,
      extra = null,
      employee_id = 0,
      schedule_id,
    },
    setAll,
    setLoading,
    onClose = () => {},
    setErrorEmployees
  ) =>
  async (dispatch, getState) => {
    try {
      if (!schedule_id) return;
      setLoading(true);
      const response = await axios.post(
        "scheduleTips/extraDepartmentTip",
        {
          schedule_id,
          schedule_shift_id,
          num_of_shifts,
          date,
          cash,
          credit,
          profit_from_employee,
          extra,
          employee_id,
        },
        {
          headers: { Authorization: `Bearer ${getState().auth.token}` },
        }
      );
      let events = response.data.events;
      events.forEach((event) => {
        event.hourlyTip = Number(event.hourlyTip).toFixed(2);
      });
      setAll({ events, ...response.data });
      setLoading(false);
      dispatch({ type: SET_SCHEDULE_TIP, payload: response.data });
    } catch (e) {
      console.error(e);
      setLoading(false);
      setAll({});
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 404) {
          if (e.response.data.code === 1) {
            dispatch({
              type: SET_ERROR,
              errorTitle: `שגיאת מערכת`,
              error: `לא ניתן לחשב טיפים למשמרת זו מאחר ולא משובצים עובדים עם שעות בפועל `,
            });
          } else if (e.response.data.code === 20) {
            dispatch({
              type: SET_ERROR,
              errorTitle: `חריגה ממינימום טיפ שעתי`,
              error: `לא ניתן לעדכן את חישוב הטיפים למשמרת זו מאחר והטיפ לשעה נמוך מהסכום המוגבל במינימום טיפ לשעה, יש לחשב את הטיפים מחדש `,
              warning: true,
            });
          }
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "הטיפים חושבו",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "לצורך צפיה בטיפים נדרש לפתוח את מסך חישוב טיפים מחדש",
          });
          onClose();
        } else if (e.response.status === 409) {
          setAll({ errorEmployees: e.response.data });
        }
    }
  };

export const getAllShiftRolesByShift =
  ({ shiftIds, day }) =>
  async (dispatch, getState) => {
    let queryString = "";

    if (Object.keys(shiftIds).length > 0) {
      queryString = Object.keys(shiftIds)
        .map((key) => `id[]=${shiftIds[key]}`)
        .join("&");
    }

    const url = `newShift/getShiftRoles?day=${day}${
      queryString ? `&${queryString}` : ""
    }`;

    try {
      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${getState().auth.token}` },
      });

      const { data } = response;

      dispatch({ type: SET_EXISTING_ROLES, payload: data.roles });
    } catch (e) {
      console.error(e);
      if (e.response)
        if (e.response.status === 401) {
          dispatch({ type: LOGOUT });
        } else if (e.response.status === 500) {
          dispatch({
            type: SET_ERROR,
            errorTitle: "ארעה שגיאה בשרת",
            error: getState().publicValues.isMobile
              ? "אנא נסה למחוק ולהתקין מחדש את האפליקציה, אם התקלה חוזרת ניתן לפנות לתמיכה הטכנית של רסטיגו"
              : "אנא נסה לרענן את הדף. אם התקלה ממשיכה ניתן לפנות לתמיכה הטכנית של רסטיגו",
          });
        }
    }
  };

export const changeDayHoursSchedule =
  ({
    plannedStart,
    plannedEnd,
    checkedRolesOptions,
    checkedShiftsOptions,
    day,
  }) =>
  async (dispatch, getState) => {
    const token = getState().auth.token;

    try {
      dispatch(startLoading("מעדכן שעות"));
      const res = await Axios.post(
        `newShift/changeDayHoursSchedule`,
        {
          plannedStart,
          plannedEnd,
          checkedRolesOptions,
          checkedShiftsOptions,
          day,
          shiftId: getState().schedule.schedule.id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (res.status === 200) {
        const { data } = res;
        let { events, shifts } = data;
        const matrix = createDayMatrix(events);
        events = await events.filter((item) => item.shift);
        dispatch({ type: ADD_SHIFT, payload: { events, matrix, shifts } });
        dispatch({ type: EDIT_MODE_SCHEDULE_CHANGE, payload: true });
      }
    } catch (err) {
      dispatch(setSnackBar("נא לפנות לשירות הלקוחות של רסטיגו", false));
    } finally {
      dispatch(stopLoading());
    }
  };
