import {
  Avatar,
  Collapse,
  keyframes,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import { createElement, useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import { ListItem as MuiListItem } from "@mui/material";
import { BsChevronLeft } from "react-icons/bs";
const StyledListItem = styled(MuiListItem)({
  ":hover": {
    "& .MuiListItemIcon-root": {
      "& .MuiAvatar-root": {
        "@keyframes shake": {
          "0%": {
            transform: "translate(1px, 1px) rotate(0deg)",
          },
          "10%": {
            transform: "translate(-1px, -2px) rotate(-1deg)",
          },
          "20%": {
            transform: "translate(-3px, 0px) rotate(1deg)",
          },
          "30%": {
            transform: "translate(3px, 2px) rotate(0deg)",
          },
          "40%": {
            transform: "translate(1px, -1px) rotate(1deg)",
          },
          "50%": {
            transform: "translate(-1px, 2px) rotate(-1deg)",
          },
          "60%": {
            transform: "translate(-3px, 1px) rotate(0deg)",
          },
          "70%": {
            transform: "translate(3px, 1px) rotate(-1deg)",
          },
          "80%": {
            transform: "translate(-1px, -1px) rotate(1deg)",
          },
          "90%": {
            transform: "translate(1px, 2px) rotate(0deg)",
          },
          "100%": {
            transform: "translate(1px, -2px) rotate(1deg)",
          },
        },
        animation: `shake 1s 1 ease`,
      },
    },
  },
  maxHeight: 50,
  color: "white",
});

const MenuIconOpen = styled("div")({
  animation: `${keyframes`from {
      transform: rotate(0deg)
    }
    to {
      transform: rotate(-90deg)
    }`}
    0.5s 1 ease`,
  animationFillMode: "forwards",
});
const MenuIconClose = styled("div")({
  animation: `${keyframes`from {
      transform: rotate(-90deg)
    }
    to {
      transform: rotate(0deg)
    }`}
     0.5s 1 ease`,
  animationFillMode: "forwards",
});

const ListItem = ({
  route: { path = "", icon, name, children, element },
  open,
  setTitle,
  onClick,
}) => {
  const match = useMatch({ path, caseSensitive: true, end: !children });
  useEffect(() => {
    if (match && path && setTitle) {
      setTitle(name ?? "");
    }
  }, [setTitle, children, match, element, path, name]);

  const [collapse, setCollapse] = useState(false);

  return (
    <>
      <StyledListItem {...{ ...(!!element && { component: Link, to: path }) }}>
        <ListItemButton
          selected={!!(match && path)}
          sx={{
            backgroundColor: "inherit",
            "&.Mui-selected": {
              backgroundColor: "inherit",
              "&:hover": {
                backgroundColor: "inherit",
              },
            },
          }}
          onClick={(e) => {
            onClick && onClick(e);
            if (!children) {
              return;
            }
            setCollapse((prev) => !prev);
          }}
        >
          <ListItemIcon>
            <Avatar
              sx={{
                backgroundColor: "#2B2B2B",
                color: !!(match && path) ? "white" : "#adadad",
              }}
            >
              {!!icon && createElement(icon)}
            </Avatar>
          </ListItemIcon>
          <ListItemText
            sx={{
              ...(!open && { display: "none" }),
            }}
            primaryTypographyProps={{
              color: !!(match && path) ? "white" : "#adadad",
              ...(!!(match && path) && { fontWeight: "900" }),
            }}
          >
            {name}
          </ListItemText>
          {!!children?.length &&
            (collapse ? (
              <MenuIconOpen>
                <BsChevronLeft />
              </MenuIconOpen>
            ) : (
              <MenuIconClose>
                <BsChevronLeft />
              </MenuIconClose>
            ))}
        </ListItemButton>
      </StyledListItem>
      {!!children?.length && (
        <Collapse in={collapse}>
          <List sx={{ backgroundColor: "#2B2B2B" }}>
            {children.map((route, key) => (
              <ListItem
                {...{ open, key }}
                onClick={route.onClick}
                route={{
                  ...route,
                  path: `${path}${route.path ? `/${route.path}` : ""}`,
                }}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default ListItem;
