import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchEmployees,
  fetchRoles,
  getPendingCount,
} from "../redux/actions/scheduleAction";
import {
  checkConnection,
  fetchUserPrivileges,
} from "../redux/actions/loginAction";
import {
  getNetworkCategories,
  getNetworkSuppliers,
} from "../redux/actions/networkAction";
import { getBranches, setBranch } from "../redux/actions/branchesAction";
import { matchRoutes, Outlet, useLocation } from "react-router-dom";
import {
  AppBar as MuiAppBar,
  Drawer as MuiDrawer,
  Box,
  CssBaseline,
  Toolbar,
  IconButton,
  Divider,
  Avatar,
  List,
  Link,
  Menu,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { FaChevronCircleLeft, FaChevronCircleRight } from "react-icons/fa";
import restigo_logo from "../images/restigo_logo.svg";
import restigo_logo_white from "../images/restigo_logo_white.svg";
import Zendesk, { ZendeskAPI } from "react-zendesk";
import { useScreenSize } from "../Hooks";
import { AutoComplete } from "../components/Forms/inputs/AutoComplete";
import { isFunction } from "lodash";
import { FiHelpCircle } from "react-icons/fi";
import { setError } from "../redux/actions/errorAction";
import ListItem from "../components/layouts/ListItem";
import ConnectedUsers from "../components/layouts/ConnectedUsers";

const DRAWER_WIDTH = 240;
const DRAWER_WIDTH_CLOSE_SM = 15;
const DRAWER_WIDTH_CLOSE_MD = 10;
const openedMixin = (theme) => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overFlowX: "hidden",
});
const closedMixin = (theme) => ({
  width: `calc(${theme.spacing(DRAWER_WIDTH_CLOSE_MD)} + 1px)`,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overFlowX: "hidden",
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(DRAWER_WIDTH_CLOSE_SM)} + 1px)`,
  },
});
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    p: 3,
    backgroundColor: "#F5F5F5",
    display: "flex",
    flexDirection: "column",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open
      ? {
          width: `calc(100% - ${DRAWER_WIDTH}px)`,
          [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${DRAWER_WIDTH}px)`,
          },
        }
      : {
          width: `calc(100% - (${theme.spacing(DRAWER_WIDTH_CLOSE_MD)} + 1px))`,
          [theme.breakpoints.up("sm")]: {
            width: `calc(100% - (${theme.spacing(
              DRAWER_WIDTH_CLOSE_SM
            )} + 1px))`,
          },
        }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "white",
  color: "black",
  // zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(100% - (${theme.spacing(DRAWER_WIDTH_CLOSE_MD)} + 1px))`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(100% - (${theme.spacing(DRAWER_WIDTH_CLOSE_SM)} + 1px))`,
  },
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${DRAWER_WIDTH}px)`,
    },
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open
    ? {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      }
    : {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      }),
}));

const MinDrawer = ({
  user,
  selected_branch,
  routes,
  baseRoute,
  logout,
  setBranch,
  branches,
  setError,
  connected_users,
}) => {
  const { direction } = useTheme();
  const { isMobile } = useScreenSize();
  const location = useLocation();
  const [isLagacy, setIsLagacy] = useState(true);
  const [open, setOpen] = useState(!isMobile);
  const [anchorEl, setAnchorEl] = useState(null);
  const [title, setTitle] = useState("");
  // const [avatar, setAvatar] = useState(null);
  const [branchesToSelect, setBranchesToSelect] = useState(
    branches.map(({ id, name }) => ({ id, label: name }))
  );

  useEffect(() => {
    let routesMatch = matchRoutes(routes, location, `/${baseRoute}`);
    routesMatch = routesMatch?.filter(({ route }) => route.element);
    setIsLagacy(routesMatch?.[0]?.route.isLagacy);
  }, [location, routes, baseRoute, matchRoutes]);
  useEffect(() => {
    setOpen(!isMobile);
  }, [isMobile]);
  useEffect(() => {
    setBranchesToSelect(branches.map(({ id, name }) => ({ id, label: name })));
  }, [branches]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar {...{ open }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <p>{selected_branch?.name}</p>
            <p>{selected_branch?.network?.name}</p>
          </div>
          <div>
            <IconButton
              onClick={(e) => {
                setAnchorEl(e.currentTarget);
              }}
            >
              <Avatar src={user?.avatar_path}>{`${user?.first_name?.[0] ?? ""}${
                user?.last_name?.[0] ?? ""
              }`}</Avatar>
              <span style={{ fontSize: 10, marginRight: "1rem" }}>
                <p style={{ fontWeight: "bold" }}>{`${user?.first_name ?? ""} ${
                  user?.last_name ?? ""
                }`}</p>
                <p>{user?.privilege_name}</p>
              </span>
            </IconButton>
            <Box>
              <Menu
                {...{ anchorEl }}
                open={!!anchorEl}
                onClose={() => {
                  setAnchorEl(null);
                }}
                keepMounted
              >
                <MenuItem
                  component={Link}
                  to={`/${baseRoute}/personal/private`}
                >
                  אזור אישי
                </MenuItem>
                {!!connected_users?.length && (
                  <ConnectedUsers {...{ connected_users }} />
                )}
                <MenuItem
                  onClick={() => {
                    logout();
                  }}
                >
                  התנתק
                </MenuItem>
              </Menu>
            </Box>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        {...{ open }}
        PaperProps={{
          sx: {
            backgroundColor: "#1D1D1D",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          },
        }}
      >
        <DrawerHeader>
          <img
            src={open ? restigo_logo_white : restigo_logo}
            alt="logo"
            width={open ? 130 : 30}
            height={open ? 43 : 26}
          />
        </DrawerHeader>
        <List component={"nav"}>
          {routes?.map(({ path, ...rest }, key) => (
            <ListItem
              {...{ key, open, setTitle }}
              route={{
                ...rest,
                path: `/${baseRoute}${path ? `/${path}` : ""}`,
              }}
            />
          ))}
          <ListItem
            route={{ icon: FiHelpCircle, name: "תמיכה" }}
            {...{ open }}
            onClick={() => {
              if (isFunction(window.zE.activate)) {
                window.zE.activate();
              } else {
                setError(
                  "התרחשה שגיאה",
                  "אנא נסה לרענן במידה והתקלה ממשיכה ניתן לפנות לתמיכה"
                );
              }
            }}
          />
        </List>
        <Divider sx={{ backgroundColor: "white" }} />
        <IconButton
          onClick={() => {
            setOpen((prev) => !prev);
          }}
        >
          {(direction === "rtl" && open) || (direction !== "rtl" && !open) ? (
            <FaChevronCircleRight color="#adadad" size={"2.2rem"} />
          ) : (
            <FaChevronCircleLeft color="#adadad" size={"2.2rem"} />
          )}
        </IconButton>
      </Drawer>
      <Main {...{ open }} sx={{ height: "100vh" }}>
        <DrawerHeader />
        {!isLagacy && (
          <CardHeader
            sx={{ border: "none", paddingBottom: 0, marginBottom: 3 }}
            title={
              <span
                style={{
                  fontSize: "3.1rem",
                  fontWeight: 700,
                }}
              >
                {title}
              </span>
            }
            action={
              branches?.length > 1 &&
              !!selected_branch && (
                <AutoComplete
                  blurOnSelect
                  size="small"
                  options={branchesToSelect}
                  value={{
                    id: selected_branch.id,
                    label: selected_branch.name,
                  }}
                  sx={{ width: "12.5rem" }}
                  disableClearable
                  onChange={(e, value) => {
                    setBranch(branches.find(({ id }) => value.id === id));
                  }}
                  inputProps={{
                    variant: "outlined",
                    sx: {
                      backgroundColor: "white",
                      "& fieldset": {
                        borderColor: "white",
                      },
                    },
                  }}
                />
              )
            }
          />
        )}
        <Card
          sx={{
            flexGrow: 1,
            padding: "0px",
            margin: "0px",
            overflow: "scroll",
          }}
        >
          <CardContent sx={{ padding: 0, margin: "2rem" }}>
            {/* <Zendesk
              defer
              zendeskKey={process.env.REACT_APP_ZENDESK_KEY}
              onLoaded={() => {
                ZendeskAPI("webWidget", "setLocale", "he");
                ZendeskAPI("webWidget", "hide");
              }}
            /> */}
            <Outlet context={{ routes }} />
          </CardContent>
        </Card>
      </Main>
    </Box>
  );
};

// const Layout = ({
//   children,
//   locale,
//   collapsed,
//   isFrame,
//   selected_branch_id,
//   fetchEmployees,
//   fetchRoles,
//   setIsMobile,
//   isMobile,
//   isTablet,
//   setIsTablet,
//   user,
//   checkConnection,
//   fetchUserPrivileges,
//   active,
//   title = "",
//   getPendingCount,
//   getBranches,
//   fetchRequiredActions,
//   token,
// }) => {
//   const isMounted = useIsMountedRef();
//   const [toggled, setToggled] = useState(false);
//   const isIdle = useIdle({ timeToIdle: 7 * 60000 });

//   useEffect(() => {
//     if (!!token) {
//       fetchUserPrivileges();
//       getPendingCount();
//       getBranches();
//     }
//   }, [token, fetchUserPrivileges, fetchRequiredActions, getBranches]);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       checkConnection();
//       if (token) {
//         if (!isIdle) {
//           fetchUserPrivileges();
//           getPendingCount();
//         }
//       }
//     }, 15 * 60000);

//     return () => {
//       clearInterval(interval);
//     };
//   }, [isIdle, token, fetchUserPrivileges, getPendingCount]);

//   useEffect(() => {
//     if (!selected_branch_id) {
//       return;
//     }
//     // if (isMounted) {
//     // }
//     fetchEmployees();
//     fetchRoles();
//   }, [selected_branch_id, fetchEmployees, fetchRoles]);

//   const [windowWidth] = useWindowSize();
//   useEffect(() => {
//     if (windowWidth < 100) {
//       return;
//     }
//     if (windowWidth <= 500) {
//       if (!isMobile) {
//         setIsMobile(true);
//       }
//     } else {
//       if (isMobile) {
//         setIsMobile(false);
//       }
//     }
//     if (windowWidth > 500 && windowWidth <= 1281) {
//       if (!isTablet) {
//         setIsTablet(true);
//       }
//     } else {
//       if (isTablet) {
//         setIsTablet(false);
//       }
//     }
//   }, [windowWidth, isMobile, isTablet]);

//   const handleToggleSidebar = (value) => {
//     setToggled(value);
//   };

//   const rtl = locale === "heb" ? true : false;

//   if (!isMobile && !isTablet) {
//     return (
//       <div className={`app ${rtl ? "rtl" : ""} ${toggled ? "toggled" : ""}`}>
//         <Navigation
//           rtl={rtl}
//           toggled={toggled}
//           handleToggleSidebar={handleToggleSidebar}
//         />
//         <div
//           className={`page-layout ${collapsed ? "page-layout__collapsed" : ""}`}
//         >
//           <Header />

//           <div className=" page-layout__content">
//             <main>
//               <ErrorModal />
//               <ConfirmModal />
//               <DialogModal />
//               <ConfirmBar />
//               <LoaderModal />
//               {isFrame || active === "home" ? (
//                 <></>
//               ) : (
//                 <div
//                   className="flex"
//                   style={{ width: "96%", margin: "0 auto" }}
//                 >
//                   <div style={{ width: "50%" }}>
//                     <H1 pageName={title} />
//                   </div>
//                   <div
//                     className="flex-end"
//                     style={{ width: "50%", position: "relative" }}
//                   >
//                     <BranchDropdown />
//                   </div>
//                 </div>
//               )}
//               {/* {children} */}
//             </main>
//           </div>
//         </div>
//       </div>
//     );
//   } else {
//     return (
//       <div className={`app ${rtl ? "rtl" : ""}`}>
//         <MobileHeader />
//         <div className="mobile">
//           <ConfirmBar />
//           <div
//             className={`mobile--content ${
//               isFrame ? "mobile--content__iframe" : ""
//             }`}
//           >
//             <main>
//               <ErrorModal />
//               <ConfirmModal />
//               <DialogModal />
//               <LoaderModal />
//               {/* {children} */}
//               <Outlet />
//             </main>
//           </div>
//         </div>
//       </div>
//     );
//   }
// };

const mapStateToProps = (state) => {
  const { locale } = state.i18n;
  const { branches } = state.branches;
  return { locale, branches };
};

const mapDispatchToProps = {
  fetchEmployees,
  fetchRoles,
  checkConnection,
  fetchUserPrivileges,
  getNetworkCategories,
  getNetworkSuppliers,
  getPendingCount,
  getBranches,
  setBranch,
  setError,
};

export default connect(mapStateToProps, mapDispatchToProps)(MinDrawer);
