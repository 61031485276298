import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "axios";
import { get, isEmpty, isNumber, values } from "lodash";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../pages/managePositions/consts";
import { setConfirm, setSnackBar } from "../actions/confirmAction";
import { setError } from "../actions/errorAction";
import { startLoading, stopLoading } from "../actions/loaderAction";
import { logout } from "../actions/loginAction";

const INITIAL_STATE = {
  filter: {
    status: [2],
    branch: null,
    branch_id: null,
    positions: [],
    emptyTypesFilter: null,
    per_page: 25,
    current_page: 1,
    total: 0,
    last_page: null,
  },
  isLoading: false,
  isSubPositionLoading: false,
  isSPLoading: false,
  isSaving: false,
  error: null,
  saveError: null,
  selectedPosition: {
    id: null,
    branch_id: null,
    employee_type_id: null,
    title: null,
    color: null,
    bonus: 0,
    bonus_participation: 0,
    tips_type: 0,
    show_in_schedule_tip: true,
    tip_participation: 0,
    min_tips_type: 0,
    min_tips: 0,
    mode_participation_tip: 0,
    enable: true,
    update_by: null,
    last_update: null,
    id_in_shift: null,
    unworked_tip: 0,
    tips_allowance_by_position: 0,
    tips_allowance_method: 0,
    tips_allowance: 0,
    auto_scheduling_mandatory: 0,
    tips_daily_distribution: 0,
    tip_participations: [],
    // min_tips_by_day: {},
  },
  selectedEmployeeType: { id: null },
  positions: [],
};

const managePositionSlice = createSlice({
  name: "managePosition",
  initialState: INITIAL_STATE,
  reducers: {
    setFilter: (state, action) => {
      const { key, value } = action.payload;

      state.filter[key] = value;
    },
    resetFilters: (state, action) => {
      state.filter.branch = INITIAL_STATE.filter.branch;
      state.filter.positions = INITIAL_STATE.filter.positions;
      state.filter.status = INITIAL_STATE.filter.status;
    },
    resetSelectedPosition: (state, action) => {
      state.selectedPosition = INITIAL_STATE.selectedPosition;
    },
    resetSelectedEmployeeType: (state, action) => {
      state.selectedEmployeeType = INITIAL_STATE.selectedEmployeeType;
    },

    setNewPosition: (state, action) => {
      state.selectedPosition = { ...INITIAL_STATE.selectedPosition, id: 0 };
      state.selectedEmployeeType = INITIAL_STATE.selectedEmployeeType;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPosition.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPosition.fulfilled, (state, action) => {
      state.isLoading = false;
      state.positions = action.payload.data;
      state.filter.current_page = action.payload.current_page;
      state.filter.per_page = +action.payload.per_page;
      state.filter.total = action.payload.total;
      state.filter.last_page = action.payload.last_page;
    });
    builder.addCase(fetchPosition.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(fetchSinglePosition.pending, (state, action) => {
      state.isSPLoading = true;
    });
    builder.addCase(fetchSinglePosition.fulfilled, (state, action) => {
      state.isSPLoading = false;
      const subtypesMap = {};

      if (action.payload.main_position) {
        action.payload.data.shift_positions?.subtypes?.forEach(
          (shift_position) => {
            if (!subtypesMap[shift_position.employee_type_id]) {
              subtypesMap[shift_position.employee_type_id] = [];
            }
            subtypesMap[shift_position.employee_type_id].push(shift_position);
          }
        );
        state.selectedEmployeeType = {
          ...action.payload.data,
          salary_structure: {
            ...action.payload.data.salary_structure[0],
            salary_by_subtype: Object.values(
              action.payload.data.salary_structure[0].salary_by_subtype ?? {}
            ),
            adjusted_salary:
              action.payload.data.salary_structure[0].adjusted_salary,
            base_extra: JSON.parse(
              action.payload.data.salary_structure[0].base_extra
            ),
          },
          hours_structure: {
            ...action.payload.data.hours_structure[0],
            day_length_by_day: JSON.parse(
              action.payload.data.hours_structure[0].day_length_by_day
            ),
            night_shift_type:
              action.payload.data.hours_structure[0].night_shift_length > 0
                ? true
                : false,
            is_calc_hours: !isEmpty(
              values(
                JSON.parse(
                  action.payload.data.hours_structure[0].calc_hours_settings
                )
              )
            )
              ? true
              : false,
            calc_hours_settings: JSON.parse(
              action.payload.data.hours_structure[0].calc_hours_settings
            ),
          },
          shift_positions: {
            ...action.payload.data.shift_positions,
            subtypesMap,
          },
          minimum_shift: {
            ...action.payload.data.minimum_shift[0],
          },
        };
        state.selectedPosition = INITIAL_STATE.selectedPosition;
      } else {
        state.selectedPosition = action.payload;
        state.selectedEmployeeType = INITIAL_STATE.selectedEmployeeType;
      }
    });
    builder.addCase(fetchSinglePosition.rejected, (state, action) => {
      state.isSPLoading = false;
      state.error = action.error.message;
    });
    // builder.addCase(updateSubPositionStatus.pending, (state, action) => {
    //   state.isSubPositionLoading = true;
    // });
    // builder.addCase(updateSubPositionStatus.fulfilled, (state, action) => {
    //   // console.log(action.payload);
    //   // // state.positions = action.payload;
    //   // state.isSubPositionLoading = false;
    // });
  },
});

export const fetchPosition = createAsyncThunk(
  "managePosition/fetchPosition",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const filter = getState().managePositions.filter;
    const res = await Axios.get(BASE_URL, {
      params: { ...filter, emptyTypesFilter: filter.emptyTypesFilter?.id },
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await res.data;
    return data;
  }
);

export const fetchSinglePosition = createAsyncThunk(
  "managePosition/fetchSinglePosition",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    const position_id = payload.position_id;
    const branch_id = getState().managePositions.filter.branch.id;
    const res = await Axios.get(`${BASE_URL}/${position_id}`, {
      params: {
        position_id: position_id,
        main_position: payload.main_position ?? null,
        branch_id: branch_id ?? null,
      },
      headers: { Authorization: `Bearer ${token}` },
    });
    const data = await res.data;
    if (!payload.main_position) {
      const selectedBranch = getState().branches.branches.find(
        (branch) => branch.id === data.branch_id
      );
      const selectedEmployeeType = getState().generalData.employeeTypes.find(
        (employee_type) => employee_type.id === data.employee_type_id
      );
      data.branch_id = { id: selectedBranch.id, label: selectedBranch.name };
      data.employee_type_id = {
        id: selectedEmployeeType.id,
        label: selectedEmployeeType.title,
      };
      return data;
    } else {
      return { data: data, main_position: payload.main_position };
    }
  }
);

export const updateSinglePosition = createAsyncThunk(
  "managePosition/updateSinglePosition",
  async (payload, { dispatch, getState }) => {
    dispatch(startLoading());
    const selectedPosition = payload;
    const token = getState().auth.token;
    try {
      const response = await Axios.put(
        `${BASE_URL}/${selectedPosition.id}`,
        {
          ...selectedPosition,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.status === 200) {
        dispatch(stopLoading());
        dispatch(setConfirm("תת התפקיד נשמר בהצלחה"));
        dispatch(managePositionActions.resetSelectedPosition());
        dispatch(fetchPosition());
      }
    } catch (err) {
      dispatch(stopLoading());
      dispatch(
        setError("ניתן לפנות לתמיכה הטכנית של רסטיגו  ", "ארעה שגיאה בשרת")
      );
    }
  }
);

//seperate functions to update the employee type , used axios all insted

// export const updateShiftWatchPriviliges = createAsyncThunk(
//   "managePositions",
//   async (payload, { dispatch, getState }) => {
//     const token = getState().auth.token;

//     const response = await Axios.post(
//       `${BASE_URL}/updateShiftWatchPriviliges`,
//       payload,
//       { headers: { Authorization: `Bearer ${token}` } }
//     );

//     console.log(response);
//   }
// );

// export const updateHoursStructure = createAsyncThunk(
//   "managePositions/updateHoursStructure",
//   async (payload, { dispach, getState }) => {
//     const token = getState().auth.token;
//     const response = await Axios.post(
//       `${BASE_URL}/updateHoursStructure`,
//       payload,
//       { headers: { Authorization: `Bearer ${token}` } }
//     );
//     console.log(response);
//   }
// );

// export const updateSalaryStructure = createAsyncThunk(
//   "managePositions/updateSalaryStructure",
//   async (payload, { dispach, getState }) => {
//     const token = getState().auth.token;
//     const response = await Axios.post(
//       `${BASE_URL}/updateSalaryStructure`,
//       payload,
//       { headers: { Authorization: `Bearer ${token}` } }
//     );
//     console.log(response);
//   }
// );

export const updateEmployeeType = createAsyncThunk(
  "managePositions/updateEmployeeType",
  async (payload, { dispatch, getState }) => {
    dispatch(startLoading());
    const token = getState().auth.token;

    const axiosRequests = [];

    // Check the editMode values and add the requests to the array
    if (payload.watchPriviliges.editMode) {
      const request1 = Axios.post(
        `${BASE_URL}/updateShiftWatchPriviliges`,
        payload.watchPriviliges,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      axiosRequests.push(request1);
    }

    if (payload.hoursStructure.editMode) {
      const request2 = Axios.post(
        `${BASE_URL}/updateHoursStructure`,
        payload.hoursStructure,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      axiosRequests.push(request2);
    }

    if (payload.salaryStructure.editMode) {
      if (payload.salaryStructure?.adjusted_salary?.["150"]) {
        payload.salaryStructure.adjusted_salary["shabat"] =
          payload.salaryStructure.adjusted_salary["150"];
      }
      const request3 = Axios.post(
        `${BASE_URL}/updateSalaryStructure`,
        payload.salaryStructure,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      axiosRequests.push(request3);
    }

    if (payload.weekMinLength.editMode) {
      const request4 = Axios.post(
        `${BASE_URL}/updateMinimumShift`,
        payload.weekMinLength,
        { headers: { Authorization: `Bearer ${token}` } }
      );
      axiosRequests.push(request4);
    }

    if (axiosRequests.length > 1) {
      try {
        const responses = await Axios.all(axiosRequests);
        const status = responses.every((response) => response.status === 200);
        if (status) {
          dispatch(stopLoading());
          dispatch(setConfirm("הגדרות התפקיד נשמרו בהצלחה"));
          dispatch(managePositionActions.resetSelectedEmployeeType());
          dispatch(fetchPosition());
        }
      } catch (err) {
        stopLoading();
        console.error(err);
        const error = err.response;
        switch (error.status) {
          case 401:
            dispatch(logout());
            break;
          default:
            dispatch(
              setError("ניתן לפנות לתמיכה הטכנית של רסטיגו", "ארעה שגיאה בשרת")
            );
            break;
        }
      }
    } else if (axiosRequests.length === 1) {
      try {
        const response = await axiosRequests[0];

        if (response.status) {
          dispatch(stopLoading());
          dispatch(setConfirm("הגדרות התפקיד נשמרו בהצלחה"));
          dispatch(managePositionActions.resetSelectedEmployeeType());
          dispatch(fetchPosition());
        }
      } catch (err) {
        stopLoading();
        console.error(err);
        const error = err.response;
        switch (error.status) {
          case 401:
            dispatch(logout());
            break;
          default:
            dispatch(
              setError("ניתן לפנות לתמיכה הטכנית של רסטיגו", "ארעה שגיאה בשרת")
            );
            break;
        }
      }
    }
  }
);

export const updateSubPositionStatus = createAsyncThunk(
  "managePositions/updateSubPositionStatus",
  async (payload, { dispatch, getState }) => {
    const token = getState().auth.token;
    try {
      const response = await Axios.post(
        `${BASE_URL}/update-status`,
        {
          enable: payload.enable,
          position_id: payload.position_id,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        dispatch(setSnackBar("עודכן בהצלחה"));
        dispatch(fetchPosition());
      }
    } catch (err) {
      setError("ניתן לפנות לתמיכה הטכנית של רסטיגו", "ארעה שגיאה בשרת");
    }
  }
);
export const {
  reducer: managePositionReducer,
  actions: managePositionActions,
} = managePositionSlice;
