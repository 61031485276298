import {
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { debounce } from "lodash";
import moment from "moment";
import { createElement, useContext, useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { BiShekel } from "react-icons/bi";
import { useSelector, useDispatch } from "react-redux";
import { REQUIRED_MESSAGE } from "../../../components/Forms/constants";
import { ControlAutoComplete } from "../../../components/Forms/inputs/AutoComplete";
import FormTextField from "../../../components/Forms/inputs/FormTextField";
import { useScreenSize } from "../../../Hooks";
import { TYPE_CUT_CONVERSATION, TYPE_SALARY_CONVERSATION } from "../consts";
import { Context } from "../Show";
import {
  PART_EMPLOYEE_LIST,
  PART_TYPE_IDENTIFER,
  PART_DATE,
  PART_OTHER_PARTICIPANTS,
  PART_TYPE_EMPLOYEE_TYPE_TITLE,
  PART_SUBJECT,
  PART_MAIN_CONTENT,
  PARTS,
  PART_SALARY_FIELD,
  PART_UPDATE_DATE,
  PART_NEW_SALARY_FIELD,
  PART_BONUS_SALARY_FIELD,
  PART_WORKER_QUESTION,
  PART_BRANCH_LIST,
} from "./const";
import { useStyles } from "./CutConversationStyle";
import { fetchEmployeesBySendingId } from "../../../redux/actions/scheduleAction/index";
const CutConversation = () => {
  const { parts, user, selectedBranch, procedure } = useContext(Context);
  const { control, setValue, watch, getValues } = useFormContext();
  const [emails, setEmails] = useState([]);
  const { replace } = useFieldArray({ control, name: "parts" });
  const signature = useWatch({ control, name: "signature" });
  const branchEmployees = useSelector((state) => state.employees);
  const [participants, setParticipants] = useState("");
  const [mainContent, setMainContent] = useState("");
  const [subject, setSubject] = useState("");
  const [newSal, setNewSal] = useState("");
  const [bonusSal, setBonusSal] = useState("");
  const [salary, setSalary] = useState("");
  const [department, setDepartment] = useState("");
  const [identifier, setIdentifier] = useState("");
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  const [salaryType, setSalaryType] = useState("עדכון שכר");
  const [workerQuestion, setWorkerQuestion] = useState("");
  const [branchName, setBranchName] = useState("");
  const [employee_name, date, update_date] = watch([
    `parts.${PART_EMPLOYEE_LIST}.value`,
    `parts.${PART_DATE}.value`,
    `parts.${PART_UPDATE_DATE}.value`,
  ]);
  const { procedures } = useSelector((state) => state.manageProcedures);
  const [procedureBranches, setProcedureBranches] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const subjectId = parts.find((item) => item.type === PART_SUBJECT);
    const newSalaryId = parts.find(
      (item) => item.type === PART_NEW_SALARY_FIELD
    );

    if (subjectId) {
      let subjectValue;
      if (procedure.procedure_type_id.id === TYPE_CUT_CONVERSATION) {
        subjectValue = "שיחת חתך";
      } else {
        if (salaryType == "עדכון שכר") {
          subjectValue = "שיחת שכר";
        } else {
          subjectValue = "בונוס חד פעמי";
        }

        setValue(`parts.${PART_NEW_SALARY_FIELD}`, {
          id: newSalaryId.id,
          value: "",
        });
      }
      setValue(`parts.${PART_SUBJECT}`, {
        id: subjectId.id,
        value: subjectValue,
      });
    }
    const currProceudre = procedures.find((item) => item.id === procedure.id);
    const updatedBranches = currProceudre.branches.map((branch) => ({
      id: branch.id,
      label: branch.name, // Change "name" to "label"
    }));
    setProcedureBranches(updatedBranches);
  }, []);
  const handleEmployeeChange = (e, id, index) => {
    const identifierId = parts.find(
      (item) => item.type === PART_TYPE_IDENTIFER
    );
    const departmentId = parts.find(
      (item) => item.type === PART_TYPE_EMPLOYEE_TYPE_TITLE
    );
    const salaryId = parts.find((item) => item.type === PART_SALARY_FIELD);

    setValue(`parts.${index}`, { id, value: e.label });
    setValue("employee_id", e.id);

    if (identifierId) {
      setValue(`parts.${PART_TYPE_IDENTIFER}`, {
        id: identifierId.id,
        value: e.identifier,
      });
      setIdentifier(e.identifier);
    }
    if (departmentId) {
      setValue(`parts.${PART_TYPE_EMPLOYEE_TYPE_TITLE}`, {
        id: departmentId.id,
        value: e.department.label,
      });
      setDepartment(e.department.label);
    }
    if (salaryId) {
      setValue(`parts.${PART_SALARY_FIELD}`, {
        id: salaryId.id,
        value: e.salary,
      });
      setSalary(e.salary);
    }
  };
  const handleBranchChange = (e, id, index) => {
    dispatch(fetchEmployeesBySendingId(e.id));
  };

  const debouncedData = debounce(
    (e, id, index) => {
      if (index == PART_BRANCH_LIST) {
        setValue(`parts.${PART_BRANCH_LIST}`, { id, value: e.label });
        setBranchName(e.label);
      } else if (
        index == PART_WORKER_QUESTION &&
        procedure.procedure_type_id.id === TYPE_SALARY_CONVERSATION
      ) {
        if (e != "עדכון שכר") {
          const newSalaryId = parts.find(
            (item) => item.type === PART_NEW_SALARY_FIELD
          );
          setValue(`parts.${PART_NEW_SALARY_FIELD}`, {
            id: newSalaryId.id,
            value: 0,
          });
          setNewSal("0");
        } else {
          const bonusOneTime = parts.find(
            (item) => item.type === PART_BONUS_SALARY_FIELD
          );
          setValue(`parts.${PART_BONUS_SALARY_FIELD}`, {
            id: bonusOneTime.id,
            value: 0,
          });
          setBonusSal("0");
        }
        setWorkerQuestion(e);
        setValue(`parts.${index}`, { id, value: e });
      } else {
        setValue(`parts.${index}`, { id, value: e.value });
        switch (e.name) {
          case `parts.${PART_OTHER_PARTICIPANTS}.value`:
            setParticipants(e.value);
            break;
          case `parts.${PART_MAIN_CONTENT}.value`:
            setMainContent(e.value);
            break;
          case `parts.${PART_SUBJECT}.value`:
            setSubject(e.value);
            break;
          case `parts.${PART_NEW_SALARY_FIELD}.value`:
            setNewSal(e.value);
            break;
          case `parts.${PART_BONUS_SALARY_FIELD}.value`:
            setBonusSal(e.value);
            break;
          case `parts.${PART_TYPE_IDENTIFER}.value`:
            setIdentifier(e.value);
            break;
          case `parts.${PART_TYPE_EMPLOYEE_TYPE_TITLE}.value`:
            setDepartment(e.value);
            break;
          case `parts.${PART_BRANCH_LIST}.value`:
            setBranchName(e.value);
          default:
            return;
        }
      }
    },
    [100]
  );
  const validateWordCount = (value, maxChars) => {
    const trimmedValue = value ? value.trim() : "";

    return (
      trimmedValue.length <= maxChars ||
      `אפשר עד מקסימום ${maxChars} תווים בשדה זה.`
    );
  };

  const memoEmployess = useMemo(() => {
    return Object.entries(branchEmployees).map(([key, value]) => {
      return {
        id: key,
        label: value.full_name + ", " + value.department_name,
        name: value.full_name,
        identifier: value.employee_ID_Number,
        department: { id: value.department_id, label: value.department_name },
        salary: value.employee_base_sal,
      };
    });
  }, [branchEmployees]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: isTablet ? 0 : 50,
        justifyContent: "center",
        gap: "3rem",
      }}
    >
      <div
        style={{
          margin: isTablet ? 0 : 50,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "3rem",
          fontSize: isMobile ? "1.8rem" : "2rem",
          maxWidth: "100%",
        }}
      >
        <Grid container>
          {parts?.map(({ label, value, type, ...part }, index) => {
            switch (type) {
              case PART_EMPLOYEE_LIST:
                return (
                  <Grid
                    item
                    xs={6}
                    // style={{
                    //   width: "300px",
                    //   marginBottom: "10px",
                    //   marginLeft: "20px",
                    // }}
                  >
                    <Typography>שם העובד</Typography>
                    <ControlAutoComplete
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        options: memoEmployess,
                        handleInputChange: (value) =>
                          handleEmployeeChange(value, part.id, type),
                      }}
                    />
                  </Grid>
                );
              case PART_BRANCH_LIST:
                return (
                  <Grid item xs={6}>
                    <Typography>שם סניף</Typography>
                    <ControlAutoComplete
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        options: procedureBranches,
                        handleInputChange: (value) => {
                          debouncedData(value, part.id, type);
                          handleBranchChange(value, part.id, type);
                        },
                      }}
                    />
                  </Grid>
                );
              case PART_TYPE_IDENTIFER:
                return (
                  <Grid item xs={6}>
                    <Typography>תעודת זהות</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        id: part.id,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                      }}
                    />
                  </Grid>
                );
              case PART_WORKER_QUESTION:
                return (
                  <Grid item xs={7}>
                    <Typography>
                      האם העובד מקבל עדכון שכר או בונוס חד פעמי?
                    </Typography>
                    <input
                      type="checkbox"
                      checked={salaryType === true}
                      onChange={(value) => {
                        setSalaryType(true);
                        debouncedData("עדכון שכר", part.id, type);
                      }}
                    />
                      <label>עדכון שכר</label>
                    <br></br>
                    <input
                      type="checkbox"
                      checked={salaryType === false}
                      onChange={(value) => {
                        setSalaryType(false);
                        debouncedData("בונוס חד פעמי", part.id, type);
                      }}
                    />
                      <label>בונוס חד פעמי</label>
                  </Grid>
                );
              case PART_TYPE_EMPLOYEE_TYPE_TITLE:
                return (
                  <Grid item xs={6}>
                    <Typography>מחלקה</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                      }}
                    />
                  </Grid>
                );
              case PART_DATE:
                return (
                  <Grid
                    item
                    xs={
                      procedure.procedure_type_id.id === TYPE_CUT_CONVERSATION
                        ? 12
                        : 6
                    }
                  >
                    <Typography>תאריך</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        type: "date",
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                      }}
                    />
                  </Grid>
                );
              case PART_SALARY_FIELD:
                return salaryType ? (
                  <Grid item xs={6}>
                    <Typography>שכר נוכחי</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                        InputProps: {
                          endAdornment: <BiShekel />,
                        },
                      }}
                    />
                  </Grid>
                ) : null;
              case PART_NEW_SALARY_FIELD: {
                return salaryType ? (
                  <Grid item xs={6}>
                    <Typography>שכר מבוקש</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                        InputProps: {
                          endAdornment: <BiShekel />,
                        },
                      }}
                    />
                  </Grid>
                ) : null;
              }
              case PART_BONUS_SALARY_FIELD:
                return !salaryType ? ( //just for order thisis 6.001 maybe there is another way
                  <Grid item xs={6.001}>
                    <Typography>בונוס חד פעמי</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                        InputProps: {
                          endAdornment: <BiShekel />,
                        },
                      }}
                    />
                  </Grid>
                ) : null;
              case PART_UPDATE_DATE:
                return (
                  <Grid item xs={6}>
                    <Typography>תאריך עדכון</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        type: "date",
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                      }}
                    />
                  </Grid>
                );
              case PART_OTHER_PARTICIPANTS:
                return (
                  <Grid item xs={6}>
                    <Typography>נוכחים</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        multiline: true,
                        rows: 4,
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                        rules: {
                          validate: (value) => validateWordCount(value, 100),
                          required: REQUIRED_MESSAGE,
                        },
                      }}
                    />
                  </Grid>
                );
              case PART_SUBJECT:
                return (
                  <Grid item xs={6}>
                    <Typography>נושא השיחה</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        multiline: true,
                        rows: 4,
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                        inputProps: {
                          style: {
                            overflowWrap: "break-word",
                            wordWrap: "break-word",
                          },
                        },
                        rules: {
                          validate: (value) => validateWordCount(value, 100),
                          required: REQUIRED_MESSAGE,
                        },
                      }}
                    />
                  </Grid>
                );
              case PART_MAIN_CONTENT:
                return (
                  <Grid item xs={12}>
                    <Typography>פירוט השיחה</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        multiline: true,
                        rows: 4,
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                        inputProps: {
                          style: {
                            overflowWrap: "break-word",
                            wordWrap: "break-word",
                          },
                        },
                        rules: {
                          validate: (value) => validateWordCount(value, 2000),
                          required: REQUIRED_MESSAGE,
                        },
                      }}
                    />
                  </Grid>
                );
            }
          })}
        </Grid>
        <Divider style={{ width: "100%" }} />
        <h2
          style={{
            textAlign: "center",
            fontSize: isTablet ? "2rem" : "",
            textDecoration: "underline",
          }}
        >
          {`טופס ${
            procedure.procedure_type_id.id === TYPE_CUT_CONVERSATION
              ? "שיחת חתך"
              : "שיחת שכר"
          } לעובד ${employee_name ? employee_name.split(",")[0] : ""}`}
        </h2>

        <div>
          <span className={classes.typoStyle}>
            <strong>שם מלא : </strong>
            {employee_name}
          </span>
          <span className={classes.typoStyle}>
            <strong>שם סניף : </strong>
            {branchName}
          </span>
          {isMobile && <br />}
          <span className={classes.typoStyle}>
            <strong>ת.ז : </strong> {identifier}
          </span>
        </div>
        <div>
          <span className={classes.typoStyle}>
            <strong>מחלקה : </strong>
            {department}
          </span>
          {isMobile && <br />}

          <span className={classes.typoStyle}>
            <strong>תאריך : </strong> {moment(date).format("DD-MM-YYYY")}
          </span>
        </div>
        {procedure.procedure_type_id.id === TYPE_SALARY_CONVERSATION && (
          <>
            {salaryType && (
              <div>
                <span className={classes.typoStyle}>
                  <strong>שכר נוכחי : </strong>
                  {salary}
                </span>
                {isMobile && <br />}

                <span className={classes.typoStyle}>
                  <strong>שכר מבוקש : </strong> {newSal}
                </span>
              </div>
            )}
            {!salaryType && (
              <span className={classes.typoStyle}>
                <strong>בונוס חד פעמי : </strong> {bonusSal}
              </span>
            )}
            <span className={classes.typoStyle}>
              <strong>תאריך עדכון: </strong>{" "}
              {moment(update_date).format("DD-MM-YYYY")}
            </span>
          </>
        )}
        <p
          style={{
            maxWidth: "600px",
            wordWrap: "break-word",
            marginTop: "10px",
          }}
        >
          <strong>נוכחים:</strong>
          <br />
          <span style={{ whiteSpace: "pre-line" }}>{participants}</span>
        </p>
        <p style={{ maxWidth: "600px", wordWrap: "break-word" }}>
          <strong>נושא השיחה : </strong>
          <br />
          {procedure.procedure_type_id.id === TYPE_SALARY_CONVERSATION && (
            <span style={{ whiteSpace: "pre-line" }}>
              {salaryType ? "עדכון שכר " : "בונוס חד פעמי"}
            </span>
          )}
          {procedure.procedure_type_id.id !== TYPE_SALARY_CONVERSATION && (
            <span style={{ whiteSpace: "pre-line" }}>{subject}</span>
          )}
        </p>
        <p style={{ maxWidth: "600px", wordWrap: "break-word" }}>
          <strong>פירוט השיחה : </strong>
          <br />
          <span style={{ whiteSpace: "pre-line" }}>{mainContent}</span>
        </p>

        <div>
          <span className={classes.typoStyle}>
            <strong>שם המנהל : </strong>
            {user.full_name}
          </span>
          {isMobile && <br />}

          <span className={classes.typoStyle}>
            <strong>תאריך מילוי הטופס : </strong>
            {moment().format("DD-MM-YYYY")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CutConversation;
