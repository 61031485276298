import {
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Axios from "axios";
import { fetchEmployeesBySendingId } from "../../../redux/actions/scheduleAction/index";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { debounce } from "lodash";
import moment from "moment";
import { createElement, useContext, useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { REQUIRED_MESSAGE } from "../../../components/Forms/constants";
import { ControlAutoComplete } from "../../../components/Forms/inputs/AutoComplete";
import FormTextField from "../../../components/Forms/inputs/FormTextField";
import { Context } from "../Show";
import {
  PARTS,
  PART_EMPLOYEE_NAME,
  INPUT,
  PART_TYPE_IDENTIFER,
  PART_DATE,
} from "./const";
import React from "react";
import { dateReqFormat } from "../../../helpers";
import { ControlSignaturePad } from "../../../components/Forms/inputs/SignaturPad";
import { setDate } from "date-fns";
const Show = () => {
  const { parts, user, selectedBranch, procedure } = useContext(Context);
  const { control, setValue, watch, getValues } = useFormContext();
  const [emails, setEmails] = useState([]);
  const { replace } = useFieldArray({ control, name: "parts" });
  const worker_signature = useWatch({ control, name: "worker_signature" });
  const branchEmployees = useSelector((state) => state.employees);
  const currentBranch = useSelector((state) => state.branches.selected_branch);
  const { branches } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [identifier, setIdentifier] = useState("");
  const [date, setDate] = useState("");
  const [employeeName, setEmployeeName] = useState(" ");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const debouncedData = debounce(
    (e, id, index) => {
      setValue(`parts.${index}`, { id, value: e.value });
      switch (e.name) {
        case `parts.${PART_DATE}.value`:
          setDate(e.value);
          break;
        case `parts.${PART_TYPE_IDENTIFER}.value`:
          setIdentifier(e.value);
          break;
        default:
          return;
      }
    },
    [50]
  );
  useEffect(() => {
    parts.forEach((part) => {
      if (part.type === PART_EMPLOYEE_NAME) {
        console.log(user);
        setValue(`parts.${PART_EMPLOYEE_NAME}`, {
          id: part.id,
          value: user.full_name,
        });
        setEmployeeName(user.full_name);
      }
      if (part.type === PART_TYPE_IDENTIFER) {
        setValue(`parts.${PART_TYPE_IDENTIFER}`, {
          id: part.id,
          value: user.identifier,
        });
        setIdentifier(user.identifier);
      }
    });
  }, []);
  function decodeHtmlEntities(text) {
    const txt = document.createElement("textarea");
    txt.innerHTML = text;
    return txt.value;
  }
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: isTablet ? 0 : 50,
        justifyContent: "center",
        gap: "3rem",
      }}
    >
      <div
        style={{
          margin: isTablet ? 0 : 50,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "3rem",
          fontSize: isMobile ? "1.8rem" : "2rem",
          maxWidth: "100%",
        }}
      >
        <Grid container>
          {parts?.map(({ label, value, type, ...part }, index) => {
            switch (type) {
              case PART_EMPLOYEE_NAME:
                return (
                  <Grid item xs={6}>
                    <Typography>שם העובד</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        id: part.id,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        disabled: true,
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                      }}
                    />
                  </Grid>
                );
              case PART_TYPE_IDENTIFER:
                return (
                  <Grid item xs={6}>
                    <Typography>תעודת זהות</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        id: part.id,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                      }}
                    />
                  </Grid>
                );
              case PART_DATE:
                return (
                  <Grid item xs={6}>
                    <Typography> תאריך</Typography>
                    <FormTextField
                      {...{
                        fullWidth: true,
                        name: `parts.${type}.value`,
                        rules: { required: REQUIRED_MESSAGE },
                        type: "date",
                        handleChange: (value) =>
                          debouncedData(value, part.id, type),
                      }}
                      inputProps={{
                        min: dateReqFormat(
                          new Date(
                            new Date().setDate(new Date().getDate() - 60)
                          )
                        ),
                        max: dateReqFormat(
                          new Date(
                            new Date().setDate(new Date().getDate() + 31)
                          )
                        ),
                      }}
                    />
                  </Grid>
                );
            }
          })}
          <Grid item xs={6.1}>
            <Typography>חתימת עובד</Typography>
            <ControlSignaturePad
              {...{
                name: "worker_signature",
                rules: { required: REQUIRED_MESSAGE },
              }}
            />
          </Grid>
        </Grid>
        <Divider style={{ width: "100%" }} />
        <div>
          <div style={{ display: "flex", flexDirection: "row-reverse" }}>
            <p>תאריך : {moment(new Date()).format("DD.MM.YYYY")}</p>
          </div>
          <p>לכבוד</p>
          <p>
            {decodeHtmlEntities(user.branch.company_name)},{" "}
            {user.branch.company_h_p ? `ח.פ:${user.branch.company_h_p}` : ""}
          </p>
        </div>

        <br></br>
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            flexDirection: "column",
          }}
        >
          <h3>
            <u>הנדון: הודעה על התפטרות</u>
          </h3>
          <p>
            הריני להודיע על התפטרותי מן העבודה ב{user.branch.company_name}{" "}
            {user.branch.company_h_p ? `ח.פ: ${user.branch.company_h_p}` : ""}{" "}
            הידועה גם כ{user.branch_name}, {user.network_name}.
          </p>
          <p>
            ניתנת לכם בזאת הודעה מוקדמת להתפטרות, כך שיחסי העבודה בינינו יתסיימו
            ביום {moment(date || new Date()).format("DD.MM.YYYY")}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          <div>
            <p>בברכה,</p>
          </div>
          <div>
            <p>
              {" "}
              {employeeName}, ת.ז {identifier}
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          <div
            class="signature-container"
            style={{ marginTop: "30px", marginLeft: "30px" }}
          >
            <p>חתימה</p>
            <img
              src={worker_signature ? worker_signature : ""}
              class="signature-image"
            />
            <span class="signature-underline"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Show;
