import { ClickAwayListener, IconButton, Typography } from "@mui/material";
import { isNumber } from "lodash-es";
import React, { useMemo, useState } from "react";
import { FaChevronDown, FaChevronUp, FaEye } from "react-icons/fa";
import ButtonIcon from "../../components/buttons/ButtonIcon";
import MultiSelectCheckbox from "../../components/dropdown/MultiSelectCheckBox";
import {
  PriceCell,
  PercentageCell,
  PercentageFooter,
} from "../../components/tables/components/PriceFooter";
import { HEBREW_MONTHS, SHEKEL_SIGN } from "../../const";
import { fixPrice, numberFormat, thousandSeparator } from "../../helpers";
import {
  SELECTED_PAGE_NOT_RECEIVED,
  SELECTED_PAGE_RECEIVED,
  SELECTED_TAB_COMAPRE_MONTHS,
  SELECTED_TAB_COMPARE_PRICE,
} from "./OrdersAnalysis";
import { IoMdPricetag } from "react-icons/io";
import { Tooltip } from "@mui/material";
import SimpleTable from "../orders/manageOrders/receiveOrder/SimpleTable";
import { uniqBy } from "lodash";

// import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const buildGenericColumns = (object, orderType, selectedPage) => {
  let columnsArray = [];
  object.forEach((month) => {
    let columns = [];

    if (orderType === SELECTED_TAB_COMAPRE_MONTHS) {
      columns = [
        {
          Header: 'סה"כ כמות',
          accessor: `month_${month}_amount_for_avg`,
          style: {
            borderRight: "1px solid rgba(224, 224, 224, 1)",
          },
          Cell: ({ row }) => {
            const { original } = row;

            if (!original.months) return "-";
            const monthData = original.months[month];
            if (!monthData) return "-";

            const lastInMonth = monthData[monthData.length - 1];
            const final_amount = monthData.reduce(
              (n, { amount_for_avg }) => n + amount_for_avg,
              0
            );
            let final_scale = lastInMonth.scale;

            if (lastInMonth.is_weighted) {
            } else if (lastInMonth.by_scale) {
              final_scale = lastInMonth.box_type;
            }

            return isNumber(final_amount) ? (
              <span>
                <span className="is-number">{`${thousandSeparator(
                  final_amount
                )}`}</span>
                {` ${final_scale}`}
              </span>
            ) : (
              "-"
            );
          },
        },
        {
          Header: "מחיר ממוצע",
          accessor: `month_${month}_avg_price`,
          Cell: (props) => {
            const { months, deposit } = props.row.original;
            const { setDialogModal } = props;

            if (!months) return "-";
            const monthData = months[month];
            let dataTable = [];

            if (!monthData) return "-";

            if (monthData.length > 1) {
              dataTable = monthData.map((data) => {
                const price =
                  selectedPage === SELECTED_PAGE_RECEIVED
                    ? data.received_price
                    : data.order_price;
                return {
                  date: data.date,
                  amount: data.amount,
                  file_number: data.file_number,
                  price,
                  branch_name: data.branch_name,
                  isRefund: data.isRefund,
                };
              });
            }

            const showMorePrices = dataTable.length > 0;

            const totalReceived = monthData.reduce(
              (n, { received_price }) => n + received_price,
              0
            );

            const totalOrdered = monthData.reduce(
              (n, { order_price }) => n + order_price,
              0
            );

            const total = SELECTED_PAGE_RECEIVED ? totalReceived : totalOrdered;
            const price = total / monthData.length + deposit;

            return isNumber(price) ? (
              <p>
                <div className="is-number">{fixPrice(price, 2)}</div>

                {showMorePrices && (
                  <IconButton
                    onClick={() => {
                      setDialogModal({
                        iconJSX: () => IoMdPricetag({ size: "7em" }),
                        title: ` מחירים נוספים לחודש ${HEBREW_MONTHS[month]}`,
                        text: (
                          <SimpleTable
                            columns={[
                              {
                                title:
                                  selectedPage === SELECTED_PAGE_RECEIVED
                                    ? "מספר הזמנה"
                                    : "מספר מסמך",
                                name: "file_number",
                                render: (data, row) => {
                                  return (
                                    <span
                                      className={
                                        row.isRefund && "color__danger"
                                      }
                                    >
                                      {data} {row.isRefund && "(זיכוי)"}
                                    </span>
                                  );
                                },
                              },
                              {
                                title: "סניף",
                                name: "branch_name",
                                render: (data, row) => {
                                  return data;
                                },
                              },
                              {
                                title: "תאריך",
                                name: "date",
                                render: (data, row) => {
                                  return data;
                                },
                              },
                              {
                                title: "כמות",
                                name: "amount",
                                render: (data, row) => {
                                  return (
                                    <div className="is-number">{data}</div>
                                  );
                                },
                              },
                              {
                                title: "מחיר",
                                name: "price",
                                render: (data, row) => {
                                  return fixPrice(data, 2);
                                },
                              },
                            ]}
                            data={dataTable.reverse()}
                          />
                        ),
                        showAbortButton: false,
                        onConfirm: () => {},
                      });
                    }}
                  >
                    {IoMdPricetag()}
                  </IconButton>
                )}
              </p>
            ) : (
              "-"
            );
          },
        },
      ];
    }

    if (orderType === SELECTED_TAB_COMPARE_PRICE) {
      columns = [
        {
          Header: "מחיר ממוצע",
          id: `month_${month}_received_price`,
          style: {
            borderRight: "1px solid rgba(224, 224, 224, 1)",
          },
          Cell: (props) => {
            const { months, deposit } = props.row.original;
            const { setDialogModal } = props;
            if (!months) return "-";
            let monthData = months[month];
            let dataTable = [];

            if (!monthData) return "-";

            if (monthData.length > 1) {
              dataTable = monthData.map((data) => {
                const price =
                  selectedPage === SELECTED_PAGE_RECEIVED
                    ? data.received_price
                    : data.order_price;
                const originalPrice =
                  selectedPage === SELECTED_PAGE_RECEIVED
                    ? data.original_received_price
                    : data.original_ordered_price;
                return {
                  date: data.date,
                  amount: data.amount,
                  file_number: data.file_number,
                  price,
                  branch_name: data.branch_name,
                  isRefund: data.isRefund,
                  originalPrice,
                };
              });
            }

            const showMorePrices = dataTable.length > 0;
            const lastInMonth = monthData[monthData.length - 1];

            const catalogPrice = lastInMonth.catalog_price;
            //const discount = lastInMonth.discount;

            // const totalReceived = monthData.reduce(
            //   (n, { received_price }) => n + received_price,
            //   0
            // );

            // const totalOrdered = monthData.reduce(
            //   (n, { order_price }) => n + order_price,
            //   0
            // );

            const totalReceived = monthData.reduce(
              (n, { original_received_price }) => n + original_received_price,
              0
            );

            const totalOrdered = monthData.reduce(
              (n, { original_ordered_price }) => n + original_ordered_price,
              0
            );

            const total = SELECTED_PAGE_RECEIVED ? totalReceived : totalOrdered;
            const price = total / monthData.length + deposit;

            return (
              <div>
                <Tooltip
                  arrow
                  placement="top"
                  followCursor
                  title={
                    <Typography component="div" style={{ color: "white" }}>
                      <div className="u-right-text">
                        <p> &#8362; {`מחיר מחירון: ${catalogPrice}`}</p>
                        {/* <p> % {`הנחה: ${discount}`} </p> */}
                      </div>
                    </Typography>
                  }
                >
                  <p>
                    <div className="is-number">{fixPrice(price, 2)}</div>

                    {showMorePrices && (
                      <IconButton
                        onClick={() => {
                          setDialogModal({
                            iconJSX: () => IoMdPricetag({ size: "7em" }),
                            title: ` מחירים נוספים לחודש ${HEBREW_MONTHS[month]}`,
                            text: (
                              <SimpleTable
                                columns={[
                                  {
                                    title:
                                      selectedPage === SELECTED_PAGE_RECEIVED
                                        ? "מספר הזמנה"
                                        : "מספר מסמך",
                                    name: "file_number",
                                    render: (data, row) => {
                                      return (
                                        <span
                                          className={
                                            row.isRefund && "color__danger"
                                          }
                                        >
                                          {data} {row.isRefund && "(זיכוי)"}
                                        </span>
                                      );
                                    },
                                  },
                                  {
                                    title: "סניף",
                                    name: "branch_name",
                                    render: (data, row) => {
                                      return data;
                                    },
                                  },
                                  {
                                    title: "תאריך",
                                    name: "date",
                                    render: (data, row) => {
                                      return data;
                                    },
                                  },
                                  {
                                    title: "כמות",
                                    name: "amount",
                                    render: (data, row) => {
                                      return (
                                        <div className="is-number">{data}</div>
                                      );
                                    },
                                  },
                                  {
                                    title: "מחיר",
                                    name: "originalPrice",
                                    render: (data, row) => {
                                      return fixPrice(data, 2);
                                    },
                                  },
                                ]}
                                data={dataTable.reverse()}
                              />
                            ),
                            showAbortButton: false,
                            onConfirm: () => {},
                          });
                        }}
                      >
                        {IoMdPricetag()}
                      </IconButton>
                    )}
                  </p>
                </Tooltip>
              </div>
            );
          },
        },
        {
          Header: "מחיר רשת",
          id: `month_${month}_network_price`,
          Cell: (props) => {
            const { original } = props.row;
            if (!original.months) return "-";
            const monthData = original.months[month];
            if (!monthData) return "-";

            const lastInMonth = monthData[monthData.length - 1];

            const networkMarkup = lastInMonth.network_markup;
            const networkPrice = lastInMonth.network_price;
            return isNumber(networkPrice) ? (
              <div>
                <PriceTooltip
                  tooltipText={
                    <div className="u-right-text">
                      <p> &#8362; {`מארקפ: ${networkMarkup}`}</p>
                    </div>
                  }
                  text={fixPrice(networkPrice)}
                />
              </div>
            ) : (
              "-"
            );
          },
        },
      ];
    }

    columnsArray.push({
      id: `month_${month}`,
      style: {
        textAlign: "center",
        borderLeft: "1px solid rgba(224, 224, 224, 1)",
      },
      Header: `${HEBREW_MONTHS[month]}`,
      noSort: true,
      columns,
    });
  });

  return columnsArray;
};

const sumFooter = (rows, key) =>
  rows.reduce(
    (sum, row) =>
      row.depth ? sum : (row.original[key] ? row.original[key] : 0) + sum,
    0
  );

export const ItemTooltip = ({ tooltipText, text, money = false }) => {
  const [open, setOpen] = useState(false);
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div {...(money ? 'className="money"' : null)}>
        {money && <span>&#8362;</span>}
        {text}{" "}
        <Tooltip
          PopperProps={{
            disablePortal: true,
          }}
          placement="left"
          arrow
          onClose={() => setOpen(false)}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={
            <Typography component="div" style={{ color: "white" }}>
              {tooltipText}
            </Typography>
          }
        >
          <IconButton style={{ padding: 0 }} onClick={() => setOpen(!open)}>
            <IoMdPricetag size="2rem" />
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

const PriceTooltip = ({ tooltipText, text }) => {
  return (
    <Tooltip
      arrow
      placement="top"
      followCursor
      title={
        <Typography component="div" style={{ color: "white" }}>
          {tooltipText}
        </Typography>
      }
    >
      <p>{text}</p>
    </Tooltip>
  );
};

export const columns = (show_network_privilege_columns) => [
  {
    // Build our expander column
    id: "expander", // Make sure it has an ID
    accessor: "expander", // Make sure it has an ID
    Header: "",
    Cell: ({ row, setIsTableExpanded }) =>
      // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
      // to build the toggle for expanding a row
      row.canExpand ? (
        <div className="test">
          <IconButton
            {...row.getToggleRowExpandedProps({})}
            aria-label="expand row"
            size="small"
            style={{ color: "inherit" }}
            className={row.isExpanded ? "subRowButton" : ""}
          >
            {row.isExpanded ? <FaChevronUp /> : <FaChevronDown />}
          </IconButton>
        </div>
      ) : null,
  },

  {
    Header: () => {
      return <>שם מוצר ומק"ט</>;
    },
    accessor: "name",
    filter: "fuzzyText",
    Cell: (props) => {
      const { name, catalog_number, description } = props.row.original;
      let subName = name;
      if (name && name.includes(" - ")) {
        subName = name.substring(0, name.indexOf(" - "));
      }

      return (
        <div className="flex-column-right">
          <span>
            <ItemTooltip
              tooltipText={
                <div>
                  <p>{`תיאור המוצר: ${description}`}</p>
                </div>
              }
              text={subName}
            />
          </span>
          {catalog_number !== 0 && isNumber(catalog_number) && (
            <span>מק"ט: {catalog_number}</span>
          )}
        </div>
      );
    },
  },
  {
    Header: "ברקוד",
    accessor: "manufacturer_number",
    filter: "fuzzyText",
  },
  {
    Header: "קטגורייה",
    accessor: "category_name",
    filter: "fuzzyText",
  },
  {
    Header: "ספק",
    accessor: "supplier_name",
    filter: "fuzzyText",
  },
  {
    Header: "כמות שהוזמנה",
    accessor: "amount",
    Cell: ({ row }) => {
      const { amount, by_scale, box_type, scale, is_weighted } = row.original;
      let final_amount = amount;
      let final_scale = scale;

      if (is_weighted) {
      } else if (by_scale) {
        final_scale = box_type;
      }

      return (
        <span>
          <span className="is-number">{`${thousandSeparator(
            final_amount
          )}`}</span>
          {` ${final_scale}`}
        </span>
      );
    },
  },
  {
    Header: "מחיר ממוצע",
    accessor: "avg_price",
    Cell: ({ row }) => {
      const { avg_price } = row.original;
      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(avg_price)}
        </div>
      );
    },
  },
  {
    Header: "מחיר קטלוג",
    accessor: "catalog_price",
    Cell: ({ row }) => {
      const { catalog_price } = row.original;
      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(catalog_price)}
        </div>
      );
    },
  },

  // {
  //   Header: "הנחה",
  //   accessor: "discount",
  //   Cell: ({ row }) => {
  //     const { discount } = row.original;
  //     return `${discount}%`;
  //   },
  // },
  {
    Header: "מחיר נוכחי",
    accessor: "current_price",
    Cell: ({ row }) => {
      const { current_price } = row.original;
      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(current_price)}
        </div>
      );
    },
  },
  {
    Header: 'מחיר נוכחי כולל מע"מ',
    accessor: "current_price_with_vat",
    Cell: ({ row }) => {
      const { current_price_with_vat } = row.original;
      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(current_price_with_vat)}
        </div>
      );
    },
  },

  ...(show_network_privilege_columns
    ? [
        {
          Header: "מחיר רשת",
          accessor: "total_network_price",
          Cell: ({ row }) => {
            const { total_network_price } = row.original;
            return (
              <div className="money">
                {" "}
                <span>&#8362;</span>
                {thousandSeparator(total_network_price)}
              </div>
            );
          },
        },
        {
          Header: "מארקפ",
          accessor: "network_markup_not_recieved",
          Cell: ({ row }) => {
            const { network_markup_not_recieved } = row.original;

            const markup = fixPrice(network_markup_not_recieved);

            return <div className="money">{markup}</div>;
          },
        },
      ]
    : []),
  {
    Header: 'סה"כ ללא מע"מ',
    accessor: "total_without_vat",
    Cell: ({ row }) => {
      const { total_without_vat } = row.original;
      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(total_without_vat)}
        </div>
      );
    },
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_without_vat"),
        [self.rows]
      );

      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(total)}
        </div>
      );
    },
  },
  {
    Header: 'סה"כ עם מע"מ',
    accessor: "total_with_vat",
    Cell: ({ row }) => {
      const { total_with_vat } = row.original;
      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(total_with_vat)}
        </div>
      );
    },
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_with_vat"),
        [self.rows]
      );

      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(total)}
        </div>
      );
    },
  },
  {
    Header: "אחוז מהכנסה",
    accessor: "ordered_percentage_of_income",
    Cell: PercentageCell,
    Footer: PercentageFooter,
  },
  {
    Header: "כמות ליחידת מידה",
    accessor: "per_unit_amount",
    Cell: ({ row }) => {
      const { per_unit_amount } = row.original;
      return <div>{thousandSeparator(per_unit_amount)}</div>;
    },
  },
  {
    Header: "יחידת מידה",
    accessor: "scale",
    Cell: ({ row }) => {
      const { scale } = row.original;
      return <div>{scale}</div>;
    },
  },
  {
    Header: "מחיר ליחידת מידה",
    accessor: "per_unit_price",
    Cell: ({ row }) => {
      const { per_unit_price } = row.original;
      return <div>{thousandSeparator(per_unit_price) + SHEKEL_SIGN}</div>;
    },
  },
];

export const subColumns = (show_network_privilege_columns) => [
  {
    Header: "מוצר",
    accessor: "name",
  },
  {
    Header: "ברקוד",
    accessor: "manufacturer_number",
  },
  {
    Header: "קטגורייה",
    accessor: "category_name",
  },
  {
    Header: "ספק",
    accessor: "supplier_name",
  },
  {
    Header: "סניף",
    accessor: "branch_name",
  },
  {
    Header: "תאריך",
    accessor: "sent_at",
  },
  {
    Header: "תאריך אספקה",
    accessor: "due_date",
  },
  {
    Header: "כמות",
    accessor: "amount",
    Cell: ({ row }) => {
      const { amount, by_scale, box_type, scale, is_weighted } = row.original;
      let final_amount = amount;
      let final_scale = scale;

      if (is_weighted) {
      } else if (by_scale) {
        final_scale = box_type;
      }

      return (
        <span>
          <span className="is-number">{`${thousandSeparator(
            final_amount ? final_amount : 0
          )}`}</span>
          {` ${final_scale}`}
        </span>
      );
    },
  },
  {
    Header: "כמות שנשלחה ביחידות",
    accessor: "amount_sent_by_units",
  },
  {
    Header: "מחיר בהזמנה",
    accessor: "order_price",
    Cell: ({ row }) => {
      const { order_price } = row.original;

      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(order_price || 0)}
        </div>
      );
    },
  },
  ...(show_network_privilege_columns ? sub_markup_columns : []),
  {
    Header: 'סה"כ ללא מע"מ',
    accessor: "total_without_vat",
    Cell: ({ row }) => {
      const { total_without_vat } = row.original;
      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(total_without_vat)}
        </div>
      );
    },
  },
  {
    Header: 'סה"כ עם מע"מ',
    accessor: "total_with_vat",
    Cell: ({ row }) => {
      const { total_with_vat } = row.original;
      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(total_with_vat)}
        </div>
      );
    },
  },
  {
    Header: 'סה"כ פיקדון',
    accessor: "total_deposit",
    Cell: ({ row }) => {
      const { total_deposit } = row.original;
      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(total_deposit)}
        </div>
      );
    },
  },
  {
    Header: "מס' הזמנה",
    accessor: "new_order_id",
    Cell: ({ row, setViewToShow }) => {
      const { new_order_id } = row.original;
      return (
        <div className="flex-between-center">
          <span style={{ marginLeft: ".5rem" }}>{new_order_id}</span>
          <ButtonIcon
            onClick={() => setViewToShow({ new_order_id })}
            className="color__white"
            icon={FaEye}
            alt="צפייה בהזמנה"
          />
        </div>
      );
    },
  },
];

export const receivedColumns = (show_network_privilege_columns) => [
  {
    // Build our expander column
    id: "expander", // Make sure it has an ID
    accessor: "expander", // Make sure it has an ID
    Header: "",
    Cell: ({ row, setIsTableExpanded }) =>
      // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
      // to build the toggle for expanding a row
      row.canExpand ? (
        <IconButton
          {...row.getToggleRowExpandedProps({
            // style: {
            //   // We can even use the row.depth property
            //   // and paddingLeft to indicate the depth
            //   // of the row
            //   paddingRight: `${row.depth * 2}rem`,
            // },
          })}
          aria-label="expand row"
          size="small"
          style={{ color: "inherit" }}
          className={row.isExpanded ? "subRowButton" : ""}
        >
          {row.isExpanded ? <FaChevronUp /> : <FaChevronDown />}
        </IconButton>
      ) : null,
  },
  {
    Header: () => {
      return <>שם מוצר ומק"ט</>;
    },
    accessor: "name",
    filter: "fuzzyText",
    Cell: (props) => {
      const { name, catalog_number, description } = props.row.original;
      let subName = name;
      if (name && name.includes(" - ")) {
        subName = name.substring(0, name.indexOf(" - "));
      }

      return (
        <div className="flex-column-right">
          <span>
            <ItemTooltip
              tooltipText={
                <div>
                  <p>{`תיאור המוצר: ${description}`}</p>
                </div>
              }
              text={subName}
            />
          </span>
          {catalog_number !== 0 && isNumber(catalog_number) && (
            <span>מק"ט: {catalog_number}</span>
          )}
        </div>
      );
    },
  },
  {
    Header: "ברקוד",
    accessor: "manufacturer_number",
    filter: "fuzzyText",
  },
  {
    Header: "קטגורייה",
    accessor: "category_name",
    filter: "fuzzyText",
  },
  {
    Header: "ספק",
    accessor: "supplier_name",
    filter: "fuzzyText",
    Cell: (props) => {
      const { supplier_name } = props.row.original;
      return (
        <div className="flex-column-right">
          <span>{supplier_name}</span>
        </div>
      );
    },
  },
  {
    Header: "כמות שהוזמנה",
    accessor: "ordered_amount",
    Cell: ({ row }) => {
      const { ordered_amount, by_scale, box_type, scale, is_weighted } =
        row.original;
      let final_amount = ordered_amount;
      let final_scale = scale;

      if (is_weighted) {
      } else if (by_scale) {
        final_scale = box_type;
      }

      return (
        <span>
          <span className="is-number">{`${thousandSeparator(
            final_amount
          )}`}</span>
          {` ${final_scale}`}
        </span>
      );
    },
  },
  {
    Header: "כמות שנקלטה",
    accessor: "received_amount",
    Cell: ({ row }) => {
      const { by_scale, box_type, scale, is_weighted, received_amount } =
        row.original;

      let final_scale = scale;

      if (is_weighted) {
      } else if (by_scale) {
        final_scale = box_type;
      }

      return (
        <span>
          <span className="is-number">{`${thousandSeparator(
            received_amount
          )}`}</span>
          {` ${final_scale}`}
        </span>
      );
    },
  },
  {
    Header: "מחיר ממוצע",
    accessor: "avg_price",
    Cell: ({ row }) => {
      const { avg_price } = row.original;
      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(avg_price)}
        </div>
      );
    },
  },
  {
    Header: "מחיר קטלוג",
    accessor: "catalog_price",
    Cell: ({ row }) => {
      const { catalog_price } = row.original;
      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(catalog_price)}
        </div>
      );
    },
  },
  // {
  //   Header: "הנחה",
  //   accessor: "discount",
  //   Cell: ({ row }) => {
  //     const { discount } = row.original;
  //     return `${discount}%`;
  //   },
  // },
  {
    Header: "מחיר נוכחי",
    accessor: "current_price",
    Cell: ({ row }) => {
      const { current_price } = row.original;
      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(current_price)}
        </div>
      );
    },
  },
  {
    Header: 'מחיר נוכחי כולל מע"מ',
    accessor: "current_price_with_vat",
    Cell: ({ row }) => {
      const { current_price_with_vat } = row.original;
      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(current_price_with_vat)}
        </div>
      );
    },
  },
  ...(show_network_privilege_columns
    ? [
        {
          Header: "מחיר רשת",
          accessor: "total_network_price",
          Cell: ({ row }) => {
            const { total_network_price } = row.original;
            return (
              <div className="money">
                {" "}
                <span>&#8362;</span>
                {thousandSeparator(total_network_price)}
              </div>
            );
          },
        },
        {
          Header: "מארקפ",
          accessor: "network_markup_recieved",
          Cell: ({ row }) => {
            const { network_markup_recieved } = row.original;

            const markup = fixPrice(network_markup_recieved);

            return <div className="money">{markup}</div>;
          },
        },
      ]
    : []),
  {
    Header: 'סה"כ הוזמנו ללא מע"מ',
    accessor: "total_ordered_without_vat",
    Cell: ({ row }) => {
      const { total_ordered_without_vat } = row.original;
      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(total_ordered_without_vat)}
        </div>
      );
    },
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_ordered_without_vat"),
        [self.rows]
      );

      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(total)}
        </div>
      );
    },
  },
  {
    Header: 'סה"כ הוזמנו עם מע"מ',
    accessor: "total_ordered_with_vat",
    Cell: ({ row }) => {
      const { total_ordered_with_vat } = row.original;
      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(total_ordered_with_vat)}
        </div>
      );
    },
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_ordered_with_vat"),
        [self.rows]
      );

      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(total)}
        </div>
      );
    },
  },
  {
    Header: 'סה"כ נקלטו ללא מע"מ',
    accessor: "total_received_without_vat",
    Cell: ({ row }) => {
      const { total_received_without_vat } = row.original;
      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(total_received_without_vat)}
        </div>
      );
    },
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_received_without_vat"),
        [self.rows]
      );

      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(total)}
        </div>
      );
    },
  },
  {
    Header: 'סה"כ נקלטו עם מע"מ',
    accessor: "total_received_with_vat",
    Cell: ({ row }) => {
      const { total_received_with_vat } = row.original;
      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(total_received_with_vat)}
        </div>
      );
    },
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_received_with_vat"),
        [self.rows]
      );

      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(total)}
        </div>
      );
    },
  },
  {
    Header: "אחוז מהכנסה",
    accessor: "received_percentage_of_income",
    Cell: PercentageCell,
    Footer: PercentageFooter,
  },
  {
    Header: "כמות ליחידת מידה",
    accessor: "per_unit_amount",
    Cell: ({ row }) => {
      const { per_unit_amount } = row.original;
      return <div>{thousandSeparator(per_unit_amount)}</div>;
    },
  },
  {
    Header: "יחידת מידה",
    accessor: "scale",
    Cell: ({ row }) => {
      const { scale } = row.original;
      return <div>{scale}</div>;
    },
  },
  {
    Header: "מחיר ליחידת מידה",
    accessor: "per_unit_price",
    Cell: PriceCell,
  },
  {
    Header: 'סה"כ פערי מחיר',
    accessor: "total_price_gap",
    Cell: ({ row }) => {
      const { total_price_gap } = row.original;
      return (
        <div className="money">
          <span>&#8362;</span>
          {thousandSeparator(total_price_gap)}
        </div>
      );
    },
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_price_gap"),
        [self.rows]
      );

      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(total)}
        </div>
      );
    },
  },
  {
    Header: 'סה"כ זיכויים',
    accessor: "total_received_refunds",
    Cell: ({ row }) => {
      const { total_received_refunds } = row.original;
      return (
        <div className="money">
          <span>&#8362;</span>
          {thousandSeparator(total_received_refunds)}
        </div>
      );
    },
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_received_refunds"),
        [self.rows]
      );

      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(total)}
        </div>
      );
    },
  },
];

export const receivedSubColumns = (show_network_privilege_columns) => [
  {
    Header: "מוצר",
    accessor: "name",
  },
  {
    Header: "ברקוד",
    accessor: "manufacturer_number",
  },
  {
    Header: "קטגורייה",
    accessor: "category_name",
  },
  {
    Header: "ספק",
    accessor: "supplier_name",
  },
  {
    Header: "סניף",
    accessor: "branch_name",
  },
  {
    Header: "תאריך",
    accessor: "document_date",
  },
  {
    Header: "תיאור מארז",
    accessor: "description",
    hide: true,
    // filter: "fuzzyText",
  },
  {
    Header: "כמות בהזמנה",
    accessor: "ordered_amount",
    Cell: ({ row }) => {
      const { ordered_amount, by_scale, box_type, scale, is_weighted } =
        row.original;
      let final_amount = ordered_amount;
      let final_scale = scale;

      if (is_weighted) {
      } else if (by_scale) {
        final_scale = box_type;
      }

      return (
        <span>
          <span className="is-number">{`${thousandSeparator(
            final_amount ? final_amount : 0
          )}`}</span>
          {` ${final_scale}`}
        </span>
      );
    },
  },
  {
    Header: "כמות בקליטה",
    accessor: "received_amount",
    Cell: ({ row }) => {
      const { received_amount, by_scale, box_type, scale, is_weighted } =
        row.original;
      let final_amount = received_amount;
      let final_scale = scale;

      if (is_weighted) {
      } else if (by_scale) {
        final_scale = box_type;
      }

      return (
        <span>
          <span className="is-number">{`${thousandSeparator(
            final_amount ? final_amount : 0
          )}`}</span>
          {` ${final_scale}`}
        </span>
      );
    },
  },
  {
    Header: "כמות שנשלחה ביחידות",
    accessor: "amount_sent_by_units",
  },
  {
    Header: "כמות שנקלטה ביחידות",
    accessor: "amount_received_by_units",
  },
  {
    Header: "מחיר בהזמנה",
    accessor: "ordered_price",
    Cell: ({ row }) => {
      const { ordered_price } = row.original;

      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(ordered_price || 0)}
        </div>
      );
    },
  },
  {
    Header: "מחיר בקליטה",
    accessor: "received_price",
    Cell: ({ row }) => {
      const { received_price } = row.original;

      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(received_price || 0)}
        </div>
      );
    },
  },
  ...(show_network_privilege_columns ? sub_markup_columns : []),
  {
    Header: 'סה"כ הוזמנו ללא מע"מ',
    accessor: "total_ordered_without_vat",
    Cell: ({ row }) => {
      const { total_ordered_without_vat } = row.original;

      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(total_ordered_without_vat || 0)}
        </div>
      );
    },
  },
  {
    Header: 'סה"כ נקלטו ללא מע"מ',
    accessor: "total_received_without_vat",
    Cell: ({ row }) => {
      const { total_received_without_vat } = row.original;

      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(total_received_without_vat || 0)}
        </div>
      );
    },
  },

  {
    Header: "פערי מחיר",
    accessor: "price_gap",
    Cell: ({ row }) => {
      const price_gap = row.original?.price_gap ? row.original.price_gap : 0;
      return (
        <div className="money">
          <span>&#8362;</span>
          {thousandSeparator(price_gap)}
        </div>
      );
    },
  },
  {
    Header: "זיכויים",
    accessor: "received_refunds",
    Cell: ({ row }) => {
      const received_refunds = row.original?.received_refunds
        ? row.original.received_refunds
        : 0;
      return (
        <div className="money">
          <span>&#8362;</span>
          {thousandSeparator(received_refunds)}
        </div>
      );
    },
  },
  {
    Header: "מס' מסמך",
    accessor: "document_number",
    Cell: ({ row, setViewToShow }) => {
      const { new_received_order_id, document_number } = row.original;
      return (
        <div className="flex-between-center">
          <span style={{ marginLeft: ".5rem" }}>{document_number}</span>
          <ButtonIcon
            onClick={() => setViewToShow({ new_received_order_id })}
            className="color__white"
            icon={FaEye}
            alt="צפייה בקליטה"
          />
        </div>
      );
    },
  },
];

export const markup_columns = (type) => [
  {
    Header: "מחיר רשת",
    accessor: "total_network_price",
    Cell: ({ row }) => {
      const { total_network_price } = row.original;
      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(total_network_price)}
        </div>
      );
    },
  },
  {
    Header: "מארקפ",
    accessor: "network_markup_recieved",
    Cell: ({ row }) => {
      const { network_markup_recieved } = row.original;
      const { network_markup_not_recieved } = row.original;
      let markup = 0;
      if ((type = "not_recieved")) {
        markup = fixPrice(network_markup_not_recieved);
      } else {
        markup = fixPrice(network_markup_recieved);
      }

      return <div className="money">{markup}</div>;
    },
  },
];

export const priceComapreColumns = (months, selectedPage) => [
  {
    Header: "מוצר",
    noSort: true,
    style: {
      textAlign: "center",
      borderLeft: "1px solid rgba(224, 224, 224, 1)",
    },
    columns: [
      {
        Header: 'שם מוצר ומק"ט',
        accessor: "name",
        filter: "fuzzyText",
        Cell: (props) => {
          const { name, catalog_number, description } = props.row.original;
          let subName = name;
          if (name?.includes(" - ")) {
            subName = name.substring(0, name.indexOf(" - "));
          }
          return (
            <div className="flex-column-right">
              <span>
                <ItemTooltip
                  tooltipText={
                    <div>
                      <p>{`תיאור המוצר: ${description}`}</p>
                    </div>
                  }
                  text={subName}
                />
              </span>
              {catalog_number !== 0 && isNumber(catalog_number) && (
                <span>מק"ט: {catalog_number}</span>
              )}
            </div>
          );
        },
      },
      {
        Header: "קטגורייה",
        accessor: "category_name",
        filter: "fuzzyText",
      },
      {
        Header: "ספק",
        accessor: "supplier_name",
        filter: "fuzzyText",
        Cell: (props) => {
          const { supplier_name } = props.row.original;
          return (
            <div className="flex-column-right">
              <span>{supplier_name}</span>
            </div>
          );
        },
      },
    ],
  },

  ...buildGenericColumns(months, SELECTED_TAB_COMPARE_PRICE, selectedPage),
];

const FOOTER_TYPE_MONEY = 0;
const FOOTER_TYPE_PERCENTAGE = 1;

const dualFooter = (data1, data2, type) => {
  const flexStyles = { gap: "5px" };
  const space = "\u00A0";

  switch (type) {
    case FOOTER_TYPE_MONEY:
      return (
        <div className="money flex-column-end" style={flexStyles}>
          <span>{SHEKEL_SIGN + thousandSeparator(data1)}</span>
          <span>{data2 ? SHEKEL_SIGN + thousandSeparator(data2) : space}</span>
        </div>
      );
    case FOOTER_TYPE_PERCENTAGE:
      return (
        <div className="flex-column-right" style={flexStyles}>
          <span>{data1.toFixed(0) + "%"}</span>
          <span>{data2 ? data2.toFixed(0) + "%" : space}</span>
        </div>
      );
    default:
      return (
        <div className="flex-column-right" style={flexStyles}>
          <span>{data1}</span>
          <span>{data2 || space}</span>
        </div>
      );
  }
};

export const categoriesColumns = (selectedPage) => [
  {
    Header: "שם קטגוריה",
    accessor: "name",
    filter: "fuzzyText",
    Footer: (self) => dualFooter("סה״כ", "סה״כ הכנסות"),
  },
  ...(selectedPage === SELECTED_PAGE_NOT_RECEIVED
    ? [
        {
          Header: "סה״כ נשלחו ללא מע״מ",
          accessor: "total_without_vat",
          Cell: PriceCell,
          Footer: (self) =>
            dualFooter(
              sumFooter(self.rows, "total_without_vat"),
              self.incomesWithoutVat,
              FOOTER_TYPE_MONEY
            ),
        },
        {
          Header: "סה״כ נשלחו כולל מע״מ",
          accessor: "total_with_vat",
          Cell: PriceCell,
          Footer: (self) =>
            dualFooter(
              sumFooter(self.rows, "total_with_vat"),
              self.incomesWithVat,
              FOOTER_TYPE_MONEY
            ),
        },
        {
          Header: "אחוז מהכנסה",
          accessor: "ordered_percentage_of_income",
          Cell: PercentageCell,
          Footer: (self) =>
            dualFooter(
              sumFooter(self.rows, "ordered_percentage_of_income"),
              null,
              FOOTER_TYPE_PERCENTAGE
            ),
        },
      ]
    : [
        {
          Header: "סה״כ נקלטו ללא מע״מ",
          accessor: "total_received_without_vat",
          Cell: PriceCell,
          Footer: (self) =>
            dualFooter(
              sumFooter(self.rows, "total_received_without_vat"),
              self.incomesWithoutVat,
              FOOTER_TYPE_MONEY
            ),
        },
        {
          Header: "סה״כ נקלטו כולל מע״מ",
          accessor: "total_received_with_vat",
          Cell: PriceCell,
          Footer: (self) =>
            dualFooter(
              sumFooter(self.rows, "total_received_with_vat"),
              self.incomesWithVat,
              FOOTER_TYPE_MONEY
            ),
        },
        {
          Header: "אחוז מהכנסה",
          accessor: "received_percentage_of_income",
          Cell: PercentageCell,
          Footer: (self) =>
            dualFooter(
              sumFooter(self.rows, "received_percentage_of_income"),
              null,
              FOOTER_TYPE_PERCENTAGE
            ),
        },
      ]),
];

export const suppliersColumns = (selectedPage) => [
  {
    Header: "ספק",
    accessor: "supplier_name",
    filter: "fuzzyText",
    Footer: (self) => dualFooter("סה״כ", "סה״כ הכנסות"),
  },
  {
    Header: "קטגוריה",
    accessor: "category",
    filter: "fuzzyText",
  },
  ...(selectedPage === SELECTED_PAGE_NOT_RECEIVED
    ? [
        {
          Header: 'סה"כ הוזמנו ללא מע"מ',
          accessor: "total_without_vat",
          Cell: PriceCell,
          Footer: (self) =>
            dualFooter(
              sumFooter(self.rows, "total_without_vat"),
              self.incomesWithoutVat,
              FOOTER_TYPE_MONEY
            ),
        },
        {
          Header: 'סה"כ הוזמנו עם מע"מ',
          accessor: "total_with_vat",
          Cell: PriceCell,
          Footer: (self) =>
            dualFooter(
              sumFooter(self.rows, "total_with_vat"),
              self.incomesWithVat,
              FOOTER_TYPE_MONEY
            ),
        },
        {
          Header: "אחוז מרכש",
          accessor: "total_without_vat_percentage",
          Cell: PercentageCell,
          Footer: (self) =>
            dualFooter(
              sumFooter(self.rows, "total_without_vat_percentage"),
              null,
              FOOTER_TYPE_PERCENTAGE
            ),
        },
        {
          Header: "אחוז מהכנסה",
          accessor: "ordered_percentage_of_income",
          Cell: PercentageCell,
          Footer: (self) =>
            dualFooter(
              sumFooter(self.rows, "ordered_percentage_of_income"),
              null,
              FOOTER_TYPE_PERCENTAGE
            ),
        },
      ]
    : [
        {
          Header: 'סה"כ נקלטו ללא מע"מ',
          accessor: "total_received_without_vat",
          Cell: PriceCell,
          Footer: (self) =>
            dualFooter(
              sumFooter(self.rows, "total_received_without_vat"),
              self.incomesWithoutVat,
              FOOTER_TYPE_MONEY
            ),
        },
        {
          Header: 'סה"כ נקלטו עם מע"מ',
          accessor: "total_received_with_vat",
          Cell: PriceCell,
          Footer: (self) =>
            dualFooter(
              sumFooter(self.rows, "total_received_with_vat"),
              self.incomesWithVat,
              FOOTER_TYPE_MONEY
            ),
        },
        {
          Header: "אחוז מרכש",
          accessor: "total_received_without_vat_percentage",
          Cell: PercentageCell,
          Footer: (self) =>
            dualFooter(
              sumFooter(self.rows, "total_received_without_vat_percentage"),
              null,
              FOOTER_TYPE_PERCENTAGE
            ),
        },
        {
          Header: "אחוז מהכנסה",
          accessor: "received_percentage_of_income",
          Cell: PercentageCell,
          Footer: (self) =>
            dualFooter(
              sumFooter(self.rows, "received_percentage_of_income"),
              null,
              FOOTER_TYPE_PERCENTAGE
            ),
        },
        {
          Header: 'סה"כ פערי מחיר',
          accessor: "total_price_gap",
          Cell: PriceCell,
          Footer: (self) =>
            dualFooter(
              sumFooter(self.rows, "total_price_gap"),
              null,
              FOOTER_TYPE_MONEY
            ),
        },
        {
          Header: 'סה"כ זיכויים',
          accessor: "total_received_refunds",
          Cell: PriceCell,
          Footer: (self) =>
            dualFooter(
              sumFooter(self.rows, "total_received_refunds"),
              null,
              FOOTER_TYPE_MONEY
            ),
        },
      ]),
  {
    Header: "תנאי תשלום",
    accessor: "payment_condition",
    filter: "fuzzyText",
  },
];

export const monthsColumns = (months, selectedPage) => [
  {
    Header: "מוצר",
    noSort: true,
    style: {
      textAlign: "center",
      borderLeft: "1px solid rgba(224, 224, 224, 1)",
    },
    columns: [
      {
        Header: 'שם מוצר ומק"ט',
        accessor: "name",
        filter: "fuzzyText",
        Cell: (props) => {
          const { name, catalog_number, description } = props.row.original;
          let subName = name;
          if (name?.includes(" - ")) {
            subName = name.substring(0, name.indexOf(" - "));
          }

          return (
            <div className="flex-column-right">
              <span>
                <ItemTooltip
                  tooltipText={
                    <div>
                      <p>{`תיאור המוצר: ${description}`}</p>
                    </div>
                  }
                  text={subName}
                />
              </span>
              {catalog_number !== 0 && isNumber(catalog_number) && (
                <span>מק"ט: {catalog_number}</span>
              )}
            </div>
          );
        },
      },
      {
        Header: "קטגורייה",
        accessor: "category_name",
        filter: "fuzzyText",
      },
      {
        Header: "ספק",
        accessor: "supplier_name",
        filter: "fuzzyText",
        Cell: (props) => {
          const { supplier_name } = props.row.original;
          return (
            <div className="flex-column-right">
              <span>{supplier_name}</span>
            </div>
          );
        },
      },
      {
        Header: "מחיר קטלוג",
        accessor: "catalog_price",
        Cell: ({ row }) => {
          const { catalog_price } = row.original;
          return <div className="money">{fixPrice(catalog_price)}</div>;
        },
      },

      // {
      //   Header: "הנחה",
      //   accessor: "discount",
      //   Cell: ({ row }) => {
      //     const { discount } = row.original;
      //     return (
      //       <div className="is-number">{Number(discount).toFixed(0)} %</div>
      //     );
      //   },
      // },
      {
        Header: "מחיר נוכחי",
        accessor: "current_price",
        Cell: ({ row }) => {
          const { current_price } = row.original;
          return <div className="money">{fixPrice(current_price)}</div>;
        },
      },
    ],
  },
  ...buildGenericColumns(months, SELECTED_TAB_COMAPRE_MONTHS, selectedPage),
];

export const hiddenColumns = [
  "deposit",
  "total_deposit",
  "catalog_price",
  "current_price_with_vat",
  "total_with_vat",
  "total_network_price",
  "network_markup",
  "catalog_price",
  "current_price",
  "current_price_with_vat",
  "total_deposit",
  "total_with_vat",
  "network_markup_not_recieved",
  "per_unit_amount",
  "scale",
  "per_unit_price",
  "ordered_percentage_of_income",
  "manufacturer_number",
];

export const received_hiddenColumns = [
  "deposit",
  "total_deposit",
  "catalog_price",
  "current_price_with_vat",
  "total_with_vat",
  "total_network_price",
  "network_markup",
  "ordered_amount",
  "total_received_with_vat",
  "total_ordered_with_vat",
  "total_ordered_without_vat",
  "avg_price",
  "current_price",
  "current_price_with_vat",
  "total_deposit",
  "total_ordered_without_vat",
  "network_markup_recieved",
  "per_unit_amount",
  "scale",
  "per_unit_price",
  "received_percentage_of_income",
  "manufacturer_number",
];

export const view_received_columns = [
  {
    Header: "מוצר",
    accessor: "name",
  },
  {
    Header: 'מק"ט',
    accessor: "catalog_number",
  },
  {
    Header: "כמות בהזמנה",
    accessor: "ordered_amount",
    Cell: ({ row }) => {
      const { ordered_amount, by_scale, box_type, scale, is_weighted } =
        row.original;
      let final_amount = ordered_amount;
      let final_scale = scale;

      if (is_weighted) {
      } else if (by_scale) {
        final_scale = box_type;
      }

      return (
        <span>
          <span className="is-number">{`${thousandSeparator(
            final_amount
          )}`}</span>
          {` ${final_scale}`}
        </span>
      );
    },
  },

  {
    Header: "מחיר בהזמנה",
    accessor: "ordered_price",
    Cell: ({ row }) => {
      const { ordered_price, is_weighted } = row.original;
      let final_price = ordered_price;

      if (is_weighted) {
      }
      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(final_price)}
        </div>
      );
    },
  },
  {
    Header: 'סה"כ הוזמנו ללא מע"מ',
    accessor: "total_ordered_without_vat",
    Cell: ({ row }) => {
      const { total_ordered_without_vat } = row.original;
      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(total_ordered_without_vat)}
        </div>
      );
    },
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_ordered_without_vat"),
        [self.rows]
      );

      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(total)}
        </div>
      );
    },
  },
  {
    Header: "כמות בקליטה",
    accessor: "received_amount",
    Cell: ({ row }) => {
      const { received_amount, by_scale, box_type, scale, is_weighted } =
        row.original;
      let final_amount = received_amount;
      let final_scale = scale;

      if (is_weighted) {
      } else if (by_scale) {
        final_scale = box_type;
      }

      return (
        <span>
          <span className="is-number">{`${thousandSeparator(
            final_amount
          )}`}</span>
          {` ${final_scale}`}
        </span>
      );
    },
  },
  {
    Header: "מחיר בקליטה",
    accessor: "received_price",
    Cell: ({ row }) => {
      const { received_price, is_weighted } = row.original;
      let final_price = received_price;

      if (is_weighted) {
      }
      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(final_price)}
        </div>
      );
    },
  },
  {
    Header: 'סה"כ נקלטו ללא מע"מ',
    accessor: "total_received_without_vat",
    Cell: ({ row }) => {
      const { total_received_without_vat } = row.original;
      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(total_received_without_vat)}
        </div>
      );
    },
    Footer: (self) => {
      const total = useMemo(
        () => sumFooter(self.rows, "total_received_without_vat"),
        [self.rows]
      );

      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(total)}
        </div>
      );
    },
  },
  {
    Header: "פערי מחיר",
    accessor: "price_gap",
    Cell: ({ row }) => {
      const { price_gap } = row.original;
      return (
        <div className="money">
          <span>&#8362;</span>
          {thousandSeparator(price_gap)}
        </div>
      );
    },
  },
];

export const aggregates_received = [
  { field: "total_ordered_without_vat", aggregate: "sum" },
  { field: "total_ordered_with_vat", aggregate: "sum" },
  { field: "total_received_without_vat", aggregate: "sum" },
  { field: "total_received_with_vat", aggregate: "sum" },
  { field: "total_price_gap", aggregate: "sum" },
  { field: "total_received_refunds", aggregate: "sum" },
];

export const sub_markup_columns = [
  {
    Header: "מחיר רשת",
    accessor: "network_price",
    Cell: ({ row }) => {
      const { network_price } = row.original;
      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(network_price)}
        </div>
      );
    },
  },
  {
    Header: "מרקאפ",
    accessor: "markup",
    Cell: ({ row }) => {
      const markup = row.original?.markup ? row.original.markup : 0;
      return (
        <div className="money">
          {" "}
          <span>&#8362;</span>
          {thousandSeparator(markup)}
        </div>
      );
    },
  },
];

export const aggregates = [
  { field: "total_with_vat", aggregate: "sum" },
  { field: "total_without_vat", aggregate: "sum" },
];

export const excelCellsConfig = {
  per_unit_price: (val) => {
    return numberFormat(val, 0, 3, "decimal");
  },
  manufacturer_number: (val) => {
    return val?.toString();
  },
};
