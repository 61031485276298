import { Link, Tooltip } from "@mui/material";
import {
  CountFooter,
  PriceCell,
  PriceFooter,
} from "../../../components/tables/components/PriceFooter";

import {
  dynamicToFixed,
  fixPrice,
  getSafeDivision,
  thousandSeparator,
} from "../../../helpers";
import { VAT } from "../../orders/manageOrders/constants";
import { SOLD_DISH_TYPE_UNLINKED } from "../const";
import ButtonIcon from "../../../components/buttons/ButtonIcon";
import { FaLink } from "react-icons/fa";
import { BLUE_COLOR } from "../../products/const";

export const getData = (
  by_code, //raw data
  branches // redux state
) => {
  return by_code
    .filter((soldDish) => soldDish.type === SOLD_DISH_TYPE_UNLINKED)
    .map((dish) => {
      const { product_code, name, branch_ids, amount, total } = dish;

      const branch_names = branch_ids.map(
        (branch_id) => branches.find((branch) => branch.id === branch_id).name
      );

      const total_with_vat = total * VAT;
      const avg_price = getSafeDivision(total, amount);

      return {
        product_code,
        name,
        branch_names,
        amount,
        total_with_vat,
        avg_price,
      };
    });
};

export const excelRowConfig = (row) => {
  row.amount = dynamicToFixed(row.amount);
  row.total_with_vat = dynamicToFixed(row.total_with_vat);
  row.avg_price = dynamicToFixed(row.avg_price);

  return row;
};

export const aggregates = [
  { field: "amount", aggregate: "sum" },
  { field: "total_with_vat", aggregate: "sum" },
  { field: "avg_price", aggregate: "sum" },
];

export const sortBy = [{ id: "avg_foodcost", desc: true }];

export const columns = [
  {
    Header: "קוד קופה",
    accessor: "product_code",
    Footer: () => "סה״כ",
  },
  {
    Header: "שם",
    accessor: "name",
  },
  {
    Header: "סניפים",
    accessor: "branch_names",
    disable_export_excel: true,
    Cell: ({ value: branch_names }) => {
      const amount = branch_names.length;

      return (
        <Tooltip
          title={
            <span style={{ fontSize: "1.5rem" }}>
              {branch_names.join(", ")}
            </span>
          }
          placement="top-start"
        >
          <Link
            component="span"
            sx={{
              fontSize: "1.6rem",
            }}
          >
            {`${amount} סניפים`}
          </Link>
        </Tooltip>
      );
    },
  },
  {
    Header: "כמות שנמכרה",
    accessor: "amount",
    Cell: ({ value }) => (
      <span className="is-number">{thousandSeparator(value)}</span>
    ),
    Footer: CountFooter,
  },
  {
    Header: "מכירות כולל מע״מ",
    accessor: "total_with_vat",
    Cell: (props) => PriceCell(props, 1, false),
    Footer: PriceFooter,
  },
  {
    Header: "מחיר מנה",
    accessor: "avg_price",
    Cell: (props) => PriceCell(props, 1, false),
    Footer: PriceFooter,
  },
  {
    Header: "קישור מוצר",
    accessor: "actions",
    disable_export_excel: true,
    Cell: ({ row, handleLink }) => (
      <span className="flex-around-center">
        <ButtonIcon
          onClick={() => handleLink(row.original)}
          className="btn--normal"
          style={{ color: BLUE_COLOR }}
          icon={FaLink}
          alt="קישור מוצר"
        />
      </span>
    ),
  },
];
