import React, { useMemo } from "react";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { HashRouter, Navigate, Outlet, useRoutes } from "react-router-dom";
import LoginAdmin from "../admin/LoginAdmin";
import RestoreAdmin from "../admin/RestoreAdmin";
import { useScreenSize } from "../Hooks";
import Login from "../pages/Login";
import Restore from "../pages/Restore";
import {
  clearPermissions,
  clearPrivileges,
  fetchPermissions,
  fetchPrivileges,
} from "../redux/actions/adminAction";
import { logoutAdmin } from "../redux/actions/AdminLoginAction";
import { getBranches } from "../redux/actions/branchesAction";
import { fetchEmployeeTypes } from "../redux/actions/generalAction";
import { fetchUserPrivileges, logout } from "../redux/actions/loginAction";
import { fetchUserPermissions } from "../redux/actions/permissions";
import { getHolidays, getPendingCount } from "../redux/actions/scheduleAction";
import { AdminGuest, UserAuth, UserGuest, AdminAuth } from "./Auth";
import Loader from "./Loader";
import { useAdminRoutes, useUserRoutes } from "./routes";

const Router = ({}) => {
  return (
    // <BrowserRouter>
    <HashRouter>
      <Routes />
    </HashRouter>
    // </BrowserRouter>
  );
};

const mapStateToProps = (state) => {
  const { token, user } = state.auth;
  const { token: adminToken, admin } = state.authAdmin;
  const { selected_branch } = state.branches;
  const { permissions } = state;
  const { forms_not_filled } = state.user_privileges;

  return {
    token,
    user,
    admin,
    adminToken,
    selected_branch,
    permissions,
    forms_not_filled,
  };
};
const mapDispatchToProps = {
  fetchPrivileges,
  fetchPermissions,
  clearPrivileges,
  clearPermissions,
  fetchUserPermissions,
  logout,
  logoutAdmin,
  fetchUserPrivileges,
  getPendingCount,
  getBranches,
  fetchEmployeeTypes,
};

const Routes = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  ({
    token,
    adminToken,
    fetchPrivileges,
    fetchPermissions,
    clearPrivileges,
    clearPermissions,
    fetchUserPermissions,
    permissions: { pages, fetched },
    fetchUserPrivileges,
    getPendingCount,
    getBranches,
    fetchEmployeeTypes,
    forms_not_filled,
    selected_branch,
  }) => {
    const [, routes] = useUserRoutes();
    const [adminRoutes] = useAdminRoutes();
    const dispatch = useDispatch();
    useEffect(() => {
      if (adminToken) {
        fetchPrivileges();
        fetchPermissions();
      } else {
        clearPrivileges();
        clearPermissions();
      }
    }, [
      adminToken,
      fetchPrivileges,
      fetchPermissions,
      clearPrivileges,
      clearPermissions,
    ]);

    useEffect(() => {
      fetchUserPermissions();

      if (!token) {
        return;
      }
      dispatch(getHolidays());
      // dispatch(getHolidays());
      fetchUserPrivileges();
      getPendingCount();
      getBranches();
      fetchEmployeeTypes();
    }, [
      token,
      fetchUserPermissions,
      fetchUserPrivileges,
      getPendingCount,
      getBranches,
      fetchEmployeeTypes,
    ]);

    // const userRoutes = useMemo(() => {
    //   /**temporary */
    //   const notFiiledForms = (pageId) => {
    //     let formsIds = [37, 52, 59];
    //     if (pageId === 41) {
    //       return (
    //         !!selected_branch?.user_work_agreement &&
    //         forms_not_filled?.includes(pageId)
    //       );
    //     }
    //     return !formsIds.includes(pageId) || forms_not_filled?.includes(pageId);
    //   };
    //   const filteredSubRoutes = susRoutes.map(({ children, ...route }) => ({
    //     ...route,
    //     ...(!!children && {
    //       children: children?.filter((page) => {
    //         const flag = isSizeMatch(page);
    //         const notFilled = notFiiledForms(page.id);
    //         return flag && notFilled && pages?.includes(page.id);
    //       }),
    //     }),
    //   }));
    //   return null;
    // }, [pages, isMobile, isDesktop, forms_not_filled, selected_branch]);

    const element = useRoutes([
      {
        path: "/users",
        children: [
          {
            element: <UserAuth routes={routes} />,
            children: routes,
          },
          {
            element: (
              <>
                <UserGuest />
                <Outlet />
              </>
            ),
            children: [
              {
                path: "login",
                element: <Login />,
              },
              {
                path: "restore",
                element: <Restore />,
              },
            ],
          },
        ],
      },
      {
        path: "/rs-admin",
        children: [
          {
            element: (
              <AdminGuest>
                <Outlet />
              </AdminGuest>
            ),
            children: [
              {
                path: "login",
                element: <LoginAdmin />,
              },
              {
                path: "restore",
                element: <RestoreAdmin />,
              },
            ],
          },
          {
            element: <AdminAuth routes={adminRoutes} />,
            children: adminRoutes,
          },
        ],
      },
      {
        path: "*",
        element: fetched ? <Navigate to="/users" replace /> : <Loader />,
      },
      ,
    ]);
    return element;
  }
);

export default connect(mapStateToProps)(Router);
