import { isFunction } from "lodash";
import { useCallback, useEffect } from "react";
import { Box } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { setDialogModal } from "../../redux/actions/DialogAction";
import { DevTool } from "@hookform/devtools";

const Form = ({
  setDialogModal,
  children,
  onSubmit = () => {},
  defaultValues = {},
  noValidate = true,
  confirmBeforeSubmit,
  confirmMessage = "האם את/ה בטוח/ה בפעולה זו?",
  confirmTitle = "האם את/ה בטוח/ה בפעולה זו?",
  confirmIcon = null,
  confirmIconStyle = {},
  confirmBtnClass = "",
  formId,
  mode = "onChange",
  extraData = {},
}) => {
  const methods = useForm({
    mode: mode,
    defaultValues,
  });

  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues]);

  const submit = useCallback(
    (data) => {
      const submitMethod = () => onSubmit(data, methods);

      if (confirmBeforeSubmit) {
        setDialogModal({
          iconJSX: () => confirmIcon(confirmIconStyle),
          title: confirmTitle,
          text: confirmMessage,
          onConfirm: submitMethod,
          btnConfirmClass: confirmBtnClass,
        });
      } else {
        submitMethod();
      }
    },
    [onSubmit, confirmBeforeSubmit, confirmMessage, confirmTitle]
  );

  return (
    <FormProvider {...methods} {...{ extraData }}>
      <Box
        component="form"
        {...{ noValidate, ...(!!formId && { id: formId }) }}
        onSubmit={methods.handleSubmit(submit)}
        // sx={{ "& .MuiFormControl-root": { margin: 5 } }}
      >
        {children}
      </Box>
    </FormProvider>
  );
};

export default connect(null, { setDialogModal })(Form);
