import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  fetchEmployees,
  fetchRoles,
  getPendingCount,
} from "../../redux/actions/scheduleAction";
import {
  checkConnection,
  fetchUserPrivileges,
  logConnectedUser,
} from "../../redux/actions/loginAction";
import {
  getNetworkCategories,
  getNetworkSuppliers,
} from "../../redux/actions/networkAction";
import { getBranches, setBranch } from "../../redux/actions/branchesAction";
import { matchRoutes, Outlet, useLocation } from "react-router-dom";
import {
  AppBar as MuiAppBar,
  Drawer as MuiDrawer,
  Box,
  CssBaseline,
  Toolbar,
  IconButton,
  List,
  Card,
  CardContent,
  Link,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { GiFactory, GiHamburgerMenu } from "react-icons/gi";
import {
  AiOutlineClose,
  AiOutlinePoweroff,
  AiOutlineReload,
  AiOutlineUser,
} from "react-icons/ai";
import restigo_logo from "../../images/restigo_logo.svg";
import Zendesk, { ZendeskAPI } from "react-zendesk";
import { useScreenSize } from "../../Hooks";
import { isFunction } from "lodash";
import { FiHelpCircle } from "react-icons/fi";
import { FaLockOpen } from "react-icons/fa";
import { setError } from "../../redux/actions/errorAction";
import ListItem from "../../components/layouts/ListItem";

const DRAWER_WIDTH = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const MinDrawer = ({
  user,
  selected_branch,
  routes,
  baseRoute,
  logout,
  setBranch,
  branches,
  setError,
  connected_users,
  logConnectedUser,
}) => {
  const { zIndex } = useTheme();
  const { isMobile } = useScreenSize();
  const location = useLocation();
  const [isLagacy, setIsLagacy] = useState(true);
  const [open, setOpen] = useState(!isMobile);
  const [title, setTitle] = useState("");
  const [branchesToSelect, setBranchesToSelect] = useState([]);
  const [connectedUsersToSelect, setConnectedUsersToSelect] = useState([]);

  useEffect(() => {
    setOpen(false);
    let routesMatch = matchRoutes(routes, location, `/${baseRoute}`);
    routesMatch = routesMatch?.filter(({ route }) => route.element);
    setIsLagacy(routesMatch?.[0]?.route.isLagacy);
  }, [location, routes, baseRoute, matchRoutes]);
  useEffect(() => {
    setBranchesToSelect(
      branches.map(({ id, name }) => ({
        name,
        onClick: () => {
          setBranch(branches.find((branch) => branch.id === id));
        },
      }))
    );
  }, [branches, setBranch]);
  useEffect(() => {
    if (!connected_users?.length) {
      return;
    }
    setConnectedUsersToSelect(
      connected_users.map(({ id, full_name, network_name }) => ({
        name: `${full_name}, ${network_name}`,
        onClick: () => {
          logConnectedUser(id);
        },
      }))
    );
  }, [branches, logConnectedUser]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <MuiAppBar sx={{ backgroundColor: "black", zIndex: zIndex.drawer + 1 }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            sx={{ color: "white" }}
            onClick={(e) => {
              setOpen((prev) => !prev);
            }}
          >
            {open ? (
              <AiOutlineClose size={"3rem"} />
            ) : (
              <GiHamburgerMenu size={"3rem"} />
            )}
          </IconButton>
          <Link to="/">
            <img src={restigo_logo} alt="logo" width={40} />
          </Link>
        </Toolbar>
      </MuiAppBar>
      <MuiDrawer
        variant="temporary"
        {...{ open }}
        ModalProps={{ keepMounted: true }}
        onClose={() => {
          setOpen(false);
        }}
        sx={{
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
            backgroundColor: "#1D1D1D",
          },
        }}
      >
        <DrawerHeader />
        <List
          component={"nav"}
          sx={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          (
          <ListItem
            {...{ open }}
            route={{
              icon: AiOutlineUser,
              name: `${user.full_name}, ${user.network_name}`,
              children: connectedUsersToSelect,
            }}
          />
          <ListItem
            {...{ open }}
            route={{
              icon: GiFactory,
              name: `סניף: ${selected_branch?.name}`,
              ...(branchesToSelect?.length > 1 && {
                children: branchesToSelect,
              }),
            }}
          />
          <ListItem
            route={{ icon: AiOutlineReload, name: "רענון" }}
            {...{ open }}
            onClick={() => {
              document.location.reload();
            }}
          />
          {routes?.map(({ path, ...rest }, key) => (
            <ListItem
              {...{ key, open, setTitle }}
              route={{
                ...rest,
                path: `/${baseRoute}${path ? `/${path}` : ""}`,
              }}
            />
          ))}
          <ListItem
            route={{ icon: FaLockOpen, name: "תמיכה" }}
            {...{ open }}
            onClick={() => {
              if (isFunction(window.zE.activate)) {
                window.zE.activate();
              } else {
                setError(
                  "התרחשה שגיאה",
                  "אנא נסה לרענן במידה והתקלה ממשיכה ניתן לפנות לתמיכה"
                );
              }
            }}
          />
          <ListItem
            route={{ icon: AiOutlinePoweroff, name: "התנתק" }}
            {...{ open }}
            onClick={logout}
          />
        </List>
      </MuiDrawer>

      <main
        style={{
          backgroundColor: "#F5F5F5",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <DrawerHeader />
        {/* {!isLagacy && (
          <CardHeader
            sx={{ border: "none", paddingBottom: 0, marginBottom: 3 }}
            title={
              <span
                style={{
                  fontSize: "3.6rem",
                  fontWeight: 700,
                }}
              >
                {title}
              </span>
            }
            action={
              branches?.length > 1 &&
              !!selected_branch && (
                <AutoComplete
                  blurOnSelect
                  size="small"
                  options={branchesToSelect}
                  value={{
                    id: selected_branch.id,
                    label: selected_branch.name,
                  }}
                  sx={{ width: "12.5rem" }}
                  disableClearable
                  onChange={(e, value) => {
                    setBranch(branches.find(({ id }) => value.id === id));
                  }}
                  inputProps={{
                    variant: "outlined",
                    sx: {
                      backgroundColor: "white",
                      "& fieldset": {
                        borderColor: "white",
                      },
                    },
                  }}
                />
              )
            }
          />
        )} */}
        <Card
          sx={{
            padding: "0px",
            flexGrow: 1,
            margin: 0,
            overflow: "scroll",
          }}
        >
          <CardContent
            sx={{
              padding: 0,
              margin: "20px",
            }}
          >
            <>
              {/* <Zendesk
                defer
                zendeskKey={process.env.REACT_APP_ZENDESK_KEY}
                onLoaded={() => {
                  ZendeskAPI("webWidget", "setLocale", "he");
                  ZendeskAPI("webWidget", "hide");
                }}
              /> */}
              <Outlet context={{ routes }} />
            </>
          </CardContent>
        </Card>
      </main>
    </Box>
  );
};

const mapStateToProps = (state) => {
  const { locale } = state.i18n;
  const { branches } = state.branches;
  return { locale, branches };
};

const mapDispatchToProps = {
  fetchEmployees,
  fetchRoles,
  checkConnection,
  fetchUserPrivileges,
  getNetworkCategories,
  getNetworkSuppliers,
  getPendingCount,
  getBranches,
  setBranch,
  setError,
  logConnectedUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(MinDrawer);
