export const NEW_ITEM_ID = -1;
export const RESTIGO_BACKGROUND_GREY_COLOR = "#f5f5f5";
export const RESTIGO_BACKGROUND_BLUE_COLOR = "#00a2ea";

// USE PRIVILIGES
export const INTERNAL_CATALOG_NUMBER = "internal_catalog_number";
export const REQUIRED_CATALOG_NUMBER = "required_catalog_number";

// GENERAL - TABS
export const TAB_ITEMS = 0;
export const TAB_CATEGORIES = 1;
export const TAB_UNIFIED = 2;
export const TAB_PRICE_GAPS = 3;

// TABS PATHS
export const TAB_PATH_ITEMS = "items";
export const TAB_PATH_CATEGORIES = "categories";
export const TAB_PATH_UNIFIED = "unified";
export const TAB_PATH_PRICE_GAPS = "price_gaps";

export const TAB_OPTIONS = [
  {
    id: TAB_ITEMS,
    label: "מוצרים",
    path: TAB_PATH_ITEMS,
  },
  {
    id: TAB_CATEGORIES,
    label: "קטגוריות",
    path: TAB_PATH_CATEGORIES,
  },
  {
    id: TAB_UNIFIED,
    label: "מוצר על",
    path: TAB_PATH_UNIFIED,
  },
  {
    id: TAB_PRICE_GAPS,
    label: "שינויי מחיר",
    path: TAB_PATH_PRICE_GAPS,
  },
];

export const TABS_WITH_CREATE_BUTTON = [TAB_ITEMS, TAB_CATEGORIES, TAB_UNIFIED];

// GENERAL - FILTERS
export const FILTERS_STATUS_ENABLE = 1;
export const FILTERS_STATUS_DISABLED = 0;

export const FILTERS_STATUS_OPTIONS = [
  {
    id: FILTERS_STATUS_ENABLE,
    label: "פעילים",
  },
  {
    id: FILTERS_STATUS_DISABLED,
    label: "לא פעילים",
  },
];

//types should match the relevant const types in 'ItemLog' laravel model
export const ITEM_PRICE_LOG_TYPES = {
  catalog_price: "CATALOG_PRICE",
  sp_price: "SP_PRICE",
  taklit_price: "TAKLIT_PRICE",
  discount: "DISCOUNT",
  tax: "TAX",
  deposit: "DEPOSIT",
};

export const FILTER_OTHER_OPTIONS = [
  { name: "ללא פילטור נוסף", id: 0 },
  { name: "מקושר לספק", id: 1 },
  { name: "מוצר תקליט", id: 2 },
];

// GENERAL - SWEEPING STATUS
export const GROUP_CHANGE_STATUS = [
  { id: -1, name: "סטטוס" },
  { id: 1, name: "פעיל" },
  { id: 0, name: "לא פעיל" },
];

// SWEEPING OPTIONS

export const SWEEPING_CATEGORY = 1;
export const SWEEPING_STATUS = 2;
export const SWEEPING_ORDERABLE = 3;
export const SWEEPING_HAS_TAX = 4;
export const SWEEPING_DISCOUNT = 6;
export const SWEEPING_DEPOSIT = 7;
export const SWEEPING_TAX = 8;
export const SWEEPING_CREDIT = 9;
export const SWEEPING_DEBIT = 10;
export const SWEEPING_MARKUP = 11;
export const SWEEPING_COUNT_TYPE = 12;
export const SWEEPING_BRANCHES = 13;
export const SWEEPING_EXPENSE_TYPE = 14;
export const REMOVING_BRANCHES = 15;
export const SWEEPING_STICKERS = 16;

// SWEEPING OPTIONS

export const SWEEPING_OPTIONS = [
  { value: SWEEPING_CATEGORY, title: "קטגוריה" },
  { value: SWEEPING_STATUS, title: "מצב מוצר" },
  { value: SWEEPING_ORDERABLE, title: "מוצג בעת שליחת הזמנה" },
  { value: SWEEPING_HAS_TAX, title: "מע״מ" },
  { value: SWEEPING_DISCOUNT, title: "הנחה" },
  { value: SWEEPING_DEPOSIT, title: "פיקדון" },
  { value: SWEEPING_TAX, title: "מיסים" },
  { value: SWEEPING_CREDIT, title: "חשבון זכות" },
  { value: SWEEPING_DEBIT, title: "חשבון חובה" },
  { value: SWEEPING_MARKUP, title: "מרקאפ" },
  { value: SWEEPING_COUNT_TYPE, title: "צורת ספירת מלאי" },
  { value: SWEEPING_BRANCHES, title: "הוספת סניפים" },
  { value: REMOVING_BRANCHES, title: "הסרת סניפים" },
  { value: SWEEPING_EXPENSE_TYPE, title: "סוג הוצאה" },
  { value: SWEEPING_STICKERS, title: "הדפסת מדבקות" },
];

// SWEEPING OPTIONS

export const SWEEPING_COUNT_TYPE_OPTIONS = [
  { id: 0, label: "לא נספר במלאי" },
  { id: 1, label: "מוצר" },
  { id: 2, label: "שקיל-יחידת מידה ()" },
  { id: 3, label: "צורת מארז (מארז)" },
  { id: 4, label: "יחידת מידה במארז (יחידות)" },
  { id: 5, label: "מארז + יחידת מידה במארז(יחידות)" },
  { id: 6, label: "לא מנוהל מלאי" },
  { id: 7, label: "מארז + יחידת מידה במארז + שקיל" },
];

export const SWEEPING_EXPENSE_TYPE_OPTIONS = [
  { id: 1, label: "Food Cost" },
  { id: 2, label: "הוצאות תפעול" },
  { id: 3, label: "הוצאות הנהלה וכלליות" },
];
