//orderReducer

import { addMonths } from "date-fns";
import { mapKeys, omit } from "lodash";
import { fixDate } from "../../helpers";
import { LOGOUT_ADMIN } from "../actions/AdminLoginAction/types";
import { LOGOUT } from "../actions/loginAction/types";
import {
  ADD_ORDER,
  FETCH_CATEGORIES,
  FETCH_CONST_ORDERS,
  FETCH_ITEMS,
  FETCH_OPEN_ORDERS,
  FETCH_SINGLE_CONST_ORDER,
  FETCH_SUPPLIERS,
  FETCH_SUPPLIERS_AND_OPEN,
  REMOVE_ORDER,
  SAVE_ITEM,
  SET_CURRENT_CONST_ORDER,
  SET_CURRENT_ORDER,
  SET_MANAGE_ORDERS_FILTERS,
  UPDATE_SUPPLIER,
  EDIT_NOTES,
  GET_SINGLE_ORDER_DATA,
} from "../actions/orderAction/types";

const INITIAL_STATE = {
  open_orders: {},
  const_orders: {},
  suppliers: {},
  categories: {},
  items: {},
  current_order: null,
  current_const_order: {},
  const_order_items: {},
};

export default (state = INITIAL_STATE, action) => {
  const {
    type,
    suppliers,
    categories,
    items,
    open_orders,
    payload,
    order_id,
    id,
    const_orders,
    current_const_order,
    current_order = null,
    note,
    const_order_items,
  } = action;
  switch (type) {
    case SET_CURRENT_ORDER:
      return {
        ...state,
        current_order,
      };
    case FETCH_SUPPLIERS_AND_OPEN:
      return {
        ...state,
        suppliers: mapKeys(suppliers, "id"),
        open_orders: mapKeys(open_orders, "id"),
      };
    case SET_CURRENT_CONST_ORDER:
      return {
        ...state,
        current_const_order: order_id ? state.open_orders[order_id] : null,
      };
    case FETCH_SINGLE_CONST_ORDER:
      return {
        ...state,
        current_const_order,
      };
    case GET_SINGLE_ORDER_DATA:
      return {
        ...state,
        const_order_items,
      };
    case FETCH_OPEN_ORDERS:
      return {
        ...state,
        open_orders: mapKeys(open_orders, "id"),
      };
    case FETCH_CONST_ORDERS:
      return {
        ...state,
        const_orders: mapKeys(const_orders, "id"),
      };
    case ADD_ORDER: {
      return {
        ...state,
        open_orders: { ...state.open_orders, [payload.id]: payload },
      };
    }
    case REMOVE_ORDER: {
      return {
        ...state,
        open_orders: omit(state.open_orders, id),
      };
    }
    case FETCH_SUPPLIERS:
      return { ...state, suppliers: mapKeys(suppliers, "id") };

    case UPDATE_SUPPLIER: {
      return {
        ...state,
        suppliers: { ...state.suppliers, [payload.id]: payload },
      };
    }
    case FETCH_CATEGORIES:
      return { ...state, categories: mapKeys(categories, "id") };
    case FETCH_ITEMS:
      return { ...state, items: mapKeys(items, "id") };
    case SAVE_ITEM: {
      return {
        ...state,
        items: { ...state.items, [payload.id]: payload },
      };
    }
    case EDIT_NOTES: {
      return {
        ...state,
        current_order: { ...state.current_order, notes: note },
      };
    }

    case LOGOUT_ADMIN:
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
